
import { Viewer3D } from "../application/Viewer3D";
import { HotGestureTool } from "../tools/HotGestureTool";
import * as et from "../application/EventTypes";
import { isTouchDevice, isMobileDevice, touchStartToClick, getGlobal, isIOSDevice } from "../compat";
import { ViewerLayersPanel } from "./ViewerLayersPanel";
import { ScreenMode } from "../application/ScreenModeDelegate";
import { i18n } from "../globalization/i18next";
import { Button } from "./controls/Button";
import { ControlGroup } from "./controls/ControlGroup";
import { ToolBar } from "./toolbar/ToolBar";
import { RadioButtonGroup } from "./controls/RadioButtonGroup";
import { ErrorHandler } from "./ErrorHandler";
import { ProgressBar } from "./ProgressBar";
import { logger } from "../logger/Logger";
import { getParameterByName, stringToDOM } from "../globals";
import { RenderOptionsPanel } from "./RenderOptionsPanel";
import { Lang } from "../globalization/langs";
import { setLanguage } from "../globalization/i18init";
import { HudMessage } from "./HudMessage";
import { AlertBox } from "./AlertBox";
import { ViewerPropertyPanel } from "./ViewerPropertyPanel";
import { SettingsPanel } from "./SettingsPanel";
import { ViewerSettingsPanel } from "./ViewerSettingsPanel";
import { ViewerModelStructurePanel } from "./ViewerModelStructurePanel";
import { ErrorCodes, errorCodeString } from "../file-loaders/net/ErrorCodes";
import { LayersPanel } from "./LayersPanel";
import { PropertyPanel } from "./PropertyPanel";
import { TOOLBAR, TOOLBAR_CREATED_EVENT, VIEW_CUBE_CREATED_EVENT } from "./GuiViewerToolbarConst";
import { KeyCode } from "../tools/KeyCode";


    /**
     * Viewer component based on {@link Autodesk.Viewing.Viewer3D} with added UI.
     *
     * @constructor
     * @param {HTMLElement} container - The viewer container.
     * @param {object} config - The initial settings object. See base class for details.
     * @alias Autodesk.Viewing.GuiViewer3D
     * @extends Autodesk.Viewing.Viewer3D
     */
    export function GuiViewer3D(container, config) {
        if (!config) config = {};

        // Explicitly set startOnInitialize = false, as we want to finish some initialization
        // before starting the main loop.
        //
        config.startOnInitialize = false;

        Viewer3D.call(this, container, config);

        this.toolbar = null;

        // Container for the UI docking panels
        this.dockingPanels = [];

        this.modelstructure = null;
        this.layersPanel = null;

        this.onFullScreenModeEvent = this.onFullScreenModeEvent.bind(this);

    }

    GuiViewer3D.prototype = Object.create(Viewer3D.prototype);
    GuiViewer3D.prototype.constructor = GuiViewer3D;

    GuiViewer3D.prototype.initialize = function () {
        var viewerErrorCode = Viewer3D.prototype.initialize.call(this);

        if (viewerErrorCode > 0)    // ErrorCode was returned.
        {
            ErrorHandler.reportError(this.container, viewerErrorCode); // Show UI dialog
            return viewerErrorCode;
        }

        var viewer = this;

        // Add padding to bottom to account for toolbar, when calling fitToView()
        // TODO: Use pixel size for setting these.
        //---this.navigation.FIT_TO_VIEW_VERTICAL_OFFSET = 0.03;
        //---this.navigation.FIT_TO_VIEW_VERTICAL_MARGIN = 0.0;

        if (this.toolController) {
            var hottouch = new HotGestureTool(this);

            this.toolController.registerTool(hottouch);

            this.toolController.activateTool(hottouch.getName());
        }

        // Create toolbar that is attached to the bottom of the panel.
        this.getToolbar(true);

        this.addEventListener(et.FULLSCREEN_MODE_EVENT, this.onFullScreenModeEvent);

        // Context menu
        if (!this.contextMenu) {
            this.setDefaultContextMenu();
        }

        // Create a progress bar. Shows streaming.
        //
        this.progressbar = new ProgressBar(this.container);
        this.addEventListener(et.PROGRESS_UPDATE_EVENT, function (e) {
            if (e.percent !== undefined) {
                viewer.progressbar.setPercent(e.percent);
            }
        }, false);

        // There is no way on the API to get the current selection (yet?)
        //
        // We need to know if there is anything selected in order to process the
        // Escape key workflow, so track it manually.
        this.selectionActive = false;
        this.addEventListener(et.SELECTION_CHANGED_EVENT, function (event) {
            viewer.selectionActive = (event.dbIdArray.length > 0);

            if (viewer.prefs.openPropertiesOnSelect) {
                var propertyPanel = viewer.getPropertyPanel(true);
                propertyPanel.setVisible(viewer.selectionActive);
            }
        });

        this.addEventListener(et.ISOLATE_EVENT, function (event) {
            if (viewer.prefs.openPropertiesOnSelect || event.nodeIdArray[0] === event.model.getRootId()) {
                if (viewer.propertygrid) {
                    viewer.propertygrid.setVisible(event.nodeIdArray.length > 0 || viewer.selectionActive);
                }
            }
        });

        this.addEventListener(et.VIEWER_STATE_RESTORED_EVENT, function (event) {
            if (viewer.renderoptions) {
                viewer.renderoptions.syncUI();
            }
        });

        this.addEventListener(et.VIEWER_RESIZE_EVENT, function (event) {

            viewer.resizePanels();

            if (viewer.viewCubeUi && viewer.viewCubeUi.cube)
                viewer.viewCubeUi.cube.refreshCube();

            viewer.updateToolbarButtons(event.width, event.height);
        });

        this.addEventListener(et.NAVIGATION_MODE_CHANGED_EVENT, function (event) {
            viewer.updateToolbarButtons(viewer.container.clientWidth, viewer.container.clientHeight);
        });

        this.addEventListener(et.MODEL_UNLOADED_EVENT, function(event){
            viewer._onUnloadModel(event.model);
        });

        this.initEscapeHandlers();

        // Now that all the ui is created, localize it.
        this.localize();

        
        this.addEventListener( et.WEBGL_CONTEXT_LOST_EVENT, function(event) {
            this.impl.stop();
            // Hide all divs
            var div = this.container;
            var divCount = div.childElementCount;
            for (var i=0; i<divCount; ++i) {
                div.children[i].style.display = 'none';
            }
            ErrorHandler.reportError(this.container, ErrorCodes.WEBGL_LOST_CONTEXT);
        }.bind(this));

        // Now that all of our initialization is done, start the main loop.
        //
        this.run();

        return 0;   // No errors initializing.
    };

    GuiViewer3D.prototype.uninitialize = function () {

        if (this.viewerSettingsPanel) {
            this.viewerSettingsPanel.uninitialize();
            this.viewerSettingsPanel = null;
        }

        if (this.modelstructure) {
            this.modelstructure.uninitialize();
            this.modelstructure = null;
        }

        if (this.layersPanel) {
            this.layersPanel.uninitialize();
            this.layersPanel = null;
        }

        if (this.propertygrid) {
            this.propertygrid.uninitialize();
            this.propertygrid = null;
        }

        if (this.renderoptions) {
            this.renderoptions.uninitialize();
            this.renderoptions = null;
        }

        if (this.viewerOptionButton) {

            this.viewerOptionButton = null;
        }

        this.getHotkeyManager().popHotkeys("Autodesk.ROLL");
        this.getHotkeyManager().popHotkeys("Autodesk.FOV");

        this.removeEventListener(et.FULLSCREEN_MODE_EVENT, this.onFullScreenModeEvent);

        this.progressbar = null;

        this.modelTools = null;
        this.navTools = null;
        this.settingsTools = null;
        this.debugMenu = null;
        this.modelStats = null;
        this.explodeSlider = null;
        this.explodeSubmenu = null;

        // Toolbar
        this.toolbar = null;

        Viewer3D.prototype.uninitialize.call(this);
    };

    GuiViewer3D.prototype.setUp = function (config) {
        if (!config) config = {};

        // Explicitly set startOnInitialize = false, as we want to finish some initialization
        // before starting the main loop.
        //
        config.startOnInitialize = false;

        this.getToolbar(true);

        Viewer3D.prototype.setUp.call(this, config);
    };

    GuiViewer3D.prototype.tearDown = function () {

        //TODO: this is unorthodox order of destruction, but we
        //need to call the super first so it unloads the extensions,
        //which need the GUI. We need to resolve this somehow.
        Viewer3D.prototype.tearDown.call(this);


        if (this.toolbar) {
            this.toolbar.container.parentNode.removeChild(this.toolbar.container);
            this.toolbar = null;
        }

        if (this.modelstructure) {
            this.setModelStructurePanel(null);
        }
        if (this.propertygrid) {
            this.setPropertyPanel(null);
        }
        if (this.viewerSettingsPanel) {
            this.setSettingsPanel(null);
        }
        if (this.layersPanel) {
            this.setLayersPanel(null);
        }
        if (this.renderoptions) {
            this.removePanel(this.renderoptions);
            this.renderoptions.uninitialize();
            this.renderoptions = null;
        }

        this.debugMenu = null;

        // Need to remove this event listener, in case that viewcube will show up when
        // changing sheets from 3D to 2D and the 3D model doesn't fully loaded.
        this.removeEventListener(et.GEOMETRY_LOADED_EVENT, this.initViewCube);
    };

    GuiViewer3D.prototype.loadModel = function (url, options, onSuccessCallback, onErrorCallback, initAfterWorker) {

        var viewer = this;

        function createUI(model) {
            if (!viewer.running) {
                logger.error("createUI expects the viewer to be running.", errorCodeString(ErrorCodes.VIEWER_INTERNAL_ERROR));
                return;
            }
            viewer.createUI(model);
        }

        function onSuccessChained(model) {

            //TODO: The exact timeout needs to be tuned for best
            //CPU utilization and shortest frame length during startup.
            setTimeout(function() {
                // Init UI, unless model is background-loaded
                if (!options || !options.loadAsHidden) {
                    createUI(model);
                }

                viewer._onLoadModel(model);

                if (onSuccessCallback)
                    onSuccessCallback.call(onSuccessCallback, model);
            }, 1);
        }

        function onFailureChained(errorCode) {
            ErrorHandler.reportError(viewer.container, errorCode); // Show UI dialog
            onErrorCallback && onErrorCallback.apply(onErrorCallback, arguments);
        }

        var res = Viewer3D.prototype.loadModel.call(this, url, options, onSuccessChained, onFailureChained, initAfterWorker);

        return res;
    };

    GuiViewer3D.prototype.createUI = function (model) {
        // We only support UI for initially loaded model.
        if (this.model !== model) {
            return;
        }

        var viewer = this;

        this.initViewCube = function () {
            //Delay this to the next frame so that the current frame can render fast and display the geometry.
            setTimeout(function() {
                viewer.displayViewCube(viewer.prefs.viewCube);
                viewer.removeEventListener(et.GEOMETRY_LOADED_EVENT, viewer.initViewCube);
            }, 1);
        };

        var disabledExtensions = this.config.disabledExtensions || {};

        this.initHotkeys(model);

        // Load or update navtools extension
        var navExtName = 'Autodesk.DefaultTools.NavTools';
        var navExt     = this.getExtension(navExtName);
        if (navExt) {
            // If already loaded, just make sure that it is properly configured
            navExt.updateUI(model.is3d());
        } else {
            this.loadExtension(navExtName, viewer.config);
        }

        this.initModelTools(model);

        //Optional rendering options panel + button
        if (getGlobal().ENABLE_DEBUG) {
            this.initDebugTools();
        }

        //load debug ext by query param
        //duped from Viewer3D as a workaround for adsk viewer site
        var debugConfig = getParameterByName("lmv_viewer_debug");
        if (debugConfig === "true") {
            this.loadExtension("Autodesk.Debug", this.config);
        }

        // Dispatch a toolbar created event
        this.toolbar.container.style.display = 'block'; // Show toolbar before event fires
        this.dispatchEvent({type: TOOLBAR_CREATED_EVENT});

        this.createViewCube();

        // Dispatch a view cube created event
        this.dispatchEvent({type: VIEW_CUBE_CREATED_EVENT});

        this.initModality();

        this.resize();

        if (model.is2d()) {
            this.unloadExtension('Autodesk.BimWalk');
            this.unloadExtension('Autodesk.FirstPerson');

            // Make pan a default navigation tool.
            this.setDefaultNavigationTool("pan");

            // Make sure view cube and click to set COI are disabled (but don't update the preferences)
            this.setClickToSetCOI(false, false);
            this.displayViewCube(false, false);

            //Load relevant extensions (on the next frame, since creating the UI is already too slow)
            setTimeout(function(){
                if (!disabledExtensions.measure) {
                    viewer.loadExtension('Autodesk.Measure', viewer.config);
                }

                if (!disabledExtensions.hyperlink) {
                    viewer.loadExtension('Autodesk.Hyperlink', viewer.config);
                }
            }, 1);

        } else {
            // Make orbit a default navigation tool.
            if (this.getDefaultNavigationToolName().indexOf("orbit") === -1)
                this.setDefaultNavigationTool("orbit");

            //Load relevant extensions (on the next frame, since creating the UI is already too slow)
            setTimeout(function() {
                if (!disabledExtensions.bimwalk) {
                    viewer.loadExtension('Autodesk.BimWalk', viewer.config);
                }

                if (viewer.prefs.fusionOrbit)
                    viewer.loadExtension('Autodesk.Viewing.FusionOrbit', viewer.config);

                if (!disabledExtensions.measure) {
                    viewer.loadExtension('Autodesk.Measure', viewer.config);
                }

                if (!disabledExtensions.section) {
                    viewer.loadExtension('Autodesk.Section', viewer.config);
                }

                if (!disabledExtensions.hyperlink) {
                    viewer.loadExtension('Autodesk.Hyperlink', viewer.config);
                }

                if (!disabledExtensions.scalarisSimulation) {
                    if (viewer.model.isScalaris) {
                        viewer.loadExtension('Autodesk.Viewing.ScalarisSimulation', viewer.config);
                    }
                }

            }, 1);

            this.addEventListener(et.GEOMETRY_LOADED_EVENT, this.initViewCube);
        }
    };

    GuiViewer3D.prototype.onFullScreenModeEvent = function(event) {
        this.resizePanels();
        this.updateFullscreenButton(event.mode);
    };

    GuiViewer3D.prototype._onLoadModel = function(model) {

        if (this.modelstructure) {
            this.modelstructure.addModel(model);
        }
    };

    GuiViewer3D.prototype._onUnloadModel = function(model) {
        if (this.modelstructure) {
            this.modelstructure.unloadModel(model);
        }
    };

    // "tooltip" string is localized by this method.
    GuiViewer3D.prototype.addOptionToggle = function (parent, tooltip, initialState, onchange, saveKey) {

        // Use the stored settings or defaults
        var storedState = saveKey ? this.prefs[saveKey] : null;
        initialState = (typeof storedState === 'boolean') ? storedState : initialState;

        var li = document.createElement("li");
        li.className = "toolbar-submenu-listitem";

        var cb = document.createElement("input");
        cb.className = "toolbar-submenu-checkbox";
        cb.type = "checkbox";
        cb.id = tooltip;
        li.appendChild(cb);

        var lbl = document.createElement("label");
        lbl.setAttribute('for', tooltip);
        lbl.setAttribute("data-i18n", tooltip);
        lbl.textContent = i18n.translate(tooltip);
        li.appendChild(lbl);

        parent.appendChild(li);

        cb.checked = initialState;

        cb.addEventListener("touchstart", touchStartToClick);
        lbl.addEventListener("touchstart", touchStartToClick);
        li.addEventListener("touchstart", touchStartToClick);

        cb.addEventListener("click", function (e) {
            onchange(cb.checked);
            e.stopPropagation();
        });

        lbl.addEventListener("click", function (e) {
            e.stopPropagation();
        });

        li.addEventListener("click", function (e) {
            onchange(!cb.checked);
            e.stopPropagation();
        });

        if (saveKey) {
            this.prefs.addListeners(saveKey, function (value) {
                cb.checked = value;
            }, function (value) {
                cb.checked = value;
                onchange(value);
            });
        }
        return cb;
    };

    // "label" string will be converted to localized string by this method
    GuiViewer3D.prototype.addOptionList = function (parent, label, optionList, initialIndex, onchange, saveKey) {

        // Use the stored settings or defaults
        var storedState = this.prefs[saveKey];
        initialIndex = (typeof storedState === 'number') ? storedState : initialIndex;

        // Wrap the onchange with the update to that setting
        var handler = function (e) {
            var selectedIndex = e.target.selectedIndex;
            onchange(selectedIndex);
            e.stopPropagation();
        };

        var selectElem = document.createElement("select");
        selectElem.className = 'option-drop-down';
        selectElem.id = "selectMenu_" + label;
        for (var i = 0; i < optionList.length; i++) {
            var item = document.createElement("option");
            item.value = i;
            item.setAttribute("data-i18n", optionList[i]);
            item.textContent = i18n.translate(optionList[i]);
            selectElem.add(item);
        }

        var li = document.createElement("li");
        li.className = "toolbar-submenu-select";

        var lbl = document.createElement("div");
        lbl.className = "toolbar-submenu-selectlabel";
        lbl.setAttribute('for', label);
        lbl.setAttribute("data-i18n", label);
        lbl.textContent = i18n.translate(label);
        li.appendChild(lbl);
        li.appendChild(selectElem);

        parent.appendChild(li);

        selectElem.selectedIndex = initialIndex;
        selectElem.onchange = handler;
        selectElem.addEventListener("touchstart", function (e) {
            e.stopPropagation();
        });
        selectElem.addEventListener("click", function (e) {
            e.stopPropagation();
        });

        if (saveKey) {
            this.prefs.addListeners(saveKey, function (value) {
                selectElem.selectedIndex = value;
            }, function (value) {
                selectElem.selectedIndex = value;
                onchange(value);
            });
        }

        return selectElem;
    };

    GuiViewer3D.prototype.showViewer3dOptions = function (show) {
        var settingsPanel = this.getSettingsPanel(true);
        if (show && settingsPanel.isVisible()) {
            settingsPanel.setVisible(false);
        }
        settingsPanel.setVisible(show);
    };

    GuiViewer3D.prototype.showRenderingOptions = function (show) {
        this.renderoptions.setVisible(show);
    };

    GuiViewer3D.prototype.showLayerManager = function (show) {
        this.layersPanel.setVisible(show);
    };

    GuiViewer3D.prototype.initHotkeys = function (model) {
        var viewer = this;
        var keys = KeyCode;
        var onPress;
        var onRelease;

        if (!model.is2d()) {
            // Add FOV hotkey
            var previousToolForFOV;
            onPress = function () {
                if (viewer.toolController.getIsLocked() || !viewer.navigation.isActionEnabled('fov')) {
                    return false;
                }

                previousToolForFOV = viewer.getActiveNavigationTool();
                viewer.setActiveNavigationTool("fov");
                return true;
            };
            onRelease = function () {
                if (viewer.toolController.getIsLocked() || !viewer.navigation.isActionEnabled('fov')) {
                    return false;
                }

                viewer.setActiveNavigationTool(previousToolForFOV);
                return true;
            };
            viewer.getHotkeyManager().pushHotkeys("Autodesk.FOV", [
                {
                    keycodes: [keys.CONTROL, keys.SHIFT],
                    onPress: onPress,
                    onRelease: onRelease
                }
            ], {tryUntilSuccess: true});
        }

        // Add Roll hotkey
        var previousToolForRoll;
        onPress = function () {
            if (viewer.toolController.getIsLocked() || !viewer.navigation.isActionEnabled('roll')) {
                return false;
            }

            previousToolForRoll = viewer.getActiveNavigationTool();
            viewer.setActiveNavigationTool("worldup");
            return true;
        };
        onRelease = function () {
            if (viewer.toolController.getIsLocked() || !viewer.navigation.isActionEnabled('roll')) {
                return false;
            }

            viewer.setActiveNavigationTool(previousToolForRoll);
            return true;
        };
        viewer.getHotkeyManager().pushHotkeys("Autodesk.ROLL", [{
            keycodes: [keys.ALT, keys.SHIFT],
            onPress: onPress,
            onRelease: onRelease
        }], {tryUntilSuccess: true});
    };



    /**
     * Sets the model structure panel for displaying the loaded model.
     * Assumes that no model has yet been loaded into the scene.
     *
     * @param {Autodesk.Viewing.UI.ModelStructurePanel} modelStructurePanel - The model structure panel to use, or null.
     * @returns {boolean} True if the panel, or null, was set successfully; false otherwise.
     */
    GuiViewer3D.prototype.setModelStructurePanel = function (modelStructurePanel) {

        if (modelStructurePanel === this.modelstructure)
            return false;

        var newPanel = false;
        if (this.modelstructure) {
            newPanel = true;
            this.modelstructure.setVisible(false);  // This ensures the button is in the correct state.
            this.removePanel(this.modelstructure);
            this.modelstructure.uninitialize();
        }

        this.modelstructure = modelStructurePanel;
        if (!modelStructurePanel) {
            return true;
        }

        this.addPanel(this.modelstructure);

        // Notify of all models already loaded
        if (newPanel) {
            var models = this.impl.modelQueue().getModels();
            for (var i=0; i<models.length; ++i) {
                this.modelstructure.addModel(models[i]);
            }
        }

        var self = this;
        modelStructurePanel.addVisibilityListener(function (visible) {
            if (visible) {
                self.onPanelVisible(modelStructurePanel, self);
            }
            self.settingsTools.structurebutton.setState(visible ? Button.State.ACTIVE : Button.State.INACTIVE);
        });
        return true;
    };

    /**
     * Sets the layers panel for display 2d layers.
     *
     * @param {Autodesk.Viewing.UI.LayersPanel} layersPanel - The layers panel to use, or null.
     * @returns {boolean} True if the panel or null was set successfully, and false otherwise.
     */
    GuiViewer3D.prototype.setLayersPanel = function (layersPanel) {
        var self = this;

        if( this.model && !this.impl.getLayersRoot() ){
            return false;
        }

        if (layersPanel instanceof LayersPanel || !layersPanel) {
            if (this.layersPanel) {
                this.layersPanel.setVisible(false);
                this.removePanel(this.layersPanel);
                this.layersPanel.uninitialize();
            }

            this.layersPanel = layersPanel;
            if (layersPanel) {
                this.addPanel(layersPanel);

                layersPanel.addVisibilityListener(function (visible) {
                    if (visible) {
                        self.onPanelVisible(layersPanel, self);
                    }
                    self.settingsTools.layerButton.setState(visible ? Button.State.ACTIVE : Button.State.INACTIVE);
                });
            }
            return true;
        }
        return false;
    };

    /**
     * Sets the property panel.
     * @param {Autodesk.Viewing.UI.PropertyPanel} propertyPanel - The property panel to use, or null.
     * @returns {boolean} True if the panel or null was set successfully, and false otherwise.
     */
    GuiViewer3D.prototype.setPropertyPanel = function (propertyPanel) {
        var self = this;
        if (propertyPanel instanceof PropertyPanel || !propertyPanel) {
            if (this.propertygrid) {
                this.propertygrid.setVisible(false);
                this.removePanel(this.propertygrid);
                this.propertygrid.uninitialize();
            }

            this.propertygrid = propertyPanel;
            if (propertyPanel) {
                this.addPanel(propertyPanel);

                propertyPanel.addVisibilityListener(function (visible) {
                    if (visible) {
                        self.onPanelVisible(propertyPanel, self);
                    }
                    self.settingsTools.propertiesbutton.setState(visible ? Button.State.ACTIVE : Button.State.INACTIVE);
                });

            }
            return true;
        }
        return false;
    };

    GuiViewer3D.prototype.getPropertyPanel = function (createDefault) {
        if (!this.propertygrid && createDefault) {
            this.setPropertyPanel(new ViewerPropertyPanel(this));
        }
        return this.propertygrid;
    };


    /**
     * Sets the viewer's settings panel.
     * @param {Autodesk.Viewing.UI.SettingsPanel} settingsPanel - The settings panel to use, or null.
     * @returns {boolean} True if the panel or null was set successfully, and false otherwise.
     */
    GuiViewer3D.prototype.setSettingsPanel = function (settingsPanel) {
        var self = this;
        if (settingsPanel instanceof SettingsPanel || !settingsPanel) {
            if (this.viewerSettingsPanel ) {
                this.viewerSettingsPanel.setVisible(false);
                this.removePanel(this.viewerSettingsPanel);
                this.viewerSettingsPanel.uninitialize();
            }

            this.viewerSettingsPanel = settingsPanel;
            if (settingsPanel) {
                this.addPanel(settingsPanel);

                settingsPanel.addVisibilityListener(function (visible) {
                    if (visible) {
                        self.onPanelVisible(settingsPanel, self);
                    }
                    self.viewerOptionButton.setState(visible ? Button.State.ACTIVE : Button.State.INACTIVE);
                });
            }
            return true;
        }
        return false;
    };

    GuiViewer3D.prototype.getSettingsPanel = function (createDefault) {
        if (!this.viewerSettingsPanel && createDefault) {
            this.setSettingsPanel(new ViewerSettingsPanel(this, this.model));
        }
        return this.viewerSettingsPanel;
    };

    GuiViewer3D.prototype.createSettingsPanel = function (model) {
        var settingsPanel = new ViewerSettingsPanel(this, model);
        this.setSettingsPanel(settingsPanel);
        settingsPanel.syncUI();

        var viewerOptionButton = new Button('toolbar-settingsTool');
        this.viewerOptionButton = viewerOptionButton;
        viewerOptionButton.setIcon("adsk-icon-settings");
        viewerOptionButton.setToolTip("Settings");
        this.settingsTools.addControl(viewerOptionButton);
        this.createViewerOptionsMenu(model);
    };

    GuiViewer3D.prototype.initModelTools = function (model) {
        var viewer = this;

        //var resetTooltip = null;
        if (!model.is2d()) {
            if (!viewer.modelstructure) {
                var options = {
                    docStructureConfig: viewer.config.docStructureConfig,
                    hideSearch: isMobileDevice(),
                    excludeRoot: viewer.config.modelBrowserExcludeRoot,
                    startCollapsed: viewer.config.modelBrowserStartCollapsed
                };
                var modelTitle = this.config.defaultModelStructureTitle ? this.config.defaultModelStructureTitle : 'Browser';
                viewer.setModelStructurePanel(new ViewerModelStructurePanel(viewer, modelTitle, options));
            }

            var structureButton = new Button('toolbar-modelStructureTool');
            structureButton.setToolTip('Model browser');
            structureButton.setIcon("adsk-icon-structure");
            structureButton.onClick = function (e) {
                viewer.showModelStructurePanel(!viewer.modelstructure.isVisible());
            };

            this.settingsTools.addControl(structureButton);
            this.settingsTools.structurebutton = structureButton;

            this.initExplodeSlider();
            // this.initInspectTools();  // NOTE_NOP: don't need this

            //TODO: show only after complete load?
            //viewer.showModelStructurePanel(true);

            //resetTooltip = "Reset model";
        }

        // Initialize model layers panel if any layers.
        var modelLayersInit = function() {
            var layersRoot = viewer.impl.layers.getRoot();
            if (layersRoot && layersRoot.childCount > 0) {
                var layersPanel = new ViewerLayersPanel(this);
                this.setLayersPanel(layersPanel);

                var layerButton = new Button('toolbar-layers-tool');
                layerButton.setToolTip('Layer Manager');
                layerButton.setIcon("adsk-icon-layers");
                layerButton.onClick = function (e) {
                    if(!viewer.layersPanel) {
                        viewer.setLayersPanel(new ViewerLayersPanel(viewer));
                    }
                    viewer.showLayerManager(!viewer.layersPanel.isVisible());
                };

                var index = this.settingsTools.indexOf('toolbar-modelStructureTool');
                index = index !== -1 ? index : 0;

                this.settingsTools.addControl(layerButton, {index: index+1});
                this.settingsTools.layerButton = layerButton;
            }
            viewer.removeEventListener(et.MODEL_LAYERS_LOADED_EVENT, modelLayersInit);
        }.bind(this);

        if (viewer.impl.layers && viewer.impl.layers.initialized) {
            modelLayersInit();
        } else {
            this.addEventListener(et.MODEL_LAYERS_LOADED_EVENT, modelLayersInit);
        }

        // NOTE_NOP: turn off reset button
        // var resetModelButton = new Button('toolbar-resetTool');
        // resetModelButton.setToolTip(resetTooltip);
        // resetModelButton.setIcon("adsk-icon-reset");
        // resetModelButton.onClick = function (e) {
        //     viewer.dispatchEvent({type: et.RESET_EVENT});
        // };
        // this.modelTools.addControl(resetModelButton);
        // this.modelTools.resetModelButton = resetModelButton;

        viewer.addEventListener(et.RESET_EVENT, function () {
            if (viewer.model && !viewer.model.is2d()) {
                viewer.explode(0);
                viewer.explodeSlider.value = 0;
            }
            viewer.showAll();
        });

        var propertiesButton = new Button('toolbar-propertiesTool');
        propertiesButton.setToolTip('Properties');
        propertiesButton.setIcon("adsk-icon-properties");
        propertiesButton.onClick = function (e) {
            var propertyPanel = viewer.getPropertyPanel(true);
            propertyPanel.setVisible(!propertyPanel.isVisible());
        };
        propertiesButton.setVisible(!viewer.prefs.openPropertiesOnSelect);
        this.settingsTools.addControl(propertiesButton);
        this.settingsTools.propertiesbutton = propertiesButton;

        // New viewer options' panel
        this.createSettingsPanel(model);

        if (getGlobal().ENABLE_DEBUG && !model.is2d()) {
            this.renderoptions = new RenderOptionsPanel(this);
            this.addPanel(this.renderoptions);

            var renderOptionsButton = new Button('toolbar-renderOptionsTool');
            renderOptionsButton.setToolTip('Rendering options');
            renderOptionsButton.setIcon("adsk-icon-settings-render");
            renderOptionsButton.onClick = function (e) {
                viewer.showRenderingOptions(!viewer.renderoptions.isVisible());
            };
            this.settingsTools.addControl(renderOptionsButton);
        }

        if (this.canChangeScreenMode()) {
            var fullscreenButton = new Button('toolbar-fullscreenTool', {collapsible: false});
            fullscreenButton.setToolTip('Full screen');
            fullscreenButton.setIcon("adsk-icon-fullscreen");
            fullscreenButton.onClick = function (e) {
                viewer.nextScreenMode();
            };
            this.settingsTools.addControl(fullscreenButton);
            this.settingsTools.fullscreenbutton = fullscreenButton;

            this.updateFullscreenButton(this.getScreenMode());
        }
    };

    GuiViewer3D.prototype.setPropertiesOnSelect = function (onSelect) {
        this.prefs.set('openPropertiesOnSelect', onSelect);
        this.settingsTools.propertiesbutton.setVisible(!onSelect);
    };

    GuiViewer3D.prototype.addDivider = function (parent) {
        var item = document.createElement("li");
        item.className = "toolbar-submenu-horizontal-divider";
        parent.appendChild(item);
        return item;
    };

    GuiViewer3D.prototype.createViewerOptionsMenu = function (model) {
        // TODO: Refactor this into a control
        var viewer = this;
        function show3dOptions() {

            var panel = viewer.getSettingsPanel(true);
            if (!panel.isVisible()) {
                viewer.showViewer3dOptions(true);
            } else {
                viewer.showViewer3dOptions(false);
            }
        }
        /* Comment the below code to make fusion-like */

        //this.viewerOptionButton.onMouseOver = function(e) {
        //    subMenu.classList.remove('adsk-hidden');
        //};
        //
        //this.viewerOptionButton.onMouseOut = function(e) {
        //    subMenu.classList.add('adsk-hidden');
        //};

        //if (isTouchDevice()) {
        this.viewerOptionButton.onClick = function () {
            show3dOptions();
        };


    };
    GuiViewer3D.prototype.initDebugTools = function () {

        if (this.debugMenu)
            return false;

        var debugGroup = new ControlGroup('debugTools');
        this.debugMenu = debugGroup;

        // Create the debug submenu button and attach submenu to it.
        var debugButton = new Button('toolbar-debugTool');
        debugButton.setIcon("adsk-icon-bug");
        debugGroup.addControl(debugButton);
        this.debugMenu.debugSubMenuButton = debugButton;

        this.createDebugSubmenu(this.debugMenu.debugSubMenuButton);

        this.toolbar.addControl(debugGroup);
        return true;
    };

    GuiViewer3D.prototype.removeDebugTools = function() {
        if (!this.debugMenu)
            return;

        this.toolbar.removeControl(this.debugMenu);
        this.debugMenu = null;
    }

    GuiViewer3D.prototype.createDebugSubmenu = function (button) {
        // TODO: Refactor into a control
        var viewer = this;

        var subMenu = document.createElement('div');
        subMenu.id = 'toolbar-debugToolSubmenu';
        subMenu.classList.add('toolbar-submenu');
        subMenu.classList.add('toolbar-settings-sub-menu');
        subMenu.classList.add('adsk-hidden');

        this.debugMenu.subMenu = subMenu;
        this.debugMenu.subMenu.style.minWidth = "180px";

        // Temp connect to the main container to calculate the correct width
        this.container.appendChild(subMenu);

        this.initModelStats();
        this.addDivider(subMenu);

        // Add the language setting
        this.addDivider(subMenu);
        var langs = Lang.getLanguages();
        var langNames = langs.map(function(elem) { return elem.label; });
        var langSymbols = langs.map(function(elem) { return elem.symbol; });

        function setLanguageCB() {
            viewer.localize();
        }

        var initialSelection = viewer.selectedLanguage ? viewer.selectedLanguage : 0;
        var langList = this.addOptionList(subMenu, "Language", langNames, initialSelection, function (selectedIndex) {
            var langSymb = langSymbols[selectedIndex];
            viewer.selectedLanguage = selectedIndex;
            setLanguage(langSymb, setLanguageCB);
        }, null);
        langList.parentNode.style.paddingBottom = "15px";

        // Add display of errors
        this.addDivider(this.debugMenu.subMenu);
        var errorNames = ["UNKNOWN FAILURE", "BAD DATA", "NETWORK ERROR", "NETWORK ACCESS DENIED",
            "NETWORK FILE NOT FOUND", "NETWORK SERVER ERROR", "NETWORK UNHANDLED RESPONSE CODE",
            "BROWSER WEBGL NOT SUPPORTED", "BAD DATA NO VIEWABLE CONTENT"];

        var errorList = this.addOptionList(subMenu, "Error", errorNames, 0, function (errorIndex) {
            var errorCode = errorIndex + 1;
            ErrorHandler.reportError(viewer.container, errorCode, "");
        }, null);
        errorList.parentNode.style.paddingBottom = "15px";

        var subMenuBounds = subMenu.getBoundingClientRect();
        this.debugMenu.subMenu.style.width = subMenuBounds.width + "px";
        this.container.removeChild(subMenu);
        button.container.appendChild(subMenu);

        // Check if the menu fits on the right site and if not, adjust the right edge.
        var right = subMenuBounds.left + subMenuBounds.width;
        var rightBoundary = this.container.getBoundingClientRect().right;
        if (right > rightBoundary) {
            var leftAdjustment = -(right - rightBoundary + 10) + "px";
            this.debugMenu.subMenu.style.left = leftAdjustment;
        }

        button.onMouseOver = function (e) {
            subMenu.classList.remove('adsk-hidden');
        };

        button.onMouseOut = function (e) {
            subMenu.classList.add('adsk-hidden');
        };

        if (isTouchDevice()) {
            button.onClick = function (e) {
                subMenu.classList.toggle('adsk-hidden')
            };
        }
    };

    GuiViewer3D.prototype.initModelStats = function () {

        var self = this;

        function updateModelStatContent(message) {
            var viewer = self.impl;
            var text = "";
            var model = self.model;
            if (model) {
                text += "Geom&nbsp;polys:&nbsp;" + viewer.modelQueue().getGeometryList().geomPolyCount + "<br>";
                text += "Instance&nbsp;polys:&nbsp;" + viewer.modelQueue().getGeometryList().instancePolyCount + "<br>";
                text += "Fragments:&nbsp;" + viewer.modelQueue().getFragmentList().getCount() + "<br>";
                text += "Geoms:&nbsp;" + viewer.modelQueue().getGeometryList().geoms.length + "<br>";
                text += "Loading&nbsp;time:&nbsp;" + (viewer.model.loader.loadTime/1000).toFixed(2) + " s" + "<br>";
            }
            text += "# " + (message || "");

            self.modelStats.innerHTML = text;
        }

        // On progress update debug text.
        //
        function createModelStats() {
            self.modelStats = document.createElement("div");
            self.modelStats.className = "statspanel";
            self.container.appendChild(self.modelStats);

            self.addEventListener(et.PROGRESS_UPDATE_EVENT, function (e) {
                if (e.message) {
                    updateModelStatContent(e.message);
                }
            });


            self.fpsDisplay = document.createElement("div");
            self.fpsDisplay.className = "fps";
            self.container.appendChild(self.fpsDisplay);
        }

        this.addOptionToggle(this.debugMenu.subMenu, "Model statistics", false, function (checked) {

            if (checked && !self.modelStats) {
                createModelStats();
                updateModelStatContent("");
            }

            self.modelStats.style.visibility = (checked ? "visible" : "hidden");
            self.fpsDisplay.style.visibility = (checked ? "visible" : "hidden");

            if (checked) {
                self.impl.fpsCallback = function(fps) {
                    self.fpsDisplay.textContent = "" + (0|fps);
                }
            } else {
                self.impl.fpsCallback = null;
            }
        });

    };

    GuiViewer3D.prototype.initEscapeHandlers = function () {
        var viewer = this;

        this.addEventListener(et.ESCAPE_EVENT, function (event) {
            if (viewer.contextMenu && viewer.contextMenu.hide()) {
                return;
            }

            // Render options isn't enabled in release, so don't try to manipulate it
            if (viewer.renderoptions) {
                // Close render settings panel
                if (viewer.renderoptions.isVisible()) {
                    viewer.renderoptions.setVisible(false);
                    return;
                }
            }

            // TODO: stop any active animation

            // Reset default navigation mode:
            if (viewer.getActiveNavigationTool() !== viewer.getDefaultNavigationToolName()) {
                // Force unlock active tool:
                if (viewer.toolController)
                    viewer.toolController.setIsLocked(false);

                viewer.setActiveNavigationTool();
                HudMessage.dismiss();
                return;
            }

            // Deselect
            if (viewer.selectionActive) {
                viewer.clearSelection();
                return;
            }

            // Show all if anything is hidden
            if (!viewer.areAllVisible()) {
                viewer.showAll();
                return;
            }

            // Close open alert windows
            if (AlertBox.dismiss()) {
                return;
            }

            // Close open windows
            for (var i = 0; i < viewer.dockingPanels.length; ++i) {
                var panel = viewer.dockingPanels[i];
                if (panel.container.style.display !== "none" && panel.container.style.display !== "") {
                    // NB: Since the document structure panel state is reflected
                    //     in the toolbar, we need to update that as well.
                    if (panel.container === viewer.modelstructure) {
                        viewer.showModelStructurePanel(false);
                    } else {
                        panel.setVisible(false);
                    }
                    return;
                }
            }

            if (viewer.escapeScreenMode()) {
                return;
            }
        });
    };

    GuiViewer3D.prototype.showViewCubeUI = function (show) {
        this.viewCubeUi.setVisible(show);
    };

    GuiViewer3D.prototype.showViewCubeTriad = function (show) {
        this.viewCubeUi.showTriad(show);
    };

    GuiViewer3D.prototype.displayViewCube = function (display, updatePrefs) {
        this.viewCubeUi.displayViewCube(display, updatePrefs);
    };

    /**
     * Hides the Home button next to the ViewCube.
     * There is no info button any more.
     * Please use `displayHomeButton(show)` instead.
     *
     * @deprecated
     * @param {boolean} show
     */
    GuiViewer3D.prototype.displayHomeandInfoButton = function(show) {   // show/hide home button.
        logger.info('viewer.displayHomeandInfoButton() is deprecated. Use viewer.displayHomeButton() instead.');
        this.displayHomeButton(show);
    };

    /**
     * Hides the Home button next to the ViewCube.
     *
     * @param {boolean} show
     */
    GuiViewer3D.prototype.displayHomeButton = function(show) {   // show/hide home button.
        var home = this.container.querySelector('.homeViewWrapper');

        if(home) {
            home.style.display = show ? '' : 'none';
        }
    };


    /**
     * Returns a toolbar.
     * @param {boolean} create - If true and the toolbar does not exist, it will be created.
     * @returns {Autodesk.Viewing.UI.ToolBar} Returns the toolbar.
     */
    GuiViewer3D.prototype.getToolbar = function( create )
    {
        if (!this.toolbar) {
            if (create) {
                this.toolbar = new ToolBar( 'guiviewer3d-toolbar' );

                this.navTools = new RadioButtonGroup( TOOLBAR.NAVTOOLSID );
                this.modelTools = new ControlGroup( TOOLBAR.MODELTOOLSID );
                this.settingsTools = new ControlGroup( TOOLBAR.SETTINGSTOOLSID );

                this.toolbar.addControl(this.navTools);
                this.toolbar.addControl(this.modelTools);
                this.toolbar.addControl(this.settingsTools);

                this.container.appendChild(this.toolbar.container);
                this.toolbar.container.style.display = 'none'; // Will make visible when TOOLBAR_CREATED_EVENT fires
            }
        }
        return this.toolbar;
    };

    GuiViewer3D.prototype.showModelStructurePanel = function (show) {
        this.modelstructure.setVisible(show);
    };

    GuiViewer3D.prototype.onPanelVisible = function (panel) {

        // Shift this window to the top of the list, so that it will be closed first
        //
        this.dockingPanels.splice(this.dockingPanels.indexOf(panel), 1);
        this.dockingPanels.splice(0, 0, panel);
    };

    GuiViewer3D.prototype.updateFullscreenButton = function (mode) {
        var cls = "adsk-icon-fullscreen";

        switch (mode) {
            case ScreenMode.kNormal:
                if (!this.isScreenModeSupported(ScreenMode.kFullBrowser)) {
                    cls = 'adsk-icon-fullscreen';
                }
                break;
            case ScreenMode.kFullBrowser:
                if (this.isScreenModeSupported(ScreenMode.kFullScreen)) {
                    cls = 'adsk-icon-fullscreen';
                } else {
                    cls = 'adsk-icon-fullscreen-exit';
                }
                break;
            case ScreenMode.kFullScreen:
                cls = 'adsk-icon-fullscreen-exit';
                break;
        }

        this.settingsTools.fullscreenbutton.setIcon(cls);
    };

    GuiViewer3D.prototype.localize = function () {

        i18n.localize();

        if (this.debugMenu && this.debugMenu.debugSubMenuButton) {
            this.debugMenu.debugSubMenuButton.container.removeChild(this.debugMenu.subMenu);
            this.createDebugSubmenu(this.debugMenu.debugSubMenuButton);
        }

        ErrorHandler.localize();
    };


    /**
     * Adds a panel to the viewer. The panel will be moved and resized if the viewer
     * is resized and the panel falls outside of the bounds of the viewer.
     *
     * @param {Autodesk.Viewing.UI.PropertyPanel} panel - The panel to add.
     * @returns {boolean} True if panel was successfully added.
     *
     */
    GuiViewer3D.prototype.addPanel = function(panel) {
        var index = this.dockingPanels.indexOf(panel);
        if(index === -1) {
            this.dockingPanels.push(panel);
            return true;
        }
        return false;
    };

    /**
     * Removes a panel from the viewer. The panel will no longer be moved and
     * resized if the viewer is resized.
     *
     * @param {Autodesk.Viewing.UI.PropertyPanel} panel - The panel to remove.
     * @returns {boolean} True if panel was successfully removed.
     */
    GuiViewer3D.prototype.removePanel = function(panel) {
        var index = this.dockingPanels.indexOf(panel);
        if(index > -1) {
            this.dockingPanels.splice(index, 1);
            return true;
        }
        return false;
    };

    /**
     * Resizes the panels currently held by the viewer.
     * @param {object} [options] - An optional dictionary of options.
     * @param {array} [options.dockingPanels=all] - A list of panels to resize.
     * @param {object} [options.dimensions] - The area for the panels to occupy.
     * @param {number} options.dimensions.width - Width.
     * @param {number} options.dimensions.height - Height.
     */
    GuiViewer3D.prototype.resizePanels = function (options) {

        options = options || {};

        var toolbarHeight = this.toolbar ? this.toolbar.getDimensions().height : 0;
        var dimensions = this.getDimensions();
        var maxHeight = dimensions.height;

        if (options.dimensions && options.dimensions.height) {
            maxHeight = options.dimensions.height;
        }
        else {
            options.dimensions = {
                height: dimensions.height,
                width: dimensions.width
            };
        }

        options.dimensions.height = maxHeight - toolbarHeight;

        var viewer = this;

        var dockingPanels = options ? options.dockingPanels : null;
        if(!dockingPanels) {
            dockingPanels = viewer.dockingPanels;
        }

        var viewerRect = viewer.container.getBoundingClientRect(),
            vt = viewerRect.top,
            vb = viewerRect.bottom,
            vl = viewerRect.left,
            vr = viewerRect.right,
            vw, vh;

        if (options && options.dimensions) {
            vw = options.dimensions.width;
            vh = options.dimensions.height;
            vb = options.dimensions.height;
        } else {
            vw = viewerRect.width;
            vh = viewerRect.height;
        }

        for (var i = 0; i < dockingPanels.length; ++i) {
            dockingPanels[i].onViewerResize(vt, vb, vl, vr, vw, vh);
        }

    };

    GuiViewer3D.prototype.initExplodeSlider = function () {
        var viewer = this;

        var explodeButton = new Button('toolbar-explodeTool');
        explodeButton.setIcon("adsk-icon-explode");
        explodeButton.setToolTip("Explode model");
        viewer.modelTools.addControl(explodeButton, {index: 0});

        var htmlString = '<div class="docking-panel docking-panel-container-solid-color-b explode-submenu" style="display:none"><input class="explode-slider" type="range" min="0" max="1" step="0.01" value="0"/></div>';
        this.explodeSubmenu = stringToDOM(htmlString);

        // hack fix for iOS bug
        // range input not draggable when nested under button
        var parentDom;
        if (isIOSDevice()) {
            parentDom = document.querySelector("#toolbar-explodeTool").parentNode;
            this.explodeSubmenu.classList.add("ios");
        }
        else {
            parentDom = explodeButton.container;
        }
        parentDom.appendChild(this.explodeSubmenu);

        var slider = this.explodeSubmenu.querySelector(".explode-slider");
        viewer.explodeSlider = slider;
        slider.oninput = function (e) {
            viewer.explode(slider.value);
        };
        //oninput does not seem to work on IE11...
        slider.onchange = function (e) {
            viewer.explode(slider.value);
        };
        this.explodeSubmenu.onclick = function (e) {
            e.stopPropagation();
        };

        // hack to disable tooltip, actually also problem with ViewerSettingsPanel
        var tooltip = explodeButton.container.querySelector(".adsk-control-tooltip");

        explodeButton.onClick = function (e) {
            var state = explodeButton.getState();
            if (state === Button.State.INACTIVE) {
                explodeButton.setState(Button.State.ACTIVE);
                tooltip.style.display = "none";
                viewer.explodeSubmenu.style.display = "";

                // Explode is not handled via ToolController; log it separately for now
                logger.track({category: 'tool_changed', name: 'explode'});
            }
            else if (state === Button.State.ACTIVE) {
                explodeButton.setState(Button.State.INACTIVE);
                tooltip.style.display = "";
                slider.parentNode.style.display = "none";
                viewer.explode(0);
                viewer.explodeSlider.value = 0;
            }
        };
    };

    GuiViewer3D.prototype.initInspectTools = function () {
        var viewer = this;

        var inspectToolsButton = new Button("toolbar-inspectTools");
        inspectToolsButton.setToolTip("Inspect");
        inspectToolsButton.setIcon("measure");
        inspectToolsButton.setVisible(false);
        this.modelTools.addControl(inspectToolsButton);

        var inspectSubmenu = new RadioButtonGroup('toolbar-inspectSubMenu');
        inspectSubmenu.addClass('toolbar-vertical-group');
        inspectSubmenu.setVisible(false);
        this.modelTools.addControl(inspectSubmenu);

        // Insert at the beginning so the CSS selector works.
        inspectToolsButton.container.insertBefore(inspectSubmenu.container, inspectToolsButton.container.firstChild);

        inspectToolsButton.onMouseOver = function () {
            inspectSubmenu.setVisible(true);
        };

        inspectToolsButton.onMouseOut = function () {
            inspectSubmenu.setVisible(false);
        };

        if (isTouchDevice()) {
            inspectToolsButton.onClick = function (e) {
                inspectSubmenu.setVisible(!inspectSubmenu.isVisible());
            };
        }
    };

    GuiViewer3D.prototype.initModality = function () {

        function findToolbarParent(elem) {
            var MAX_DEPTH = 2;  // arbitrary
            var depth = 0;
            while (depth < MAX_DEPTH && elem.parentElement) {
                var eid = elem.id;
                if (eid.indexOf("toolbar-") === 0) {
                    // ignore arrow
                    if (eid.indexOf("arrow") === eid.length - 5)
                        return undefined;
                    // check if submenu, if so, return root button
                    var rootButton = findToolbarParent(elem.parentElement);
                    return rootButton || elem;
                }
                elem = elem.parentElement;
                depth++;
            }
        }

        function getButtonName(elem) {
            return elem.id.substring(8, elem.id.length);
        }

        function getButtonActive(elem) {
            return elem.classList.contains("active");
        }

        function simulateClick(elem) {
            var event = document.createEvent('Event');
            event.initEvent('click', true, true); //can bubble, and is cancellable
            elem.dispatchEvent(event);
        }

        // tool names registered for modality management
        // this mapping determines what tools are allowed together
        // when a tool is activated, all other tools but the ones allowed here will be disabled
        var modalityMap = {
            orbitTools:    { explodeTool:1 },
            panTool:       { explodeTool:1 },
            zoomTool:      { explodeTool:1 },
            beelineTool:   {},
            sectionTool:   { measureTool:0, calibrateTool:0 },
            explodeTool:   { measureTool:0, calibrateTool:0, firstPersonTool:1, bimWalkTool:1 },
            measureTool:   { section:0 },
            firstPersonTool: { explodeTool:1 },
            bimWalkTool: { explodeTool:1 }
        };

        var activeButtons = {};
        function registerButton(name, button, register) {
            activeButtons[name] = register ? button : undefined;
            // logger.log("modal "+ (register ? "+" : "-") +" " + name);
        }

        function handleModality(e) {

            var classes = (e.target.getAttribute("class") || "").split(/\s+/);

            if (classes.indexOf("clickoff") !== -1)
                return;

            var button = findToolbarParent(e.target);
            if (!button) return;

            var toolName = getButtonName(button);

            // not handled
            if (!modalityMap[toolName])
                return;

            // special case section button, do not handle if initial blank state
            // HACK: use icon class to detect this case
            if (toolName === "sectionTool" && (
                classes.indexOf("adsk-icon-section-analysis") !== -1  ||
                e.target.querySelector(".adsk-icon-section-analysis")))
                return;

            if (toolName === "measureTool" && (
                classes.indexOf("adsk-icon-measure-menu") !== -1 ||
                e.target.querySelector(".adsk-icon-measure-menu")))
                return;

            // if already registered as active
            if (activeButtons[toolName]) {
                registerButton(toolName, button, false);
            }

            // loop active buttons, deactivate (i.e., click again) if not allowed in map
            for (var k in activeButtons) {
                var b = activeButtons[k];
                if (!b)
                    continue;
                var bname = getButtonName(b);
                if (!getButtonActive(b))    // button already inactive, we're is out of sync, so we just unregister
                    registerButton(bname, b, false);
                else if (!modalityMap[toolName][bname]) // if not allowed by map
                    simulateClick(b);   // HACKY!
            }

            // finally, register active button
            registerButton(toolName, button, true);
        }

        this.toolbar.container.addEventListener("click", handleModality, true);
    };

    /**
     * Changes visibility of buttons in toolbar to accommodate as many as possible
     * given the available space.  Think of it as a media query applied to the viewer
     * canvas only (as opposed to the whole website).
     */
    GuiViewer3D.prototype.updateToolbarButtons = function(width, height) {

        var toolbar = this.getToolbar(false);
        if (!toolbar) return;

        //logger.log("resized " + width);
        var ctrl, display;

        // 310px threshold
        display = width > 310 ? "block" : "none";
        ctrl = this.modelTools.getControl('toolbar-explodeTool');
        if (ctrl) ctrl.setDisplay(display);

        // 380px threshold
        display = width > 380 ? "block" : "none";
        ctrl = this.modelTools.getControl('toolbar-collaborateTool');
        if (ctrl) ctrl.setDisplay(display);

        // 515px threshold
        display = width > 515 ? "block" : "none";
        var camMenu = this.navTools.getControl('toolbar-cameraSubmenuTool');
        if (camMenu) {
            camMenu.setDisplay(display);
            ctrl = camMenu.subMenu.getControl('toolbar-homeTool');
            if (ctrl) ctrl.setDisplay(this.navigation.isActionEnabled('gotoview') ? 'block' : 'none');
            ctrl = camMenu.subMenu.getControl('toolbar-fitToViewTool');
            if (ctrl) ctrl.setDisplay(this.navigation.isActionEnabled('gotoview') ? 'block' : 'none');
            ctrl = camMenu.subMenu.getControl('toolbar-focalLengthTool');
            if (ctrl) ctrl.setDisplay(this.navigation.isActionEnabled('fov') ? 'block' : 'none');
            ctrl = camMenu.subMenu.getControl('toolbar-rollTool');
            if (ctrl) ctrl.setDisplay(this.navigation.isActionEnabled('roll') ? 'block' : 'none');
        }

        // 700px threshold
        display = width > 700 ? "block" : "none";
        ctrl = this.modelTools.getControl('toolbar-measureTool');
        if (ctrl) ctrl.setDisplay(display);
        ctrl = this.modelTools.getControl('toolbar-sectionTool');
        if (ctrl) ctrl.setDisplay(display);

        // 740px threshold
        display = width > 740 ? "block" : "none";
        ctrl = this.navTools.getControl('toolbar-beelineTool');
        if (ctrl) ctrl.setDisplay(this.navigation.isActionEnabled('walk') ? display : 'none');
        ctrl = this.navTools.getControl('toolbar-firstPersonTool');
        if (ctrl) ctrl.setDisplay(this.navigation.isActionEnabled('walk') ? display : 'none');
        ctrl = this.navTools.getControl('toolbar-zoomTool');
        if (ctrl) ctrl.setDisplay(this.navigation.isActionEnabled('zoom') ? display : 'none');
        ctrl = this.navTools.getControl('toolbar-panTool');
        if (ctrl) ctrl.setDisplay(this.navigation.isActionEnabled('pan') ? display : 'none');
        ctrl = this.navTools.getControl('toolbar-orbitTools');
        if (ctrl) ctrl.setDisplay(this.navigation.isActionEnabled('orbit') ? display : 'none');
    };

