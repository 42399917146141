
import { getGlobal, isNodeJS } from "./compat";

var g = getGlobal();

g.LOCALIZATION_REL_PATH = "";
g.LMV_VIEWER_VERSION = BUILD_FLAG__BUILD_VERSION;
g.LMV_VIEWER_PATCH = ''; // TODO: Remove in v7.0.0
g.LMV_BUILD_TYPE = BUILD_FLAG__BUILD_TYPE;
g.LMV_RESOURCE_ROOT = "";
g.LMV_THIRD_PARTY_COOKIE = isNodeJS() ? false : undefined;

if (g.LMV_VIEWER_VERSION.charAt(0) === 'v'){
    // remove prefixed 'v'
    // Required due to TeamCity build pipeline (LMV-1361)
    g.LMV_VIEWER_VERSION = g.LMV_VIEWER_VERSION.substr(1);
}

// TODO:  This is here for now, until we find a better place for it.
//
/**
 * Returns the first source url found containing the given script name.
 * @private
 * @param {string} scriptName - Script name.
 * @returns {HTMLScriptElement} The script element whose source location matches the input parameter.
 */
export function getScript(scriptName) {
    scriptName = scriptName.toLowerCase();
    var scripts = document.getElementsByTagName('SCRIPT');
    if (scripts && scripts.length > 0) {
        for (var i = 0; i < scripts.length; ++i) {
            if (scripts[i].src && scripts[i].src.toLowerCase().indexOf(scriptName) !== -1) {
                return scripts[i];
            }
        }
    }
    return null;
}

/**
 * Inject a css file into the page.
 * There's a callback if you need to know when it gets downloaded (rare).
 * Accepts both relative and absolute URLs.
 * @private
 */
export function injectCSS(cssUrl, callback, onError) {
    var href = cssUrl.indexOf('://') > 0 ? cssUrl : getResourceUrl(cssUrl);

    // Verify that we haven't downloaded it already
    var results = document.getElementsByTagName('link');
    for (var i=0, len=results.length; i<len; i++) {
        if (results[i].href === href) {
            // Already downloaded
            callback && callback();
            return;
        }
    }

    // else, download it
    var s = document.createElement("link");
    s.setAttribute('rel',"stylesheet");
    s.setAttribute('type',"text/css");
    s.setAttribute('href', href);
    if (callback) {
        s.onload = callback;
    }
    if (onError) {
        s.onerror = onError;
    }
    document.head.appendChild(s);
}

/**
 * Download an HTML template.
 * If successful, will invoke callback(null, templateString)
 * If failure, will invoke callback("some error", null)
 * @private
 * @deprecated
 */
export function getHtmlTemplate(templateUrl, callback) {
    var href = templateUrl.indexOf('://') > 0 ? templateUrl : getResourceUrl(templateUrl);
    var request = new XMLHttpRequest();
    request.onload = requestLoad;
    request.onerror = requestError;
    request.ontimeout = requestError;
    request.open('GET', href, true);
    request.send();

    function requestError(err) {
        callback(err, null);
    }
    function requestLoad(event) {
        var content = event.currentTarget.responseText;
        callback(null, content);
    }

}

/**
 * Checks whether an experimental flag has been set into the viewer's' `config`
 * object, which happens to be the same as the extension's `options` object.
 * @private
 */
export function isExperimentalFlagEnabled(flagName, config3d) {
    if (!config3d || !Array.isArray(config3d.experimental))
        return false;
    return config3d.experimental.indexOf(flagName) !== -1;
}


/**
 * Returns the full url of a resource with version.
 * The version will be determined from the LMV_VIEWER_VERSION variable.
 * @private
 * @param {string} resourceRelativePath - The path of the resource relative to LMV_RESOURCE_ROOT.
 * @returns {string} The full resource path.
 */
export function getResourceUrl(resourceRelativePath) {
    return g.LMV_RESOURCE_ROOT + resourceRelativePath;
}

/**
 * @param {string} libNamespace - window property name expected to be loaded after library is on the document if it contains '://' will be use to resolve the url insted of libName.
 * @param {string} libName - url to load the library from.
 * @param {function} callback - success callback function
 * @param {function} onError - error callback function
 * @param {string} amdName - Should be the name module defined on the define function.
 * @description  Loads a script (e.g. an external library JS) and calls the callback once loaded. Used for delayed loading of required libraries. Accepts both relative and absolute URLs.
 * 
 * @private
 */
export function loadDependency(libNamespace, libName, callback, onError, amdName) {
    if (typeof window[libNamespace] === "undefined") {
        var s = document.createElement("SCRIPT");
        s.src = libName.indexOf('://') > 0 ? libName : getResourceUrl(libName);
        var clearCallbacks = function() {
            s.onerror = null;
            s.onload = null;
        };
        var errCallback = function() {
            clearCallbacks();
            onError && onError();
        };
        var successCallback = function() {
            clearCallbacks();
            callback && callback();
        };
        s.onload = successCallback;
        s.onerror = errCallback;
        document.head.appendChild(s);
    }
    else if (callback)
        callback();
}


// Returns the query parameter value from window url
export function getParameterByName(name) {
    if (typeof window === "undefined") {
        return "";
    }
    return getParameterByNameFromPath(name, window.location.href);
}

// return value of parameter from a url
export function getParameterByNameFromPath(name, url) {
    name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
    var regexS = "[\\?&]" + name + "=([^&#]*)";
    var regex = new RegExp(regexS);
    var results = regex.exec(url);
    if (results == null)
        return "";
    else
        return decodeURIComponent(results[1].replace(/\+/g, " "));
}


export function stringToDOM(str) {
    var d = document.createElement("div");
    d.innerHTML = str;
    return d.firstChild;
}
