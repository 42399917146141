
    "use strict";

    var TargetCrossFade = require('../../wgs/render/TargetCrossFade').TargetCrossFade;

    // Returned by animation functions to enable interruption
    function AnimControl() {
        // Function to interrupt animation
        this.stop = null;

        // False indicates that animation is stopped or finished.
        this.isRunning = true;
    }

    // Invoke an animated transition. On each frame, the given setParam() function is called
    // with a value between startValue (at startTime) and endValue (at endTime).
    //  @param {number}   startValue
    //  @param {number}   endValue
    //  @param {number}   duration     - in seconds
    //  @param {function} setParam     - callback that is called with interpolated value in [startValue, endValue]
    //  @param {function] [onFinished] - optional callback triggered when fading is done.
    //  @returns {AnimControl}
    function fadeValue(startVal, endVal, duration, setParam, onFinished) {

        var startTime = performance.now();

        var durationInMs = 1000.0 * duration;

        var reqId = 0;

        // Create control object to interrupt anim
        var control = new AnimControl();

        control.stop = function() {
            // cancel next update
            if (reqId) window.cancelAnimationFrame(reqId);
            
            control.isRunning = false;
        };

        // on each frame, call setParam and request next call until time is up
        var onNextFrame = function(timeStamp) {

            // compute unit time [0,1]
            var unitTime = (timeStamp - startTime) / durationInMs;

            // clamp unitTime to [0,1]
            unitTime = Math.max(unitTime, 0.0);
            unitTime = Math.min(unitTime, 1.0);

            // compute interpolated value
            var t = startVal + unitTime * (endVal - startVal);

            // update param
            setParam(t);

            // request next call if fading is not finished
            if (unitTime < 1.0) {
                reqId = window.requestAnimationFrame(onNextFrame);
            } else {
                control.isRunning = false;
                if (onFinished) {                
                    onFinished();
                }
            }
        }

        // start fade animation
        onNextFrame(startTime);

        return control;
    }

    // Can be replaced by THREE.Math.lerp later (not contained in our current THREE version)
    function lerp(x, y, t) {
        return ( 1 - t ) * x + t * y;
    }

    function smootherStep(t) {
        return THREE.Math.smootherstep(t, 0.0, 1.0);
    }

    function getTargetDistance() {
        var cam = NOP_VIEWER.impl.camera;
        return cam.target.clone().sub(cam.position).length();
    }

    function SimpleTransition(viewer) {

        // start/end camera
        var _startPos    = new THREE.Vector3();
        var _startTarget = new THREE.Vector3();
        var _startUp     = new THREE.Vector3();
        var _endPos      = new THREE.Vector3();
        var _endTarget   = new THREE.Vector3();
        var _endUp       = new THREE.Vector3();

        // interpolate target distance separately from orientation. Note that the target distance
        // is relevant for the orthoscale.
        var _startTargetDist = 0.0;
        var _endTargetDist   = 0.0;

        var _worldUp     = new THREE.Vector3();

        // start/end orientation matrix as quaternions
        var _qStart   = new THREE.Quaternion(); // at start
        var _qEnd     = new THREE.Quaternion(); // at end: camera looks at dst target

        // temp objects for reuse
        var _tmpVec    = new THREE.Vector3();
        var _tmpQuat   = new THREE.Quaternion();
        var _tmpObj    = new THREE.Object3D();
        var _tmpMatrix = new THREE.Matrix4();

        // Updates camera view direction based on given quaternion.
        function setFromQuaternion(camera, quat, targetDist) {

            // set target
            _tmpVec.set(0,0,-targetDist).applyQuaternion(quat);
            camera.target.addVectors(camera.position, _tmpVec);

            // set up-vector
            _tmpVec.set(0,1,0).applyQuaternion(quat);
            camera.up.copy(_tmpVec);
        }

        // Compute quaternion to rotate camera in a way that it looks towards the given target and
        // respects the given up direction.
        function computeQuaternion(result, pos, target, up) {

            // NOTE: Actually, we could just use lookAt + setFromRotationMatrix from THREE as below:
            //
            //     _tmpMatrix.lookAt(pos, target, up);
            //     result.setFromRotationMatrix(_tmpMatrix);
            //
            // However, for some target views, direction and up-vector are collinear, so that a valid up-vector
            // is not properly defined. For this case, it is essential to use the same heuristic as the
            // LMV navigation does. Otherwise, the camera up vector may suddenly flip.
            Autodesk.Viewing.Navigation.prototype.orient(_tmpObj, target, pos, up);
            result.copy(_tmpObj.quaternion);
        }

        function initQuaternions() {

            // take qStart from initial camera
            computeQuaternion(_qStart, _startPos, _startTarget, _worldUp);

            // quaternion for final view
            computeQuaternion(_qEnd, _endPos, _endTarget, _worldUp);
        }

        this.init = function(startCamera, dstPos, dstTarget, dstUp) {
            _startPos.copy(startCamera.position);
            _startTarget.copy(startCamera.target);
            _startUp.copy(startCamera.up);
            _endPos.copy(dstPos);
            _endTarget.copy(dstTarget);
            _endUp.copy(dstUp);
            _worldUp.copy(startCamera.worldup);

            _startTargetDist = _startPos.distanceTo(_startTarget);
            _endTargetDist   = _endPos.distanceTo(_endTarget);

            initQuaternions();
        };

        this.updateCamera = function(unitTime, camera) {

            var t = smootherStep(unitTime);

            // interpolate position
            camera.position.lerpVectors(_startPos, _endPos, t);

            // interpolate view direction
            THREE.Quaternion.slerp(_qStart, _qEnd, _tmpQuat, t);
            _tmpQuat.normalize();

            // interpolate target distance
            var targetDist = lerp(_startTargetDist, _endTargetDist, t);

            setFromQuaternion(camera, _tmpQuat, targetDist);

            // trigger viewer update
            camera.dirty = true;
        };

        this.updateViewerCamera = function(unitTime, viewer) {
            this.updateCamera(unitTime, viewer.impl.camera);
            viewer.impl.syncCamera();
            viewer.impl.invalidate(true, true);
        };
    }

    var _transition;

    // @param {Viewer3D}      viewer
    // @param {THREE.Vector3} destView.position  - end position
    // @param {THREE.Vector3} destView.target    - end target position
    // @param {number=2}      duration           - in seconds
    // @param {function}      onFinished         - optional callback triggered when animation is finished
    // @returns {AnimControl}
    function flyToView(viewer, destView, duration, onFinished) {

        if (!_transition)
            _transition = new SimpleTransition();

        // apply default duration
        duration = duration || 2.0;

        // init transition from current viewer camera
        var cam = viewer.impl.camera;
        _transition.init(cam, destView.position, destView.target, destView.up);

        // define onTimer handler that updates the camera
        var onTimer = function(unitTime) {
            _transition.updateViewerCamera(unitTime, viewer);
        };

        return fadeValue(0.0, 1.0, duration, onTimer, onFinished);
    }

    /**
     * Cross-fading between two RenderModels. SAO is temporarily faded out during transition.
     *
     * @param {Viewer3D} viewer
     * @param {number} prevModelId
     * @param {number} newModelId
     * @param {number} duration     - in seconds
     * @param {function} onFinished
     * @returns {AnimControl}
     */
    function crossFadeModels(viewer, prevModelId, newModelId, duration, onFinished) {

        viewer.setModelCrossFadeEnabled(true);
        viewer.setModelTargetIndex(prevModelId, 0);
        viewer.setModelTargetIndex(newModelId, 1);

        function fadeOpacity(unitTime) {
            // fade-out prev model
            viewer.setCrossFadeOpacity(0, 1.0 - unitTime);

            // fade-in new model
            viewer.setCrossFadeOpacity(1, unitTime);
        }

        function onFadeEnd() {
            // Render everything in default color buffer again
            //viewer.setModelCrossFadeEnabled(false);
            viewer.setModelTargetIndex(prevModelId, undefined);
            viewer.setModelTargetIndex(newModelId, undefined);

            if (onFinished) {
                onFinished();
            }
        }

        return fadeValue(0.0, 1.0, duration, fadeOpacity, onFadeEnd);
    }

    // TODO: We need to preserve the actual preset values instead !!
    var defaultAORadius    = 12.0;
    var defaultAOIntensity = 0.7;

    function setAOIntensity(viewer, val) {

        var renderer = viewer.impl.renderer();
        var radius    = val * defaultAORadius;
        var intensity = val * defaultAOIntensity;
        renderer.setAOOptions(radius, intensity);
        renderer.composeFinalFrame();
    }

    function fadeSAO(viewer, startVal, endVal, duration) {
        var onTimer = function(t) {
            setAOIntensity(viewer, t);
        };
        return fadeValue(startVal, endVal, duration, onTimer);
    }

    /**
     * Runs a transition (camera fly-to + cross-fade) from one model to another one.
     *
     *  @param {Viewer3D}      viewer
     *  @param {THREE.Vector3} destView.position  - end position
     *  @param {THREE.Vector3} destView.target    - end target position
     *  @param {number=5}      duration           - in seconds
     *  @param {function}      onFinished         - optional callback triggered when animation is finished
     *  @returns {AnimControl}
     */
    function startViewTransition(viewer, destView, prevModel, newModel, duration, onFinished) {

        // Fade SAO out at start and in again when transition is done
        //var saoFadeOut = fadeSAO(viewer, 1.0, 0.0, 0.5);
        //var saoFadeIn  = null;  // invoked at transition end

        var control = new AnimControl();

        var onCrossFadeFinished = function() {

            // actual transition is done (flight + crossFade)
            control.isRunning = false;
            if (onFinished) {
                onFinished();
            }                        

            // finally fade SAO in again
           // saoFadeIn = fadeSAO(viewer, 0.0, 1.0, 0.5);
        };

        var prevModelId = prevModel.id;
        var newModelId  = newModel.id;

        var flyAnim  = flyToView(viewer, destView, duration);
        var fadeAnim = crossFadeModels(viewer, prevModelId, newModelId, duration, onCrossFadeFinished);
        
        control.stop = function() {

            // stop camera at its current position
            flyAnim.stop();

            // fast-forward fading animation, so that the previous model can go away
            fadeAnim.stop();

            // stop any ongoing SAO fades
           // saoFadeOut.stop();
           // if (saoFadeIn) saoFadeIn.stop();
           // setAOIntensity(viewer, 1.0); // reset SAO state

            // Set target model instantly visible
            viewer.setModelTargetIndex(prevModelId, undefined);
            viewer.setModelTargetIndex(newModelId, undefined);
            
            // Reset isRunning flag
            var wasRunning = control.isRunning;
            control.isRunning = false;

            // Call onFinished if not done yet
            if (onFinished && wasRunning) {
                onFinished();
            }
        };

        return control;
    }

    /** Helper for smooth fadeIn/fadeOut of ground shadow and SAO 
     *  @param {Viewer3D}       viewer
     *  @param {number}         fadeDuration - in seconds
     *  @param {function(bool)} [onFadeDone] - Optional callback. Bool param is: true = faded in, false = faded out.
     */
    function ShadowFader(viewer, fadeDuration, onFadeDone) {

        // intensity multiplier for SAO and ground shadow
        var _value = 1.0;

        // AnimControl (if fading is in progress)
        var _fadeAnim = null;
        var _viewer = viewer;

        var _fadeDuration = fadeDuration;

        // If an anim is in progress, _fading indicates the direction (fading in or out)
        var _fadingIn = undefined;

        var _onFadeDone = onFadeDone;

        function onTimer(t) {
            _value = t;
            //setAOIntensity(viewer, t);
            viewer.impl.setGroundShadowAlpha(t);
        }

        function onFadeEnd() {
            _fadeAnim = null;

            // trigger optional callback
            if (_onFadeDone) {
                _onFadeDone(_fadingIn);
            }
        }

        // Make sure that SAO/Shadow is faded to full visibility
        this.shadowOn = function() {

            // If already fading in, we are done
            if (_fadeAnim && _fadingIn) {
                return;
            }

            // If fading-out, stop it
            if (_fadeAnim && !_fadingIn) {
                _fadeAnim.stop();
                _fadeAnim = null;
            }

            // already full intensity => done
            if (_value >= 1.0) {
                return;
            }

            // compute duration based on intensity change
            var fadingDist = 1.0 - _value;
            var duration = _fadeDuration * fadingDist;

            // Fade from current intensity value to 1.0
            _fadeAnim = fadeValue(_value, 1.0, duration, onTimer, onFadeEnd);

            _fadingIn = true;
        };

        this.shadowOff = function() {

            // already fading out => done
            if (_fadeAnim && !_fadingIn) {
                return;
            }

            // fading in => stop it
            if (_fadeAnim && _fadingIn) {
                _fadeAnim.stop();
                _fadeAnim = null;
            }

            // already 0 intensity => done
            if (_value <= 0.0) {
                return;
            }

            var duration = _fadeDuration * _value;
            _fadeAnim = fadeValue(_value, 0.0, duration, onTimer, onFadeEnd);

            _fadingIn = false;
        };

        this.isFading = function() {
            return _fadeAnim && _fadeAnim.isRunning;
        }
    };


    /**
     * Runs a fading-animation on a cross-fading target.
     *
     *  @param {Viewer3D}      viewer
     *  @param {number}        targetIndex        - see viewer.setModelTargetIndex()
     *  @param {number}        startOpacity       - in [0,1]
     *  @param {number}        endOpacity         - in [0,1]
     *  @param {number=5}      duration           - in seconds
     *  @param {function}      onFinished         - optional callback triggered when animation is finished
     *  @returns {AnimControl}
     */
    function fadeTarget(viewer, targetIndex, startOpacity, endOpacity, duration, onFinished) {

        var setTargetOpacity = function(val) {
            viewer.setCrossFadeOpacity(targetIndex, val);
        };
        return fadeValue(startOpacity, endOpacity, duration, setTargetOpacity, onFinished);
    }

    // Performs an image cross-fade between fade-target 0 and 1, assuming that the main scene is not visible.
    function fadeImage(viewer, startVal, endVal, duration, onFinished) {

        // Temporarily hide all models during the image fade. They would be overdrawn anyway, so rendering them would just waste time.
        var mq = viewer.impl.modelQueue();
        var models = mq.getModels().slice(); // We have to copy - otherwise the array would be empty after hiding the models
        for (var i=0; i<models.length; i++) {
            var model = models[i];
            mq.hideModel(model.id);
        }

        function onFadeDone() {

            // Set fading mode back to the default (independent decal of both targets)
            var crossFade = viewer.impl.renderer().getCrossFade();
            crossFade.setFadeMode(TargetCrossFade.FadeMode.CROSSFADE);

            // Reactivate all temporarily hidden models
            for (var i=0; i<models.length; i++) {
                var model = models[i];
                mq.showModel(model.id);
            }

            if (onFinished) {
                onFinished();
            }
        }

        // fade between both targets
        var setTargetOpacity = function(val) {

            // Fade opacity of cross-fade target 1
            viewer.setCrossFadeOpacity(0, 1.0 - val);
            viewer.setCrossFadeOpacity(1, val);

            // Viewer3DImpl skips the present step if scene is empty. Therefore, we enforce
            // present from outside when cross-fading images.
            var renderer = viewer.impl.renderer();
            renderer.presentBuffer();
        };

        // For static image fading, we use the cross-fade mode. This ensures that identical pixels between both
        // images do not vary during transition.
        var crossFade = viewer.impl.renderer().getCrossFade();
        crossFade.setFadeMode(TargetCrossFade.FadeMode.CROSSFADE);

        return fadeValue(startVal, endVal, duration, setTargetOpacity, onFadeDone);
    }

    // Runs a static image fade between the current view and a modified view.
    // (e.g. with changed model/fragment visiblity, ghosting etc.)
    // The modified view is specified via function applyState.
    //  @param {Viewer3D}   viewer
    //  @param {function()} applyState - applied after rendering the fading start image.
    //  @param {number}     duration   - in seconds
    function fadeToViewerState(viewer, applyState, duration) {

        // time-limit in ms that we allow for rendering static images
        var renderBudget = 300;

        // needed to use image-fading
        viewer.setModelCrossFadeEnabled(true);

        // Render "before" state into target 0
        viewer.impl.renderFadingImage(0, renderBudget);

        // apply viewer state modification
        applyState();

        // render image that we fade to
        viewer.impl.renderFadingImage(1, renderBudget);

        // remember if SAO was enabled before image fade, because we temporarily disable it, because
        // AO is already baked into the images that we fade.
        var rc = viewer.impl.renderer();
        var aoEnabled = rc.getAOEnabled();

        function onFadeDone() {
            // unlock targets again, so that we see the main color target again (instead of the static images)
            viewer.impl.releaseFadingImage(0);
            viewer.impl.releaseFadingImage(1);

            // recover original AO-enabled state
            rc.setAOEnabled(aoEnabled);
        }

        // disable SAO during fading
        rc.setAOEnabled(false);

        // run image fading
        fadeImage(viewer, 0.0, 1.0, duration, onFadeDone);
    }

    module.exports = {
        flyToView:          flyToView,
        crossFadeModels:    crossFadeModels,
        //fadeSAO:          fadeSAO,
        startViewTransition: startViewTransition,
        fadeTarget:         fadeTarget,
        ShadowFader:        ShadowFader,

        fadeToViewerState:  fadeToViewerState,
        lerp:               lerp,
        smootherStep:       smootherStep,
        fadeValue:          fadeValue
    };

