import { RenderBatch } from './RenderBatch';
import { FrustumIntersector } from './FrustumIntersector';
import * as THREE from "three";
import { MeshFlags } from "./MeshFlags";
import { RenderFlags } from "./RenderFlags";
import { LmvBox3 as  Box3 } from './LmvBox3';

var _tmpBox = new Box3();


export function RenderBatchLess(frags, fragOrder, start, count) {
    RenderBatch.call(this, frags, fragOrder, start, count);

    // visibility flag for scene batch. -1: not check yet, 0: not visible, 1: visible
    // this is useful when travserse the same batch again in a re-render without reset the iterator, 
    // that won't apply visibility again.
    this.visibleStats = 0;  
}

RenderBatchLess.prototype = Object.create(RenderBatch.prototype);
RenderBatchLess.prototype.constructor = RenderBatchLess;

RenderBatchLess.prototype.resetVisStatus = function() {
    this.visibleStats = 0;
};

RenderBatchLess.prototype.forEach = function(callback, drawMode, includeEmpty) {

    var indices = this.getIndices();

    var frags = this.frags;
    var sortByShaderPossible = !this.sortByShaderDone;

    var pageOutGeometryEnabled = frags.pageOutGeometryEnabled();
    var onDemandLoadingEnabled = frags.onDemandLoadingEnabled();

    //var showPF = (frags.showPF === undefined ) ? -1 : frags.showPF;

    for (var i=this.start, iEnd=this.lastItem; i<iEnd; i++) {
        var idx = indices ? indices[i] : i;
        var m = frags.getVizmesh(idx,this.renderImportance,true);
        if (sortByShaderPossible && (!m || !m.material || !m.material.program || m.geometry_proxy))
            sortByShaderPossible = false;

        // Only do this when on demand loading enabled.
        if (onDemandLoadingEnabled) {

            // If already traversed for rendering, ignore this fragment.
            if ((frags.isFlagSet(idx, MeshFlags.MESH_TRAVERSED)) && (drawMode == MeshFlags.MESH_RENDERFLAG)) {
                continue;
            }

            // for debug only, if the PF is to be displayed, then check if this fragment is in the designated PF
            //if ((showPF !== -1) && (showPF !== frags.fragments.packIds[idx])) {
            //    continue;
            //}

            // If geometry of this fragment is required...
            if (!includeEmpty && (drawMode && frags.isFlagSet(idx, drawMode))) {
                
                if (!m.geometry) {
                    // Require geometry only when we truly need it, so that it is available on later runs.
                    // Note that m.geometry will usually be null here.
                    m.geometry = frags.requireGeometry(idx);
                    if (idx < this.drawOrderRender)
                        this.drawOrderRender = idx;
                    
                    if ( !m.geometry && m.geometry_proxy )
                        m.geometry = m.geometry_proxy;
                }
                // geometry may now be set, if retrieved or a proxy box is to be displayed.
                if (m.geometry) {
                    // Set traversed flag for this fragment. Don't set it if we are drawing
                    // fragments out of order. Then they will be drawn again over anything
                    // that might have been drawn.
                    var drawn = drawMode == MeshFlags.MESH_RENDERFLAG && frags.isFlagSet(idx, MeshFlags.MESH_DRAWN);
                    if (drawMode == MeshFlags.MESH_RENDERFLAG) {
                        if (this.drawOrderRender == undefined || idx < this.drawOrderRender)
                            frags.setFlagFragment(idx, MeshFlags.MESH_DRAWN, true);
                        else {
                            continue;       // Don't draw out of order
                        }
                    }

                    // For fragments that may be paged out, check if this fragment was the
                    // last one 
                    // Only record candidates for paging if it is enabled. 
                    if (!drawn && pageOutGeometryEnabled) {

                        if (frags.pagingProxy) {
                            var mtl = frags.getMaterial(idx);
                            // no material means the object is not actually loaded yet, it's a box proxy, so should be ignored.
                            if ( mtl ) {
                                frags.pagingProxy.onGeomTraversed(m.geometry, mtl.transparent);
                            }
                        }

                    }

                }
            }
        }

        // if drawMode is given, iterate vizflags that match
        if ((includeEmpty || (m && m.geometry)) &&
            (!drawMode || frags.isFlagSet(idx, drawMode))) {

            callback(m, idx);
        }
    }

    //If all materials shaders are already available, we can sort by shader
    //to minimize shader switches during rendering. This sort will only
    //execute once and changing materials later will break the sorted order again.
    if (sortByShaderPossible)
        this.sortByShader();
};

/**
 * Sets the MESH_RENDERFLAG for a single fragment, depeneding on the drawMode and the other flags of the fragment.
 * @param {number} drawMode - One of the modes defined in Viewer3DImpl.js, e.g. RENDER_NORMAL
 * @param {number} vizflags - vizflags bitmask.
 * @param {number} idx - index into vizflags, for which we want to determine the MESH_RENDERFLAG.
 * @param {bool} hideLines
 * @param {bool} hidePoints
 * @returns {bool} Final, evaluated visibility.
 */
function evalVisibility(drawMode, vizflags, idx, hideLines, hidePoints) {

    var v;
    var vfin = vizflags[idx] & ~MeshFlags.MESH_RENDERFLAG;
    switch (drawMode) {

        case RenderFlags.RENDER_HIDDEN:
                 v = !(vfin & MeshFlags.MESH_VISIBLE); //visible (bit 0 on)
                 break;
        case RenderFlags.RENDER_HIGHLIGHTED1:
        case RenderFlags.RENDER_HIGHLIGHTED2:
                 v = (vfin & MeshFlags.MESH_HIGHLIGHTED); //highlighted (bit 1 on)
                 break;
        default:
                 v = ((vfin & (MeshFlags.MESH_VISIBLE|MeshFlags.MESH_HIGHLIGHTED|MeshFlags.MESH_HIDE)) == 1); //visible but not highlighted, and not a hidden line (bit 0 on, bit 1 off, bit 2 off)
                 break;
    }

    if (hideLines) {
        var isLine = (vfin & (MeshFlags.MESH_ISLINE | MeshFlags.MESH_ISWIDELINE));
        v = v && !isLine;
    }

    if (hidePoints) {
        var isPoint = (vfin & MeshFlags.MESH_ISPOINT);
        v = v && !isPoint;
    }

    //Store evaluated visibility into bit 7 of the vizflags
    //to use for immediate rendering
    vizflags[idx] = vfin | (v ? MeshFlags.MESH_RENDERFLAG : 0);

    return v;
}


/**
 * Checks if fragment is outside the frustum.
 * @param {bool} checkCull - indicates if culling is enabled. If false, return value is always false.
 * @param {FrustumIntersector} frustum
 * @param {FragmentList} frags
 * @param {number} idx - index into frags.
 * @returns {bool} True if the given fragment is outside the frustum and culling is enabled.
 */
function evalCulling(checkCull, frustum, frags, idx) {

    var culled = false;

    if (!_tmpBox)
        _tmpBox = new THREE.Box3();

    frags.getWorldBounds(idx, _tmpBox);
    var intersects = frustum.intersectsBox(_tmpBox);
    if (checkCull && (intersects === FrustumIntersector.OUTSIDE)) {
        culled = true;
    }
    else if (frags.pixelCullingEnable()) {
        // Check whether the projected area is smaller than a threshold,
        // if yes, do not render it.
        // ??? This may impact rendering that need to profile further.
        var area = frustum.projectedBoxArea(_tmpBox, intersects === FrustumIntersector.CONTAINS);
        area *= frustum.areaConv;
        if (area < frags.pixelCullingThreshold()) {
            culled = true;
        }
    }


    return culled;
}


RenderBatchLess.prototype.applyVisibility = function() {

    var frags, vizflags, frustum, drawMode, fragIdCb, checkCull, allHidden, done;

    function applyVisCB(m, idx) {
        if (!m && frags.useThreeMesh) {
            if (fragIdCb)
                fragIdCb(idx);
            return;
        }

        var culled = done || evalCulling(checkCull, frustum, frags, idx);

        if (culled) {
            if (m) {
                m.visible = false;
            } else {
                THREE.warn("Unexpected null mesh");
            }
            vizflags[idx] = vizflags[idx] & ~MeshFlags.MESH_RENDERFLAG;

            if (frags.pageOutGeometryEnabled()) {
                
                // Record culled geometries for paging out.
                // This fragment is culled, then move its geometry to culled geometry list.
                var geomId = frags.geomids[idx];
                var geometry = frags.geoms.getGeometry(geomId);

                if (frags.pagingProxy) {
                    frags.pagingProxy.onGeomCulled(geometry);
                }
                
            }

            return;    
        }

        var v = evalVisibility(drawMode, vizflags, idx);
        
        if (m)
            m.visible = !!v;
        
        allHidden = allHidden && !v;
    }

    return function(drawModeIn, frustumIn, fragIdCbIn) {

        //Used when parts of the same scene
        //have to draw in separate passes (e.g. during isolate).
        //Consider maintaining two render queues instead if the
        //use cases get too complex, because this approach
        //is not very scalable as currently done (it traverses
        //the entire scene twice, plus the flag flipping for each item).

        allHidden = true;
        done      = false;
        frustum   = frustumIn;
        drawMode  = drawModeIn;
        fragIdCb  = fragIdCbIn;

        frags = this.frags;

        //Check if the entire render batch is contained inside
        //the frustum. This will save per-object checks.
        var bbox = (drawMode === RenderFlags.RENDER_HIDDEN) ? this.getBoundingBoxHidden() : this.getBoundingBox();
        var containment = frustum.intersectsBox(bbox);
        if (containment === FrustumIntersector.OUTSIDE)
            done = true; //nothing to draw

        checkCull = (containment !== FrustumIntersector.CONTAINS);

        if (frags.pixelCullingEnable()) {
    
            // if this scene get culled by projected area pixel, 
            // can bail out earlier. 
            var area = this.renderImportance;
            if (area == 0) {
                area = frustum.projectedBoxArea(bbox, !checkCull);
            }
            area *= frustum.areaConv;
            if (area < frags.pixelCullingThreshold()) {
                done = true;
            }           
        }

        vizflags = this.frags.vizflags;
        
        // There is another version of forEach: forEachNoMesh which won't be used in this case.
        // Also, it seems even in RenderBatch's implementation, forEachNoMesh logic never get called (on 3d or 2d).
        this.forEach(applyVisCB, null, fragIdCb);

        return allHidden;
    };
}();
