
import { isTouchDevice, getGlobal } from "../compat";
import { isOffline, getEnv } from "../file-loaders/net/endpoints";

const global = getGlobal();

/**
 * Logging levels. Higher number means more verbose logs,
 * for example, with level 3, `info`, `warn`, or `error`
 * logs will show up in the console but `debug` and `log` won't.
 *
 * Semantics of specific levels:
 *  - debug: low-level debugging logs
 *  - log: common, higher-level debugging logs
 *  - info: helpful runtime information (even for stag/prod environments)
 *  - warn: potentially problematic situations; handled exceptions
 *  - error: definitely problematic situations; unhandled exceptions
 * @readonly
 * @enum {number}
 */
export const LogLevels = {
    DEBUG : 5,
    LOG : 4,
    INFO : 3,
    WARNING : 2,
    ERROR : 1,
    NONE : 0
};


export function Logger() {
    this.runtimeStats = {};
    this.level = -1;
    this.setLevel(LogLevels.ERROR);
}

Logger.prototype.initialize = function(options) {

    if (options.eventCallback)
        this.callback = options.eventCallback;

    this.sessionId = options.sessionId;
    if (!this.sessionId) {
        var now = Date.now() + "";
        this.sessionId = parseFloat(((Math.random() * 10000) | 0) + "" + now.substring(4));
    }

    // Initialize log level is passed in
    var logLevel = options.logLevel || options.loglevel; // typo-support
    if (typeof logLevel === 'number' && this.level !== logLevel) {
        this.setLevel(logLevel);
    }

    this.environmentInfo = {
        touch: isTouchDevice(),
        env: getEnv(),
        referer: getReferer(),
        version: global.LMV_VIEWER_VERSION,
        build_type: global.LMV_BUILD_TYPE
    };

    //Kick off with a viewer start event
    var startEvent = {
        category: "viewer_start",
        touch: this.environmentInfo.touch,
        env: this.environmentInfo.env,
        referer: this.environmentInfo.referer,
        version: this.environmentInfo.version,
        build_type: this.environmentInfo.build_type
    };
    this.track(startEvent);

    var _this = this;
    this.interval = setInterval(function() {
        _this.reportRuntimeStats();
    }, 60000);
};

Logger.prototype.shutdown = function() {
    clearInterval(this.interval);
    this.interval = undefined;
};

Logger.prototype.track = function (entry) {
    this.updateRuntimeStats(entry);

    if (isOffline() || !this.sessionId) {
        return;
    }

    entry.timestamp = Date.now();
    entry.sessionId = this.sessionId;

    if (this.callback) {
        this.callback(entry, {
            adp: false
        });
    }
};

Logger.prototype.logToADP = function() {
    this.warn('logToADP() has been deprecated and it will be removed in v7.0.0');
    return;
};

Logger.prototype.updateRuntimeStats = function(entry) {
    if (entry.hasOwnProperty('aggregate')) {
        switch (entry.aggregate) {
            case 'count':
                if (this.runtimeStats[entry.name] > 0) {
                    this.runtimeStats[entry.name]++;
                } else {
                    this.runtimeStats[entry.name] = 1;
                }
                this.runtimeStats._nonempty = true;
                break;
            case 'last':
                this.runtimeStats[entry.name] = entry.value;
                this.runtimeStats._nonempty = true;
                break;
            default:
                this.warn('unknown log aggregate type');
        }
    }
};

Logger.prototype.reportRuntimeStats = function() {
    if (this.runtimeStats._nonempty) {
        delete this.runtimeStats._nonempty;

        this.runtimeStats.category = 'misc_stats';
        this.track(this.runtimeStats);
        this.runtimeStats = {};
    }
};

Logger.prototype.setLevel = function(level) {
    if (this.level === level)
        return;

    this.level = level;

    var nullFn = function(){};
    var avpl = LogLevels;
    var self = this;

    var reportError = function() {
        if (self.callback) {
            var msg = Array.prototype.slice.call(arguments).join(' ');
            self.callback({ category: 'error', message: msg }, { adp: false });
        }
        console.error.apply(console, arguments);
    };

    // Bind to console
    this.debug = level >= avpl.DEBUG   ? console.log.bind(console) : nullFn;
    this.log   = level >= avpl.LOG     ? console.log.bind(console)   : nullFn;
    this.info  = level >= avpl.INFO    ? console.info.bind(console)  : nullFn;
    this.warn  = level >= avpl.WARNING ? console.warn.bind(console)  : nullFn;
    this.error = level >= avpl.ERROR   ? reportError : nullFn;
};

/**
 * @private
 */
function getReferer(){
    // Wrapping href retrieval due to Fortify complains
    if (typeof window !== 'undefined') {
        return encodeURI(window.location.href);
    }
    return '';
}

export let logger = new Logger();

export function setLogger(l) {
    logger = l;
}
