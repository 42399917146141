
import { Extension } from "../../src/application/Extension";



    /**
     * Use its `activate()` method to enable the explode UI.
     * 
     * The extension id is: `Autodesk.Explode`
     * 
     * @example
     *   viewer.loadExtension('Autodesk.Explode')
     * 
     * @memberof Autodesk.Viewing.Extensions
     * @alias Autodesk.Viewing.Extensions.ExplodeExtension
     * @extends {Autodesk.Viewing.Extension}
     * @constructor
     */
    export function ExplodeExtension(viewer, options) {
        Extension.call(this, viewer, options);
        this.viewer = viewer;
        this.options = options;
        this.name = "explode";
        this.slider = viewer.explodeSubmenu.querySelector(".explode-slider");
        this.explodeButton = viewer.modelTools.getControl('toolbar-explodeTool');

    }
    ExplodeExtension.prototype = Object.create(Extension.prototype);
    ExplodeExtension.prototype.constructor = ExplodeExtension;

    var proto = ExplodeExtension.prototype;

    /**
     * Displays the explode UI.
     * 
     * @memberof Autodesk.Viewing.Extensions.ExplodeExtension
     * @alias Autodesk.Viewing.Extensions.ExplodeExtension#activate
     */
    proto.activate = function() {
        if(!this.activeStatus) {
            this.viewer.explodeSubmenu.style.display = "";
            this.activeStatus = true;
        }
        return true;
    };

    /**
     * Hides the explode UI.
     * 
     * @memberof Autodesk.Viewing.Extensions.ExplodeExtension
     * @alias Autodesk.Viewing.Extensions.ExplodeExtension#deactivate
     */
    proto.deactivate = function() {
        if(this.activeStatus) {
            this.slider.parentNode.style.display = "none";
            this.viewer.explode(0);
            this.viewer.explodeSlider.value = 0;
            this.activeStatus = false;
        }
        return true;
    };
