
import { getParameterByName, loadDependency, getScript, getParameterByNameFromPath } from "./globals";
import { logger } from "./logger/Logger";
import { endpoint, setEnv, getEnv, setOfflineResourcePrefix, setOffline } from "./file-loaders/net/endpoints";
import { getGlobal, isNodeJS, disableDocumentTouchSafari } from "./compat";
import { initWorkerScript } from "./file-loaders/main/WorkerCreator";
import { ViewingService } from "./file-loaders/net/Xhr";
import { setUserName } from "./net/MessageClient";
import { initializeLocalization } from "./globalization/i18init";

var global = getGlobal();

var _token = {
    accessToken : null,
    getAccessToken : null,
    tokenRefreshInterval : null
};

export let token = _token;

let WEBGL_HELP_LINK = null;
export function getWebGLHelpLink() {
    return WEBGL_HELP_LINK;
}


    var LmvEndpoints = {
        local: {
            RTC:        ['https://rtc-dev.api.autodesk.com:443', 'https://lmv.autodesk.com:443'] //port # is required here.
        },
        dev: {
            RTC:        ['https://rtc-dev.api.autodesk.com:443', 'https://lmv.autodesk.com:443']
        },
        stg: {
            RTC:        ['https://rtc-stg.api.autodesk.com:443', 'https://lmv.autodesk.com:443']
        },
        prod: {
            RTC:        ['https://rtc.api.autodesk.com:443', 'https://lmv.autodesk.com:443']
        }
    };

    var DevApiUrls = {
        local: "",
        dev: "https://developer-dev.api.autodesk.com",
        stg: "https://developer-stg.api.autodesk.com",
        prod: "https://developer.api.autodesk.com"
    };

    var FluentApiUrls = {
        dev: "https://otg-dev.autodesk.com",
        stg: "https://otg-stg.autodesk.com",
        prod: "https://otg.autodesk.com"
    };

    export let EnvironmentConfigurations = {
        Local: {
            ROOT:       '',
            LMV:        LmvEndpoints["local"]
        },
        Development: {
            ROOT:       DevApiUrls["dev"],
            LMV:        LmvEndpoints["dev"],
            bubbleManifest: true
        },
        Staging: {
            ROOT:       DevApiUrls["stg"],
            LMV:        LmvEndpoints["stg"],
            bubbleManifest: true
        },
        Production: {
            ROOT:       DevApiUrls["prod"],
            LMV:        LmvEndpoints["prod"],
            bubbleManifest: true
        },
        AutodeskDevelopment: {
            ROOT:       DevApiUrls["dev"],
            LMV:        LmvEndpoints["dev"]
        },
        AutodeskStaging: {
            ROOT:       DevApiUrls["stg"],
            LMV:        LmvEndpoints["stg"]
        },
        AutodeskProduction: {
            ROOT:       DevApiUrls["prod"],
            LMV:        LmvEndpoints["prod"]
        },
        FluentDev: {
            ROOT:       FluentApiUrls["dev"],
            LMV:        LmvEndpoints["dev"]
        },
        FluentStaging: {
            ROOT:       FluentApiUrls["stg"],
            LMV:        LmvEndpoints["stg"]
        },
        FluentProduction: {
            ROOT:       FluentApiUrls["prod"],
            LMV:        LmvEndpoints["prod"]
        }
    };


    export function initializeEnvironmentVariable(options) {
        var env;

        // Use the enviroment that was explicitly specified.
        //
        if (options && options.env) {
            env = options.env;
        }

        // If not available, check if the environment was specified in the query parameters.
        //
        if (!env) {
            env = getParameterByName("env");
        }

        if (options && options.offlineResourcePrefix) {
            setOfflineResourcePrefix(options.offlineResourcePrefix);
        }

        if (options && options.offline && options.offline === "true") {
            setOffline(true);
        }

        // If still not available, try to resolve the environment based on the url.
        //
        if (!env) {
            switch (window.location.hostname) {
                case "developer-dev.api.autodesk.com" :
                    env = 'AutodeskDevelopment';
                    break;
                case "developer-stg.api.autodesk.com" :
                    env = 'AutodeskStaging';
                    break;
                case "developer.api.autodesk.com" :
                    env = 'AutodeskProduction';
                    break;

                case "localhost.autodesk.com" :
                    env = 'Local';
                    break;
                case "" : // IP addresses on Chrome.
                    env = 'Local';
                    break;
                case "127.0.0.1" :
                    env = 'Local';
                    break;
                default:
                    env = 'AutodeskProduction';
            }
        }

        setEnv(env);

        if (typeof window !== "undefined") {
            logger.info("Host name : " + window.location.hostname);
        }
        logger.info("Environment initialized as : " + env);
    };

    export function initializeServiceEndPoints(options) {

        // Get endpoint.
        var endp = options.endpoint;
        if (!endp) {
            var config = EnvironmentConfigurations[getEnv()];
            endp = config['ROOT'];
        }

        // Get endpoint api.
        var api = options.api || endp.ENDPOINT_API_DERIVATIVE_SERVICE_V2;

        endpoint.setEndpointAndApi(endp, api);

        if (isNodeJS())
            return;

        //Derive the root for static viewer resources based on the
        //location of the main viewer script
        var libList = [
            "viewer3D.js",
            "viewer3D.min.js"
        ];
        if (options && options.hasOwnProperty('libraryName'))
            libList.push(options.libraryName);

        var root;
        var scriptUrl;
        var hasVersionInURL = false;

        // TODO_NOP: this doesn't work for Polymer / Web Components
        for (var i=0; i<libList.length; i++) {
            var script = getScript(libList[i]);
            scriptUrl = script ? script.src : "";
            var idx = scriptUrl.indexOf(libList[i]);
            if (idx >= 0) {
                root = scriptUrl.substr(0, idx);
                if (scriptUrl.indexOf('&v=', idx) > 0 || scriptUrl.indexOf('?v=', idx) > 0) {
                    hasVersionInURL = true;
                }
                break;
            }
        }

        global.LMV_RESOURCE_ROOT = root || global.LMV_RESOURCE_ROOT;

        // Transfer version from URL-param into viewer/path/ now that Forge supports it.
        if (hasVersionInURL) {

            // However, only do this when the viewer code is being downloaded from Forge CDN
            var patchResourceRoot = false;
            var checkEnvs = ['dev', 'stg', 'prod'];
            for (var i=0; i<checkEnvs.length; ++i) {
                var theEnv = DevApiUrls[checkEnvs[i]];
                if (global.LMV_RESOURCE_ROOT.indexOf(theEnv) !== -1) {
                    patchResourceRoot = true;
                    break;
                }
            }

            if (patchResourceRoot) {
                // Embed the version into the base URL.
                global.LMV_RESOURCE_ROOT += global.LMV_VIEWER_VERSION + '/';
            }
        }

    }


    //By now, the use of cookie is Fluent-specific only
    export function refreshCookie(token, onSuccess, onError) {

        // rawGet doesn't accept undefined onSuccess callbacks
        onSuccess = onSuccess || function() {};
        ViewingService.rawGet(endpoint.getApiEndpoint(), null, "/auth/settoken", onSuccess, onError,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                },
                withCredentials: true,
                postData: "access-token=" + token
            }
        );
    }

    // Refresh the token in request header, in case that the third party cookie is disabled
    export function refreshRequestHeader(token) {

        endpoint.HTTP_REQUEST_HEADERS["Authorization"] = "Bearer " + token;

    }

    export function refreshToken(in_tokenStr, onSuccess, onError) {

        // Store the token, it will be used when third-party cookies are disabled
        _token.accessToken = in_tokenStr;

        //TODO: Fluent. Currently we need to use the cookie based approach,
        //until the server is fixed to respond to CORS pre-flight requests
        //with Authorization header.
        // At the beginning, try to store the token in cookie
        if (_token.useCookie) {

            var wrapError = function(e) {
                logger.warn("Failed to set token in cookie. Will use header instead.");
                _token.useCookie = false;
                refreshRequestHeader(in_tokenStr);
                onError && onError(e);
            };

            refreshCookie(in_tokenStr, onSuccess, wrapError);
        } else {
            refreshRequestHeader(in_tokenStr);
            onSuccess && onSuccess();
        }
    }

    export function initializeAuth(onSuccessCallback, options) {

        var shouldInitializeAuth = options ? options.shouldInitializeAuth : undefined;
        if (shouldInitializeAuth === undefined) {
            var p = getParameterByName("auth");
            shouldInitializeAuth = (p.toLowerCase() !== "false");
        }

        //Skip Auth in case we are serving the viewer locally
        if (getEnv() == "Local" || !shouldInitializeAuth) {
            setTimeout(onSuccessCallback, 0);
            endpoint.setUseCredentials((typeof options.useCredentials !== "undefined") ? options.useCredentials : false);
            return endpoint.getUseCredentials();
        }

        endpoint.setUseCredentials((typeof options.useCredentials !== "undefined") ? options.useCredentials : true);

        _token.useCookie = options.useCookie;

        //Must zero this out every time the initializer is called -- which could happen
        //several times with the same globally loaded LMV script/module
        if (_token.tokenRefreshInterval) {
            clearTimeout(_token.tokenRefreshInterval);
            _token.tokenRefreshInterval = null;
        }

        var accessToken;

        function onGetAccessToken(token /* access token value. */, expire /* expire time, in seconds. */) {
            accessToken = token;

            var isFirstTimeInit = !_token.tokenRefreshInterval;

            if (!isFirstTimeInit) {
                refreshToken(accessToken);
            } else {
                //The goal of onSuccessCallback is to continue viewer initialization.
                //In case refreshToken fails, we don't really want to block viewer initialization
                //(in order to be able to display error messages later on, or retry the token refresh, etc.)
                //This is why we use the same callback regardless of result.
                refreshToken(accessToken, onSuccessCallback, onSuccessCallback);
            }

            if (typeof expire !== "number")
                expire = 3599;

            var interval = expire - 5; // Refresh 5 seconds before token expire.
            if (interval <= 0) {
                // We can't get a precise upper bound if the token is such a short lived one (expire in a minute),
                // so just use the original one.
                interval = expire;
            }

            _token.tokenRefreshInterval = setTimeout(function() {options.getAccessToken(onGetAccessToken);}, interval * 1000);
        }

        if (options && options.getAccessToken) {
            _token.getAccessToken = options.getAccessToken;

            accessToken = options.getAccessToken(onGetAccessToken);

            //Backwards compatibility with the old synchronous API
            if (typeof accessToken === "string" && accessToken) {
                refreshToken(accessToken, onSuccessCallback);
            }

        } else if (options && options.accessToken) {
            accessToken = options.accessToken;
            refreshToken(accessToken, onSuccessCallback);
        } else {
            accessToken = getParameterByName("accessToken");
            if (!accessToken) {
                logger.error("No access token is provided, but authorization requested. This is a problem.");
            }
            refreshToken(accessToken, onSuccessCallback);
        }

        //TODO: this seems like a pointless thing to return
        return endpoint.getUseCredentials();
    }

    export function initializeLogger(options) {

        logger.initialize(options);

        var logLevel = getParameterByName("logLevel");
        if (logLevel) {
            logger.setLevel(parseInt(logLevel));
        }
    }

    function initializeCDN(options) {

        if (options && options.env === 'Local')
            return;

        if (!endpoint.getCdnRedirectUrl())
            return;

        ViewingService.rawGet(endpoint.getCdnRedirectUrl(), null, null,
            function onSuccess(res) {
                if (res && res.length) {
                    endpoint.setCdnUrl(res);
                    logger.info("CDN_ROOT is: " + res);
                }
            },
            function onError() {
            },
            {
                withCredentials: false,
                responseType: "text"
            }
        );
    }

    /**
     * Normalizes memory options passed into the viewer instance or stated in the URL
     * The URL parameter to check is `viewermemory` and has a number value that represents
     * the MegaByte memory limit.
     * @example:
     *      ?viewermemory=500 ----- set memory limit of 500 MB
     *      ?viewermemory=500f ---- force memory limit of 500 MB, which activates on-demand-loading mechanism (debug).
     * @private
     */
    export function processMemoryOptions(config3d) {

        if (!config3d)
            return;

        var memLimit;
        // Verify memory values are valid/consistant
        if (config3d.memory) {
            memLimit = parseInt(config3d.memory.limit) | 0;
            var forced = (config3d.memory.debug && config3d.memory.debug.force) || false;
            if (forced && memLimit <= 0) {
                config3d.memory.force = false; // disable forced when the limit is not valid.
                logger.warn('config.memory.limit value is invalid: (' + config3d.memory.limit + ')');
            }
        }

        // If URL argument is passed in, override limit specified through code
        var urlValue = getParameterByName('viewermemory');
        memLimit = parseInt(urlValue);
        // If urlValue isn't a number, memLimit will be a NaN and comparison will fail.
        if (memLimit >= 0) {
            // Now, truncate memLimit to an integer.
            memLimit = memLimit | 0;
            // Only allow forced when memLimit > 0, memLimit == 0 disables the limit.
            var isForced = memLimit > 0 && urlValue.charAt(urlValue.length-1).toLowerCase() === 'f'; // Check if there is an F at the end.
            config3d.memory = {
                limit: memLimit,
                debug: { force: isForced }
             };
            if (isForced) {
                logger.info('Forcing memory limit to URL param: (' + memLimit + ' MegaBytes).');
            } else {
                logger.info('Setting memory limit to URL param: (' + memLimit + ' MegaBytes).');
            }
        } else {
			// don't issue warning if viewermemory parameter (e.g., "&viewermemory=500") is not in URL at all
			if ( urlValue !== "" )
				logger.warn('Invalid viewermemory URL param value: (' + urlValue + ')');
        }
    };


    export function initializeUserInfo(options) {
        if (!options || !options.userInfo) return;
        setUserName(options.userInfo.name);
    };

    /**
     * Helper class for initializing the viewer runtime.
     *
     * Includes:
     *  - End points of cloud services the viewer uses, like viewing service and search service.
     *  - Authentication and authorization cookie settings on the client side.
     *  - Misc runtime environment variables and viewer configurations parameters.
     *
     * @constructor
     * @param {object} options - The options object contains configuration parameters used to do initializations. If no
     * access token or authentication callback is provided, the Initializer will fall back
     * on an access token provided in the URL query string, or a previous access token stored in
     * the cookie cache, if available. The static function Autodesk.Viewing.createInitializerOptions() can be used to create
     * an object with all the supported attributes.
     * @param {string} [options.env] - Can be "Development", "Staging" or "Production", for viewers running without PAAS
     * endpoints. Can be "AutodeskDevelopment", "AutodeskStaging", or "AutodeskProduction"
     * for viewers running with PAAS endpoints.
     * @param {function} [options.getAccessToken] - An function that provides an access token asynchronously.
     * The function signature is `getAccessToken(onSuccess)`, where onSuccess is a callback that getAccessToken
     * function should invoke when a token is granted, with the token being the first input parameter for the
     * onSuccess function, and the token expire time (in seconds) being the second input parameter for the
     * function. Viewer relies on both getAccessToken and the expire time to automatically renew token, so
     * it is critical that getAccessToken must be implemented as described here.
     * @param {string} [options.accessToken] - An access token.
     * @param {string} [options.webGLHelpLink] - A link to a help page on webGL if it's disabled.
     * @param {string} [options.language] - Preferred language code as defined in RFC 4646, such as "en", "de", "fr", etc.
     * If no language is set, viewer will pick it up from the browser. If language is not as defined in RFC,
     * viewer will fall back to "en" but the behavior is undefined.
     * @param {function} callback - A method the client executes when initialization is finished.
     * @example
     *  var options = {
     *     env: "AutodeskProduction",
     *     language: "en",
     *     webGLHelpLink: "http://my.webgl.help.link",
     *     getAccessToken: function(onSuccess) {
     *         var accessToken, expire;
     *         // Code to retrieve and assign token value to
     *         // accessToken and expire time in seconds.
     *         onSuccess(accessToken, expire);
     *     }
     *  };
     *  var callback = function() {
     *     alert("initialization complete");
     *  };
     *  Autodesk.Viewing.Initializer(options, callback);
     */
    export function Initializer(options, callback) {

        function init() {

            //Kick off a request for the web worker script, so it loads in parallel with three.js
            initWorkerScript();

            initializeAuth(callback, options);
            initializeLocalization(options);
            initializeUserInfo(options);
        }

        if (isNodeJS()) {

            initializeEnvironmentVariable(options);
            initializeServiceEndPoints(options);
            initializeLogger(options);
            initializeCDN(options);

            initializeAuth(callback, options);

        } else {

            if (options.webGLHelpLink)
                WEBGL_HELP_LINK = options.webGLHelpLink;

            initializeEnvironmentVariable(options);
            initializeServiceEndPoints(options);
            initializeLogger(options);
            initializeCDN(options);
            disableDocumentTouchSafari();

            //Load Promise (IE11), and other delay loaded dependencies then continue initialization
            loadDependency('Promise', 'es6-promise.min.js', function() { // Usually a no-op
                init();
            }, null, 'es6-promise-polyfill');
        }
    }
