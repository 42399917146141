//Simple base to blend highlight targets

import screen_quad_uv_vert from './shaders/screen_quad_uv_vert.glsl';
import highlight_frag from './shaders/highlight_frag.glsl';
import { Vector4 } from "three";

export let HighlightShader = {

    uniforms: {
        "tHighlight0": { type: "t", value: null },
        "tHighlight1": { type: "t", value: null },
        "edgeColor"  : { type: "v4", value: new Vector4(1, 1, 1, 1) }
    },

    vertexShader: screen_quad_uv_vert,
    fragmentShader: highlight_frag

};
