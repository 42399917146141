
import * as THREE from "three";

// Controls the way in which fading targets are applied in the blend shader
export const FadeMode = {
    // Just blend each target independently on top of main color target (default)
    DECAL: 0,

    // Crossfades between target 0 and 1 using crossFadeOpacity1 as mix param.
    // crossFadeOpacity0 is not used in this mode. Advantage is that colors keep
    // more consistent when fading between two images.
    CROSSFADE: 1
};


/**
 * @class TargetCrossFade is used by RenderContext to implement optional cross-fading
 * between different render targets. By default (no cross-fading), all scenes are rendered
 * to the default color buffer by RenderContext. When using targetCrossFade, a scene may
 * be rendered to a separate target, which is blended with the default color target in the blend pass.
 *  @param {LmvShaderPass} blendPass      - see RenderContext
 */
export function TargetCrossFade(blendPass) {

    // {THREE.WebGLRenderTarget} of type RGBA with own depth buffer. Selected scenes are rendered into this target
    // and overlayed on top of the main scene.
    var _fadeTargets    = [];
    var _numFadeTargets = 2;
    var _enableClear    = [true, true]; // Clear may be disabled when fading to static images

    var _blendPass = blendPass;

    var _clearColor = new THREE.Color();

    // {number[]} Indexed by modelId. Defines the index of the Render target to which a RenderModel is rendered.
    //           0/undefined: default color buffer, 1:
    var _modelId2TargetIndex = [];

    var _fadeMode = FadeMode.DECAL;

    // see setSaoHeuristicEnabled() comment
    var _enableSaoHeuristic = true;

    this.setModelTargetIndex = function(modelId, targetIndex) {
        _modelId2TargetIndex[modelId] = targetIndex;
    };

    // By default (true), we exclude models from depth buffer rendering (used for SAO) if they have reduced opacity.
    // Disabling it makes sure that models are always rendered to the depth target - no matter which opacity they have.
    this.setSaoHeuristicEnabled = function(enabled) {
        _enableSaoHeuristic = enabled;
    };

    //which model is used for AO (i.e. which one is more opaque)
    this.getRenderSao = function(scene) {

        if (!_enableSaoHeuristic) {
            // Never suppress depth-writing - independent of target opacity.
            return true;
        }

        var index = scene.modelId && _modelId2TargetIndex[scene.modelId];

        if (index === undefined)
            return true;

        var idx = index;
        var maxOpacity = this.getCrossFadeOpacity(index);

        var numTargets = 2;
        for (var i=0; i<numTargets; i++) {
            var op = this.getCrossFadeOpacity(i);
            if (op > maxOpacity) {
                idx = i;
                maxOpacity = op;
            }
        }

        return idx == index;
    };

    this.setCrossFadeOpacity = function(targetIndex, opacity) {
        var uniformName = 'crossFadeOpacity' + targetIndex;
        _blendPass.uniforms[uniformName].value = opacity;
    };

    this.getCrossFadeOpacity = function(targetIndex) {
        var uniformName = 'crossFadeOpacity' + targetIndex;
        return _blendPass.uniforms[uniformName].value;
    };

    // Activate target-blending for blendPass and assigns current _blendTarget as source
    this.updateBlendPass = function(numFadeTargets) {

        if (numFadeTargets === undefined) {
            numFadeTargets = _fadeTargets.length;
        }

        // Update NUM_CROSSFADE_TARGETS shader-define if necessary
        var macroName   = 'NUM_CROSSFADE_TARGETS';
        var oldMacroVal = _blendPass.material.defines[macroName];
        var newMacroVal = (numFadeTargets ? numFadeTargets : undefined);
        if (oldMacroVal !== newMacroVal) {

            if (numFadeTargets) {
                _blendPass.material.defines[macroName] = newMacroVal;
            } else {
                // remove define from blend pass. Note that setting to 'undefined' would not work here,
                // because the macro would still exist and would have the text value 'undefined'
                delete _blendPass.material.defines[macroName];
            }
            _blendPass.material.needsUpdate = true;
        }

        for (var i=0; i<numFadeTargets; i++) {
            var uniformName = 'tCrossFadeTex' + i;
            _blendPass.uniforms[uniformName].value = _fadeTargets[i];
        }

        // Update fade-mode macro
        var modeMacroName = 'TARGET_FADE_MODE';
        var prevMode = _blendPass.material.defines[modeMacroName];
        if (prevMode !== _fadeMode) {
            _blendPass.material.defines[modeMacroName] = _fadeMode;
            _blendPass.material.needsUpdate = true;
        }
    };

    /* @param {number} mode - See FadeMode enum above. */
    this.setFadeMode = function(mode) {
        if (mode !== FadeMode.DECAL && mode !== FadeMode.CROSSFADE) {
            // A wrong value causes a shader compile error - so it's better to check here.
            console.error("Unexpected fade mode enum");
            return;
        }
        _fadeMode = mode;
        this.updateBlendPass();
    };

    /** Clear with opacity 0.0 */
    this.clearTarget = function(renderer) {
        // clear RGBA blend target with black + opacity 0.0 and default clearDepth
        // Note that the blend target needs an own z-buffer.
        renderer.setClearColor(_clearColor, 0.0);

        for (var i=0; i<_fadeTargets.length; i++) {
            if (_enableClear[i]) {
                renderer.clearTarget(_fadeTargets[i], true, true);
            }
        }
    };

    // Clear can be temporarily disabled when using targets for static image fading.
    this.setClearEnabled = function(targetIndex, enabled) {
        _enableClear[targetIndex] = enabled;
    };

    this.disposeTargets = function() {
        for (var i=0; i<_fadeTargets.length; i++) {
            var target = _fadeTargets[i];
            if (target) {
                target.dispose();
            }
            _fadeTargets[i] = null;
        }
    };

    this.dtor = function() {
        this.disposeTargets();
        this.updateBlendPass();
    };

    /* Determines to which color target a scene will be rendered.
     *  @param {THREE.Scene|RenderBatch} scene         - scene to be rendered
     *  @param {THREE.WebGLRenderTarget} defaultTarget - color target that RenderContext would use by default
     */
    this.chooseColorTarget = function(scene, defaultTarget) {

        // Check if scene is associated with a render model that is assigned to a cross-fade target
        var index = scene.modelId && _modelId2TargetIndex[scene.modelId];

        // use default target if nothing else is assigned
        if (index === undefined) {
            return defaultTarget;
        }

        // use cross-fade target
        return _fadeTargets[index];
    };

    /* Called by RenderContext if target sizes or formats may change. Makes sure that
     * the blendTarget exists and has matching size.
     *  @param {number} width          - render target width
     *  @param {number} height         - ...
     *  @param {bool}   [useHdrTarget] - if true, we use a float-type target
     *  @param {bool}   [force]        - force reallocate, even if width/height keep the same
     */
    this.updateTargets = function(width, height, force, useHdrTarget) {

        for (var i=0; i<_numFadeTargets; i++) {

            var target = _fadeTargets[i];

            // skip if no update is needed
            if (!force && target && target.width === width && target.height === height) {
                continue;
            }

            // dispose any old target
            if (target) {
                target.dispose();
            }

            target = new THREE.WebGLRenderTarget(width, height,
                {   minFilter: THREE.LinearFilter,
                    magFilter: THREE.LinearFilter,
                    format: THREE.RGBAFormat,
                    type: useHdrTarget ? THREE.FloatType : THREE.UnsignedByteType,
                    //anisotropy: Math.min(this.getMaxAnisotropy(), 4),
                    stencilBuffer: false
                });
            // three.js has a flaw in its constructor: the generateMipmaps value is always initialized to true
            target.generateMipmaps = false;

            _fadeTargets[i] = target;
        }
    };

    /**
     * Starts offscreen rendering into one of the cross-fade targets.
     * Frames rendered between beginRenderFadeImage(i) and endRenderFadeImage() are rendered into a fading target
     * instead of the canvas. In this way, static images can be pre-rendered and faded.
     *  @param {number}        fadeTargetIndex - must be 0 or 1.
     *  @param {RenderContext} renderContext
     */
    this.beginRenderToFadeTarget = function(fadeTargetIndex, renderContext) {

        // Make sure that blend pass does not use the fadingTargets for reading while we are writing to one of them
        this.updateBlendPass(0);

        // make presentBuffer calls write into _offscreenTarget
        var target = _fadeTargets[fadeTargetIndex];
        renderContext.setOffscreenTarget(target);

        // protect fade-target from clear, so that the image will not be overwritten in subsequent frames
        this.setClearEnabled(fadeTargetIndex, false);
    };

    /**
     * Finish offscreen rendering into fading target. The rendered result is now overlayed on top of
     * the normal rendering result.
     *
     * @param {RenderContext} renderContext
     */
    this.endRenderFadeTarget = function(renderContext) {

        // render to canvas again
        renderContext.setOffscreenTarget(null);

        // make blend pass use fade targets again
        this.updateBlendPass();
    };
}

// expose enum
TargetCrossFade.FadeMode = FadeMode;

