import { pathToURL, ViewingService } from "../../net/Xhr";
import { logger } from "../../../logger/Logger";
import { BVHBuilder } from "../../../wgs/scene/BVHBuilder";
import { OtgFragInfo } from "../../workers/OtgWorker";
import { initPlacement, transformAnimations } from "../common/SvfPlacementUtils";
import { blobToJson, getHexStringPacked, unpackHexString } from '../common/StringUtils';
import { LmvVector3 } from '../../../wgs/scene/LmvVector3';
import { MeshFlags } from '../../../wgs/scene/MeshFlags';
import { LmvBox3 } from '../../../wgs/scene/LmvBox3';
import { LmvMatrix4 } from '../../../wgs/scene/LmvMatrix4';
import { LocalDbCache } from "./LocalDbCache";
import { allocateUintArray } from "../../../wgs/scene/IntArray";

var FLUENT_CDN_URN = "urn:adsk.fluent:fs.file:";


function ProgressiveReadContext(itemCB, defaultByteStride) {

	var currentRow = 0;
	var stride;
	var byteStride;
	var version;
	var bdata;
	var fdata;
	var idata;

	function readHeader(receiveBuffer) {
		var headerBytes = new Uint8Array(4);

		if (typeof receiveBuffer === "string") {
			for (var i=0; i<4; i++)
				headerBytes[i] = receiveBuffer.charCodeAt(i);
		} else {
			for (var i=0; i<4; i++)
				headerBytes[i] = receiveBuffer[i];
		}

		byteStride = (headerBytes[1] << 8) | headerBytes[0];

		if (!byteStride)
			byteStride = defaultByteStride || 0;

		if (!byteStride)
			logger.error("Unknwon byte stride.");

		if (byteStride % 4)
			logger.error("Expected byte size to be multiple of 4, but got " + byteStride);

		version = (headerBytes[3] << 8) | headerBytes[2];
		stride = byteStride/4;

		bdata = new Uint8Array(byteStride);
		fdata = new Float32Array(bdata.buffer);
		idata = new Uint32Array(bdata.buffer);

		if (version > 0) {
			//currently unused
			//var flags = idata[offset+3];
		}
	}


	this.onData = function(receiveBuffer, receivedLength, finalCall) {

        //The node.js native loader uses a manually resized Buffer to accumulate data
        //and the buffer length is usually bigger than the length of the usable data
        //inside the buffer. The web browser loader uses a different code path
        //where the buffer size and content are equal and not sent with the callback.
	    if (receivedLength === undefined)
	        receivedLength = receiveBuffer.length;

		var isString = (typeof receiveBuffer === "string");

		while (true) {
			//On the first progress event, read the header
			if (!currentRow && receivedLength >= 4) {
				readHeader(receiveBuffer);
				currentRow++;
			} else if (receivedLength < 4) {
				return false;
			}

			var streamOffset = currentRow * byteStride;
			var endOffset = streamOffset + byteStride;

			if (receivedLength < endOffset)
				return finalCall;

			if (isString) {
				for (var j=0; j<byteStride; j++) {
					bdata[j] = receiveBuffer.charCodeAt(j + streamOffset) & 0xff;
				}
			} else {
				for (var j=0; j<byteStride; j++) {
					bdata[j] = receiveBuffer[j + streamOffset];
				}
			}

			//The callback will return true if it was able
			//to process the item at this time. If not, we will
			//call it later with the same item, until it accepts it.
			if (itemCB(currentRow-1)) { //-1 to account for the header record which has no real data
				currentRow++;
			} else {
				return false;
			}
		}
	};

	this.onEnd = function(receiveBuffer, receivedLength) {
		var isDoneProcessing = this.onData(receiveBuffer, receivedLength, true);

		//Remember the response data in case there is a dependency that hasn't loaded yet
		//and we need to delay processing
		if (!isDoneProcessing) {
			this.rawData = receiveBuffer;
			this.rawLength = receivedLength;
        }
	};

	this.flush = function() {

		if (!this.rawData)
			return;

		var isDoneProcessing = this.onData(this.rawData, this.rawLength, true);
		if (isDoneProcessing)
			this.rawData = null;
	};

	this.idata = function() { return idata; };
	this.fdata = function() { return fdata; };
	this.bdata = function() { return bdata; };
	this.version = function() { return version; };
	this.byteStride = function() { return byteStride; };

}


export function OtgPackage() {


	this.materials = null; //The materials json as it came from the SVF

	this.fragments = null; //will be wrapped in a FragmentList

	this.geompacks = [];

	this.propertydb = {
		attrs : [],
		avs: [],
		ids: [],
		values: [],
		offsets: []
	};

	this.bbox = null; //Overall scene bounds

	this.animations = null; // animations json

	this.pendingRequests = 0;

	this.globalOffset = { x: 0, y: 0, z: 0 };

	this.pendingRequests = 0;
	this.initialLoadProgress = 0;

	this.materialIdToHash = [];

	this.aborted = false;

	this.materialCache = new LocalDbCache();
	this.materialCache.open();
}

OtgPackage.prototype.getMaterialHash = function(materialIndex) {

	var cached = this.materialIdToHash[materialIndex];
	if (cached)
		return cached;

	// bytes per SHA1 hash
	var stride = this.materialHashes.byteStride;

	//get the hash string that points to the material
	var matHash = getHexStringPacked(this.materialHashes.hashes, materialIndex * stride, stride);

	this.materialIdToHash[materialIndex] = matHash;

	return matHash;
};

OtgPackage.prototype.getGeometryHash = function(geomIndex) {

	// bytes per SHA1 hash
	var stride = this.geomMetadata.byteStride;

	//get the hash string that points to the geometry
	var gHash = getHexStringPacked(this.geomMetadata.hashes, geomIndex * stride, stride);

	return gHash;
};


//Set up the fragments, materials, and meshes lists
//which get filled progressively as we receive their data
OtgPackage.prototype.initEmptyLists = function(loadContext) {

	var svf = this;

	var frags = svf.fragments = {};
	frags.length = svf.metadata.stats.num_fragments;
	frags.numLoaded = 0;
	frags.boxes = loadContext.fragmentTransformsDouble ? new Float64Array(frags.length*6) : new Float32Array(frags.length*6);
	frags.transforms = loadContext.fragmentTransformsDouble ? new Float64Array(frags.length * 12) : new Float32Array(frags.length * 12);
	frags.materials = allocateUintArray(frags.length, svf.metadata.stats.num_materials);
	frags.geomDataIndexes = allocateUintArray(frags.length, svf.metadata.stats.num_geoms);
	frags.fragId2dbId = new Int32Array(frags.length);
	frags.visibilityFlags = new Uint8Array(frags.length);
	frags.mesh2frag = {};
	frags.topoIndexes = null;

	svf.geomMetadata = {
		hashes : null,
		byteStride: 0,
		version: 0,
		numLoaded: 0,
		hashToIndex: {}
	};

	svf.materialHashes = {
		hashes: null,
		byteStride: 0,
		version: 0,
		numLoaded: 0
	};

	//Shell object to make it compatible with SVF.
	//Not sure which parts of this are really needed,
	//SceneUnit is one.
	svf.materials = {
		"name":	"LMVTK Simple Materials",
		"version":	"1.0",
		"scene":	{
			"SceneUnit":	8215,
			"YIsUp":	0
		},
		materials: {}
	};


};

//TODO: this may be better done in ViewingServiceXhr, so that property database files
//which are done by the PropDbLoader can reuse the logic
function generateUrl(loadContext, path) {

    if (path.indexOf("file://") === 0) {
        return path;
    }
    
	//Two cases here, plus the case for local debugging using absolute URL.
	//1. We get a path which has the fluent URN prefix, we lat that through unchanged.
	//   This happens for the root otg_manifest json file.
	//2. A path relative to the otg manifest file. This gets the path of the manifest as base path
	//   and then we have to map the constructed full fluent urn to a fluent path, as done for the manifest file.
	//3. Absolute URLs are pass through.

	if (path.indexOf("urn:adsk.fluent:fs.file:") === 0) {
		return path;
	} else {
		//If the path is relative, the basePath will most likely
		//add the urn:adsk.fluent:fs.file: prefix as in the case above
		if (path.indexOf("https://") !== 0 && path.indexOf("http://") !== 0)
			return loadContext.basePath + path;
	}

	return path;
}


OtgPackage.prototype.loadAsyncResource = function(loadContext, resourcePath, responseType, callback, onprogress) {

	//Launch an XHR to load the data from external file
	var svf = this;

	this.pendingRequests ++;

	function xhrCB(responseData) {
		svf.pendingRequests--;

		callback(responseData);
	}

	resourcePath = generateUrl(loadContext, resourcePath);

	ViewingService.getItem(loadContext, resourcePath,
							xhrCB,
							loadContext.onFailureCallback,
								{
									responseType:responseType || "arraybuffer",
									onprogress: onprogress
								}
							);

};


OtgPackage.prototype.loadAsyncProgressive = function(loadContext, resourcePath, ctx, resourceName, onFailureCallback) {

	var svf = this;

	resourcePath = generateUrl(loadContext, resourcePath);

	ViewingService.getItem(loadContext, resourcePath,
							function onDone(data, receivedLength) {

								ctx.onEnd(data, receivedLength);

								svf.postLoad(loadContext, resourceName, ctx, data);
							},
							onFailureCallback || loadContext.onFailureCallback,
								{
									responseType: "text",
									onprogress: function onProgress(receiveBuffer, receivedLength) {

										//TODO: abort the xhr without waiting for progress
										//To do that we need to remember the XHR when calling getItem above.
										if (svf.aborted) {
											request.abort();
											return;
										}

										//Read as many fragments as we can at this time
										ctx.onData(receiveBuffer, receivedLength, false);
									}
								}
							);

};


OtgPackage.prototype.loadMetadata = function(loadContext, path) {

	var svf = this;

	this.loadAsyncResource(loadContext, path, "json", function(data) {

		//For OTG, there is a single JSON for metadata and manifest,
		//and it's the root
		svf.metadata = data;
		svf.manifest = svf.metadata.manifest;

		svf.processMetadata(loadContext);

		svf.initEmptyLists(loadContext);

		var manifest = svf.metadata.manifest;

		//Add the shared property db files to the property db manifest
		//TODO: this is a bit hacky and hardcoded
		var spdb = manifest.shared_assets.pdb;
		for (var p in spdb) {
			if (svf.propertydb[p])
				svf.propertydb[p].push(spdb[p]);
			else {
				//Skip this property db file from the list,
				//we don't know how to handle it.
				//This will always happen to the dbid.idx file, which is only used
				//server side during translation.
			}

		}

		var pdb = manifest.assets.pdb;
		for (var p in pdb) {
			if (svf.propertydb[p])
				svf.propertydb[p].push(pdb[p]);
			else {
				//Skip this property db file from the list,
				//we don't know how to handle it.
				//This will always happen to the dbid.idx file, which is only used
				//server side during translation.
			}
		}

		//Optional resources

		if (manifest.assets.animations) {
			svf.loadAsyncResource(loadContext, manifest.assets.animations, "json", function(data) {
				svf.animations = data;
				transformAnimations(svf);
			});
		}

		if (manifest.assets.topology) {
			svf.loadAsyncResource(loadContext, path, "json", function(data) {
				svf.topology = data;
			});
		}

		loadContext.onLoaderEvent("otg_root");
		svf.postLoad(loadContext, "metadata");
	});

};


OtgPackage.prototype.loadFragmentList = function(loadContext, path) {

	var svf = this;

	var _t = new LmvVector3();
	var _s = new LmvVector3();
	var _q = { x:0, y:0, z:0, w:1 };
	var _m = new LmvMatrix4(true);
	var _b = new LmvBox3();
	var _zero = { x:0, y:0, z:0 };

	var flagsOffset = 3; 
	var txOffset = 4;

	var ctx = new ProgressiveReadContext(readOneItem, 13*4);

	function readOneItem(i) {

		//Fragments have ot wait for the metadata (placement transform)
		//before they can be fully processed
		if (!svf.metadata)
			return false;

		var idata = ctx.idata();
		var fdata = ctx.fdata();
		var offset = 0;

		var frags = svf.fragments;
		var meshid = frags.geomDataIndexes[i] = idata[offset];
		var materialId = frags.materials[i] = idata[offset+1];

		//check if the fragment's material and geometry hashes are already known
		//If not, then pause fragment processing until they are
		//NOTE: mesh ID and material ID are 1-based indices.
		if (meshid > svf.geomMetadata.numLoaded
		|| materialId > svf.materialHashes.numLoaded) {
			//console.log("Delayed fragment", i);
			return false;
		}

		var dbId = frags.fragId2dbId[i] = idata[offset+2];

		//Add the fragment's mesh to the reverse mapping of geom->fragment
		var meshRefs = frags.mesh2frag[meshid];
		if (meshRefs === undefined) {
			//If it's the first fragments for this mesh,
			//store the index directly -- most common case.
			frags.mesh2frag[meshid] = i;
		}
		else if (!Array.isArray(meshRefs)) {
			//otherwise put the fragments that
			//reference the mesh into an array
			frags.mesh2frag[meshid] = [meshRefs, i];
		}
		else {
			//already is an array
			meshRefs.push(i);
		}

		var lo = svf.metadata.fragmentTransformsOffset || _zero;

		// Read mesh flags (currently, we only support bitvalue 1 to mark hidden-by-default meshes)
		var fo = offset + flagsOffset;
		var flags = idata[fo];
		frags.visibilityFlags[i] = (flags & 1) ? MeshFlags.MESH_HIDE : 0;

		//Read the transform
		var to = offset + txOffset;
		_t.set(fdata[to+0] + lo.x, fdata[to+1] + lo.y, fdata[to+2] + lo.z);
		_q.x = fdata[to+3];
		_q.y = fdata[to+4];
		_q.z = fdata[to+5];
		_q.w = fdata[to+6];
		_s.set(fdata[to+7], fdata[to+8], fdata[to+9]);

		_m.compose(_t, _q, _s);

		if (svf.placementWithOffset) {
			_m.multiplyMatrices(svf.placementWithOffset, _m);
		}

		var e = _m.elements;
		var dst = frags.transforms;
		var off = i * 12;
		dst[off+0] = e[0]; dst[off+1] = e[1]; dst[off+2] = e[2];
		dst[off+3] = e[4]; dst[off+4] = e[5]; dst[off+5] = e[6];
		dst[off+6] = e[8]; dst[off+7] = e[9]; dst[off+8] = e[10];
		dst[off+9] = e[12]; dst[off+10] = e[13]; dst[off+11] = e[14];

		//Estimated bounding box based on known unit box for the mesh, and the fragment's world transform
		//TODO: do we want to store the exact world space bbox?
		_b.min.x = -0.5; _b.min.y = -0.5; _b.min.z = -0.5;
		_b.max.x =  0.5; _b.max.y =  0.5; _b.max.z =  0.5;
		_b.applyMatrix4(_m);

		dst = frags.boxes;
		off = i * 6;
		dst[off+0] = _b.min.x;
		dst[off+1] = _b.min.y;
		dst[off+2] = _b.min.z;
		dst[off+3] = _b.max.x;
		dst[off+4] = _b.max.y;
		dst[off+5] = _b.max.z;

		frags.numLoaded = i+1;

		loadContext.onLoaderEvent("fragment", i);

		return true;
	}


	this.loadAsyncProgressive(loadContext, path, ctx, "all_fragments");

	return ctx;
};

OtgPackage.prototype.handleHashListRequestFailures = function(loadContext) {

	if (!this.metadata) {
		// Model root not known yet => Retry later when model root is available
		return;
	}

	// If the model is empty, the hash list files don't exist by design.
	// For this case, we suppress the request failure error.
	if (this.geomHashListFailure && this.metadata.stats.num_geoms > 0) {
		loadContext.onFailureCallback.apply(loadContext, this.geomHashListFailure);
	}

	if (this.matHashListFailure && this.metadata.stats.num_materials > 0) {
		loadContext.onFailureCallback.apply(loadContext, this.matHashListFailure);
	}

	this.geomHashListFailure = null;
	this.matHashListFailure  = null;
};

OtgPackage.prototype.loadGeometryHashList = function(loadContext, path) {

	var svf = this;

	var ctx = new ProgressiveReadContext(readOneHash, 20);

	function readOneHash(i) {

		//have ot wait for the metadata
		//before they can be fully processed
		if (!svf.metadata)
			return false;

		if (!svf.geomMetadata.hashes) {
			svf.numGeoms = svf.metadata.stats.num_geoms;
			svf.geomMetadata.hashes = new Uint8Array(ctx.byteStride() * (svf.numGeoms + 1));
			svf.geomMetadata.byteStride = ctx.byteStride();
			svf.geomMetadata.version = ctx.version();
		}

		//i is zero based, geoms ids are 1-based
		i += 1;

		svf.geomMetadata.hashes.set(ctx.bdata(), i * ctx.byteStride());

//this is curretly set by the OtgLoader
//		svf.geomMetadata.hashToIndex[] = i;

		svf.geomMetadata.numLoaded = i;

		return true;
	}

	// Hash list request error handling is deferred until model root is known
	var onFailure = function() {
		svf.geomHashListFailure = arguments;
		svf.handleHashListRequestFailures(loadContext);
		svf.postLoad(loadContext, "geometry_ptrs", ctx); // Finish hash-list loading state
	};

	this.loadAsyncProgressive(loadContext, path, ctx, "geometry_ptrs", onFailure);

	return ctx;
};

OtgPackage.prototype.loadMaterialHashList = function(loadContext, path) {

	var svf = this;

	var ctx = new ProgressiveReadContext(readOneHash, 20);

	function readOneHash(i) {

		//have ot wait for the metadata
		//before they can be fully processed
		if (!svf.metadata)
			return false;

		if (!svf.materialHashes.hashes) {
			svf.numMaterials = svf.metadata.stats.num_materials;
			svf.materialHashes.hashes = new Uint8Array(ctx.byteStride() * (svf.numMaterials + 1));
			svf.materialHashes.byteStride = ctx.byteStride();
			svf.materialHashes.version = ctx.version();
		}

		//i is zero based, geoms ids are 1-based
		i += 1;

		svf.materialHashes.hashes.set(ctx.bdata(), i * ctx.byteStride());

		svf.materialHashes.numLoaded = i;

		return true;
	}

	// Hash list request error handling is deferred until model root is known
	var onFailure = function() {
		svf.matHashListFailure = arguments;
		svf.handleHashListRequestFailures(loadContext);
		svf.postLoad(loadContext, "material_ptrs", ctx); // Finish hash-list loading state
	};

	this.loadAsyncProgressive(loadContext, path, ctx, "material_ptrs", onFailure);

	return ctx;
};


OtgPackage.prototype.processMetadata = function(loadContext) {

	var svf = this;

	var metadata = svf.metadata;

	initPlacement(svf, loadContext);
	var pt = svf.placementWithOffset;

	if (metadata.cameras) {
		svf.cameras = metadata.cameras;

		if (pt) {
			for (var i=0; i<svf.cameras.length; i++) {
				var cam = svf.cameras[i];
				cam.position = new LmvVector3(cam.position.x, cam.position.y, cam.position.z);
				cam.position.applyMatrix4(pt);
				cam.target = new LmvVector3(cam.target.x, cam.target.y, cam.target.z);
				cam.target.applyMatrix4(pt);

				cam.up = new LmvVector3(cam.up.x, cam.up.y, cam.up.z);
				cam.up.transformDirection(pt);
			}
		}
	}

};


OtgPackage.prototype.loadRemainingSvf = function(loadContext, otgPath) {
	var svf = this;

	this.loadMetadata(loadContext, otgPath);

	//These are fundamental and always there.
	//Because the file names are fixed, we can kick off those requests together with the root json.
	//TODO: This needs to be revised in case the filenames become variable (i.e. wait
	//until we can get them from metadata.manifest.assets.
	/*
	this.loadMaterialHashList(loadContext, manifest.assets.materials_ptrs);
	this.loadGeometryHashList(loadContext, manifest.assets.geometry_ptrs);
	this.loadFragmentList(loadContext, manifest.assets.fragments);
	*/
	this.materialsCtx = this.loadMaterialHashList(loadContext, "materials_ptrs.hl");
	this.geometryCtx = this.loadGeometryHashList(loadContext, "geometry_ptrs.hl");
	this.fragmentsCtx = this.loadFragmentList(loadContext, "fragments.fl");
};


OtgPackage.prototype.makeSharedResourcePath = function(cdnUrl, whichType, hash) {

	//TODO: Make this logic preferentially use the CDN paths settings from the
	//viewable manifest instead of the .shared_assets in the per-model settings.
	//In general those will be equal though.

	if (hash.length === 10)
		hash = unpackHexString(hash);

	if (cdnUrl) {

		var shardChars = this.manifest.shared_assets.global_sharding || 0;

		//The shard prefix is a number of character cut off from the hash
		//string and brought to the beginning of the S3 key to improve S3 read
		//preformance.
		var fname = hash;
		var shardPrefix = "";
		if (shardChars) {
			shardPrefix = "/" + hash.slice(0, shardChars);
			fname = hash.slice(shardChars);
		}

		//This prefix is an account ID hash, plus a relative path
		//that is one of /t /g or /m (texture, geometry, material)
		var prefix = this.manifest.shared_assets[whichType];

		if (prefix.indexOf(FLUENT_CDN_URN) !== 0) {
			//it can be a relative path in case of local testing data
			//TODO: not sure this branch will ever get hit
			var split = prefix.split("/");
			prefix = "/" + (split[split.length - 1] || split[split.length - 2])+ "/";
		} else {
			//The CDN prefix can have -dev or -prod piece, so skip that too,
			//by slicing up to the first slash
			prefix = prefix.slice(prefix.indexOf("/"));
		}

		return cdnUrl + shardPrefix + prefix + fname;

	} else {
		//Locally stored data (testing only) defaults to sharding size of 2 chars
		var shardChars = this.manifest.shared_assets.global_sharding || 2;

		var fname = hash;
		var shardPrefix = "";
		if (shardChars) {
			shardPrefix = "/" + hash.slice(0, shardChars) + "/";
			fname = hash.slice(shardChars);
		}

		var modelBasePath = pathToURL(this.basePath);
		return modelBasePath + this.manifest.shared_assets[whichType] + shardPrefix + fname;
	}
};


OtgPackage.prototype.getFromLocalDb = function(loadContext, matHash, cb) {

	if (this.materialCache) {
		return this.materialCache.get(matHash, cb);
	}

	var mc = this.materialCache = new LocalDbCache();
	this.materialCache.open(function() {
		mc.get(matHash, cb);
    }, loadContext.disableIndexedDb);
};


OtgPackage.prototype.loadMaterial = function(loadContext, matHash, matLoadCB, matLoadError) {

	var _this = this;

	this.getFromLocalDb(loadContext, matHash, (err, data) => {
		if (data) {
			if (data instanceof Uint8Array)
				data = blobToJson(data);
			else if (typeof data === "string")
				data = JSON.parse(data);
			
			matLoadCB(data);
		} else {
			// get request url
			var url;

			url = this.makeSharedResourcePath(loadContext.otg_cdn, "materials", matHash);

			// handle load failure
			var onFailure = function(error) {
				logger.error("Failed to load material " + matHash);
				logger.error(JSON.stringify(error));

				matLoadError && matLoadError(matHash, error);
			};

			if (url.indexOf("https://") !== 0 && url.indexOf("http://") !== 0 && url.indexOf("file://") !== 0)
				url = loadContext.basePath + url;

			var useCredentials = loadContext.otg_cdn_with_credentials;

			ViewingService.getItem(loadContext.otg_cdn && !useCredentials ? {} : loadContext,
				url,
				function(data) {
					_this.materialCache.store(matHash, data);

					data = blobToJson(data);

					matLoadCB(data);
				},
				onFailure,
				{
					responseType:"arraybuffer", //get it as ArrayBuffer so that it can be stored compressed in the indexedDb
					withCredentials: useCredentials,
				}
			);
		}
	});

};


OtgPackage.prototype.postLoad = function(loadContext, what, ctx, data) {

	if (what) {
		//console.log("what", what);
		this.initialLoadProgress++;
	}

	//If required files are loaded, continue with the next
	//step of the load sequence
	if (this.initialLoadProgress === 4) {

		//Finish processing the data streams in order of dependcy

		this.materialsCtx.flush();
		this.geometryCtx.flush();
		this.fragmentsCtx.flush();

		this.materialsCtx = null;
		this.geometryCtx = null;
		this.fragmentsCtx = null;

		if (this.fragments.numLoaded < this.metadata.stats.num_fragments)
			logger.warn("Fragments actually loaded fewer than expected.");

		loadContext.onLoaderEvent("all_fragments");
	} else {
		//In rare cases (especially node.js use), one of the content
		//files can appear before the metadata json is loaded.
		//so we have to do a flush when the metadata arrives in order
		//to get the processing unstuck (since readOne bails if there is no metadata).
		if (what === "metadata") {
			this.materialsCtx.flush();
			this.geometryCtx.flush();
			this.fragmentsCtx.flush();
		}
    }
};


OtgPackage.prototype.abort = function() {
	this.aborted = true;
};


OtgPackage.prototype.makeBVH = function(loadContext) {

	var svf = this;

	//TODO: we may want to do this in a worker, currently OTG does it on the main thread
	//TODO: process bboxes progressively instead of doing it once the whole file is in.
	if (this.manifest.assets.fragments_extra) {
		this.loadAsyncResource(loadContext, this.manifest.assets.fragments_extra, "", function(data) {

			if (!data || !data.length) {
				return;
			}

			//Build the R-Tree
			var t0 = performance.now();

			var finfo = new OtgFragInfo(data, svf.placementWithOffset);

			if (finfo.count) {
				var tmpbvh = new BVHBuilder(null, null, finfo);
				tmpbvh.build(loadContext.bvhOptions);

				svf.bvh = {
					nodes: tmpbvh.nodes,
					primitives: tmpbvh.primitives
				};

				var t1 = performance.now();
				logger.debug("BVH build time:" + (t1 - t0));

				// In normal mode, just post back BVH as svf is already posted back earlier.
				loadContext.onLoaderEvent("bvh", svf.bvh);
			}

		});
	}
};

