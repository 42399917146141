export let MeshFlags = {
        // FragmentList flags
        //visibility/highlight bitmask flags
        //NOTE: This is confusing and it should be fixed, but when the MESH_VISIBLE bit is off, the mesh
        //will draw in ghosted mode. To completely skip drawing a mesh, set the HIDE flag.
        MESH_VISIBLE:      1,
        MESH_HIGHLIGHTED:  2,
        MESH_HIDE:         4,
        MESH_ISLINE:       8,
        MESH_MOVED:        16,   // indicates if an animation matrix is set
        MESH_TRAVERSED:    0x20, // only used for paging: drawn fragments are tagged and then skipped by forEach() until the flag is being reset (e.g. on scene/camera changes)
        MESH_DRAWN:        0x40, // only used for paging: drawn fragments are tagged. At the end of all render passes flag is copied to MESH_TRAVERSED.
        MESH_RENDERFLAG:   0x80,
        MESH_ISPOINT:      0x100, // indicates that the mesh is vertex-only
        MESH_ISWIDELINE:   0x200 // indicates that the mesh is wide line
    };

