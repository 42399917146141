
import { Extension } from "../../src/application/Extension";


    /**
     * Use its `activate()` method to open the Model Browser UI.
     * The Model Browser is only available to 3D models.
     * 
     * The extension id is: `Autodesk.ModelStructure`
     * 
     * @example
     *   viewer.loadExtension('Autodesk.ModelStructure')
     * 
     * @memberof Autodesk.Viewing.Extensions
     * @alias Autodesk.Viewing.Extensions.ModelStructureExtension
     * @extends {Autodesk.Viewing.Extension}
     * @constructor
     */
    export function ModelStructureExtension(viewer, options) {
        Extension.call(this, viewer, options);
        this.viewer = viewer;
        this.options = options;
        this.name = "modelstructure";
    }
    ModelStructureExtension.prototype = Object.create(Extension.prototype);
    ModelStructureExtension.prototype.constructor = ModelStructureExtension;

    var proto = ModelStructureExtension.prototype;

    /**
     * Opens the Model Browser UI.
     * 
     * @memberof Autodesk.Viewing.Extensions.ModelStructureExtension
     * @alias Autodesk.Viewing.Extensions.ModelStructureExtension#activate
     */
    proto.activate = function() {
        if(!this.activeStatus) {
            this.viewer.showModelStructurePanel(true);
            this.activeStatus = true;
        }
        return true;
    };

    /**
     * Closes the Model Browser UI.
     * 
     * @memberof Autodesk.Viewing.Extensions.ModelStructureExtension
     * @alias Autodesk.Viewing.Extensions.ModelStructureExtension#deactivate
     */
    proto.deactivate = function() {
        if(this.activeStatus) {
            this.viewer.showModelStructurePanel(false);
            this.activeStatus = false;
        }
        return true;
    };
