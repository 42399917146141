import { logger } from "../../logger/Logger";
import * as THREE from "three";
import { createWorker } from "./WorkerCreator";


//web worker used for image processing, etc.
let imageWorker = null;
let messageId = 1;

//TODO_TS: getTransferables is copied in both the main and worker sides. Move it to a shared location where both can include it.
function getTransferables(map) {

    var res = [];

    // if `map.image` is an array, use it as it is, otherwise create an array with single item (`map.image`) in it
    var images = Array.isArray(map.image) ? map.image : [map.image];

    for (var i=0; i<images.length; i++) {

        var image = images[i];

        for (var j=0; j<image.mipmaps.length; j++) {

            var mipmap = image.mipmaps[j];

            res.push(mipmap.data.buffer);
        }
    }

    return res;
}


export const DecodeEnvMapAsync = function(map, exposure, useHalfFloat, callback) {

    if (!map.LogLuv) {
        logger.warn("Environment map expected to be in LogLuv format.");
        return;
    }

    if (!imageWorker)
        imageWorker = createWorker();

    var id = messageId++;

    var onMessage = function(msg) {

        if (msg.data.id !== id)
            return;

        imageWorker.removeEventListener("message", onMessage);

        var mapWorker = msg.data.map;
        map.image = mapWorker.image;

        map.LogLuv = false;

        if (useHalfFloat) {
            map.type = THREE.HalfFloatType;
            map.format = THREE.RGBFormat;
            map.RGBM = false;
            map.GammaEncoded = true;
        }
        else
            map.RGBM = true;

        callback(map);
    };

    imageWorker.addEventListener("message", onMessage);

    imageWorker.doOperation({
        operation: "DECODE_ENVMAP",
        map: map,
        exposure: exposure,
        useHalfFloat: useHalfFloat,
        id: id
    }, getTransferables(map));
};
