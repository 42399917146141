//Trivial clear pass - used to clear with stencil test

import { Vector4 } from "three";
import screen_quad_uv_vert from './shaders/screen_quad_uv_vert.glsl';
import clear_frag from './shaders/clear_frag.glsl';

export let ClearShader = {

    uniforms: {
        "color": { type: "v4", value: new Vector4(0, 0, 0, 0) }
    },

    vertexShader: screen_quad_uv_vert,
    fragmentShader: clear_frag

};
