import { logger } from '../../logger/Logger';
import { ViewingService, pathToURL } from '../net/Xhr';
import { ErrorCodes } from '../net/ErrorCodes';
import * as et from '../../application/EventTypes';
import {initLoadContext} from "../net/endpoints";
import { InstanceTree } from '../../wgs/scene/InstanceTree';
import { InstanceTreeAccess } from '../../wgs/scene/InstanceTreeStorage';
import { createWorkerWithIntercept } from './WorkerCreator';
import { EventDispatcher } from "../../application/EventDispatcher";


    var WORKER_GET_PROPERTIES = "GET_PROPERTIES";
    var WORKER_SEARCH_PROPERTIES = "SEARCH_PROPERTIES";
    var WORKER_FIND_PROPERTY = "FIND_PROPERTY";
    var WORKER_FIND_LAYERS = "FIND_LAYERS";
    var WORKER_BUILD_EXTERNAL_ID_MAPPING = "BUILD_EXTERNAL_ID_MAPPING";
    var WORKER_BUILD_LAYER_TO_NODE_ID_MAPPING = "BUILD_LAYER_TO_NODE_ID_MAPPING";
    var WORKER_LOAD_PROPERTYDB = "LOAD_PROPERTYDB";
    var WORKER_UNLOAD_PROPERTYDB = "UNLOAD_PROPERTYDB";
    var WORKER_ATTRIBUTES_MAP = "ATTRIBUTES_MAP";
    var WORKER_DIFF_PROPERTIES = "DIFF_PROPERTIES";


    //Use a global property worker thread, which does caching of
    //shared property databases (and database files).
    var propWorker;

    //Keep track of all pending operations/callbacks going into the property worker
    var PROPDB_CB_COUNTER = 1;
    var PROPDB_CALLBACKS = {};

    function propertyWorkerCallback(e) {

        var data = e.data;

        if (data && data.debug) {
            logger.debug(data.message);
            return;
        }

        if (data.cbId) {
            var cbs = PROPDB_CALLBACKS[data.cbId];

            if (data && data.error) {
                if (cbs[1])
                    cbs[1](data.error);
            } else {
                if (cbs[0])
                    cbs[0](data.result);
            }

            delete PROPDB_CALLBACKS[data.cbId];
        }

    }

    function registerWorkerCallback(onSuccess, onError) {
        var cbId = PROPDB_CB_COUNTER++;

        PROPDB_CALLBACKS[cbId] = [onSuccess, onError];

        return cbId;
    }

    //Used by node.js code to get direct access to the worker (which runs on the same thread in node.js)
    export function getPropWorker() {
        return propWorker;
    }

    export function clearPropertyWorkerCache() {
        if (!propWorker)
            return;

        propWorker.doOperation({
                "operation": WORKER_UNLOAD_PROPERTYDB,
                "clearCaches": true
        });
    }


    //Per model property database interface, talks to the worker thread behind the scenes
    export var PropDbLoader = function(sharedDbPath, model, eventTarget) {

        this.eventTarget = eventTarget || new EventDispatcher();

        this.model = model;
        this.svf = model && model.getData();

        //Will be initialized by the complex logic below.
        this.dbPath = "";
        this.sharedDbPath = false;

        //If there is a shared db path and there is no
        //per-SVF specific property database, use the shared one
        //NOTE: The check for .is2d is significant here: In cases where there
        //is an OTG v2 property database, we want to use that. Because OTG does not touch F2D files
        //those might still include the v1 property database in their manifest when we really want to use
        //the v2 OTG property db.
        var is2dAndOtg = this.svf && this.svf.is2d && this.svf.loadOptions.bubbleNode && this.svf.loadOptions.bubbleNode.findViewableParent()._getOtgManifest();
        if (this.svf && !is2dAndOtg && this.svf.propertydb && this.svf.propertydb.avs.length) {

            //If the SVF specified its own property db files, assume they are not shared
            this.dbFiles = this.svf.propertydb;

            for (var f in this.dbFiles) {
                if (this.dbFiles[f][0]) {
                    //Revit outputs backslashes in the
                    //relative path in the SVF manifest. WTF?
                    this.dbFiles[f][0] = this.dbFiles[f][0].replace(/\\/g, "/");
                }
            }

            //Now check if the SVF propertydb definition actually refers to the same property database
            //as the shared database path. This is made harder by various "../../.." relative things
            //in the svf property db files list, hence the nasty path normalization stuff.
            var svfPath = pathToURL(this.svf.basePath);
            if (sharedDbPath) {

                var avsPath = ViewingService.simplifyPath(svfPath + this.svf.propertydb.avs[0]);
                avsPath = avsPath.slice(0, avsPath.lastIndexOf("/")+1);

                //Does the property db path specified in the SVF match the
                //one specified as shared db path in the manifest?
                if (avsPath === sharedDbPath) {

                    //Convert the property db file list to be relative
                    //to the shared property db location, instead of
                    //relative to the SVF location

                    var dbFilesNew = {};
                    for (var f in this.dbFiles) {
                        var fpath = this.dbFiles[f][0];
                        fpath = ViewingService.simplifyPath(svfPath + fpath);

                        if (fpath.indexOf(sharedDbPath) === 0)
                            fpath = fpath.slice(sharedDbPath.length);

                        dbFilesNew[f] = [fpath];
                    }

                    //Replace the loader parameters by the recomputed ones
                    this.dbFiles = dbFilesNew;

                    //Use the less specific out of the SVF and shared bubble
                    //paths, and convert all file paths to be relative from that.
                    this.dbPath = sharedDbPath;
                    this.sharedDbPath = true;

                } else {
                    this.dbPath = svfPath;
                    this.sharedDbPath = false;
                }
            } else {
                this.dbPath = svfPath;
                this.sharedDbPath = false;
            }
        } else {
            this.sharedDbPath = true;

            if (this.svf && this.svf.loadOptions.bubbleNode) {
                //NOTE: sharedDbPath is only used here as a cache key in the property worker.
                //Paths returned by the new getPropertyDbManifest API are fully qualified (starting with "urn:"
                this.dbPath = sharedDbPath;
                this.dbFiles = this.svf.loadOptions.bubbleNode.getPropertyDbManifest();
            } else {

                //This fallback lets the worker initialize the file list with defaults
                //to preserve behavior if bubbleNode is not given in the model.
                //This code path should be completely removed eventually.
                logger.warn("Deprecated shared property database initialization without bubbleNode in Model load options.");
                this.dbPath = sharedDbPath;
                this.dbFiles = { attrs : [], avs: [], ids: [], values: [], offsets: [] };
            }
            logger.log("Using shared db path " + sharedDbPath);
        }

        this.queryParams = "";
        if (this.svf && this.svf.acmSessionId) {
            this.queryParams = "acmsession=" + this.svf.acmSessionId;
        }
    };

    PropDbLoader.prototype.dtor = function() {
        this.asyncPropertyOperation(
            {
                "operation": WORKER_UNLOAD_PROPERTYDB
            },
            function(){}, function(){}
        );
        this.model = null;
        this.svf = null;
    };


    PropDbLoader.prototype.processLoadResult = function(result) {
        var scope = this;

        if (result.instanceTreeStorage) {

            var nodeAccess = new InstanceTreeAccess(result.instanceTreeStorage, result.rootId, result.instanceBoxes);

            scope.instanceTree = new InstanceTree(nodeAccess, result.objectCount, result.maxTreeDepth);
            
            if (scope.svf) {
                //For backwards compatibility, svf.instanceTree has to be set also
                scope.svf.instanceTree = scope.instanceTree;

                // If nodeBoxes are not precomputed, we set the fragBoxes, so that instanceTree can compute nodeBoxes on-the-fly
                scope.instanceTree.setFragmentList(scope.model.getFragmentList());
            }
        } else if (result.instanceTree) {
            //Case of fake glTF instance tree
            //TODO: the glTF instance tree would have to be converted or warpped inside an InstanceTree
            //in order to be usable by the UI.
            logger.warn("glTF instance tree not supported");

            scope.hasObjectProperties = result.objectCount;
        }
        else if (result.objectCount) {

            //Case where there is no object tree, but objects
            //do still have properties. This is the case for F2D drawings.
            scope.hasObjectProperties = result.objectCount;

            if (scope.svf) {
                scope.svf.hasObjectProperties = result.objectCount;
            }
        }

        if (result.dbidOldToNew) {
            this.model.setDbIdRemap(result.dbidOldToNew);
        }

        scope.eventTarget.dispatchEvent({
            type: et.OBJECT_TREE_CREATED_EVENT,
            svf:scope.svf,
            model:scope.model,
            target: scope
        });

    };

    PropDbLoader.prototype.processLoadError = function(error) {

        var scope = this;

        scope.propertyDbError = error;

        scope.eventTarget.dispatchEvent({
            type: et.OBJECT_TREE_UNAVAILABLE_EVENT,
            svf:scope.svf,
            model:scope.model,
            target: scope
        });
    };


    PropDbLoader.prototype.load = function() {
        var scope = this;

        //In the case of glTF, the instance tree is immediately available, loaded
        //together with the geometry payload ("the svf")
        if (this.svf && this.svf.instanceTree && this.svf.instanceBoxes) {
            //Need this call to be async, because some state required
            //by object tree load event handlers is not yet initialized
            //when the PropDbLoader.load() is called (in particular, viewer.model is not assigned at that point)
            setTimeout(function() { scope.processLoadResult(scope.svf); },0);
            return;
        }

        //Create the shared property worker if not already done
        if (!propWorker) {
            propWorker = createWorkerWithIntercept();
            propWorker.addEventListenerWithIntercept(propertyWorkerCallback);
        }

        var onObjectTreeRead = function(result) {
            scope.processLoadResult(result);
        };

        var onObjectTreeError = function(error) {
            scope.processLoadError(error);
        };

        var cbId = registerWorkerCallback(onObjectTreeRead, onObjectTreeError);

        // Precomputed bboxes are only needed when using the model explode feature. If this is not used, we can save some memory and compute boxes on-the-fly instead.
        var loadOptions = this.svf && this.svf.loadOptions;
        var precomputeNodeBoxes = !(loadOptions && loadOptions.disablePrecomputedNodeBoxes);

        var xfer = { "operation":WORKER_LOAD_PROPERTYDB,
                     "dbPath": this.dbPath,
                     "sharedDbPath": this.sharedDbPath,
                     "propertydb" : this.dbFiles,
                     "fragToDbId": this.svf && this.svf.fragments.fragId2dbId, //the 1:1 mapping of fragment to dbId we got from the SVF or the 1:many we built on the fly for f2d
                     "fragBoxes" : precomputeNodeBoxes && this.svf && this.svf.fragments.boxes, //needed to precompute bounding box hierarchy for explode function (and possibly others)
                     needsDbIdRemap: this.svf && this.svf.is2d,
                     cbId: cbId,
                     queryParams : this.queryParams
                    };

        propWorker.doOperation(initLoadContext(xfer)); // Send data to our worker.

    };


    PropDbLoader.prototype.asyncPropertyOperation = function(opArgs, success, fail) {

        var scope = this;

        //Identify which property database we want to work on (the worker can hold multiple property databases)
        opArgs.dbPath = this.dbPath;

        if (scope.instanceTree || scope.hasObjectProperties) {

            opArgs.cbId = registerWorkerCallback(success, fail);

            propWorker.doOperation(opArgs); // Send data to our worker.
        } else if (scope.propertyDbError) {
            if (fail)
                fail(scope.propertyDbError);
        } else {
            var onEvent = function(e) {
                scope.eventTarget.removeEventListener(et.OBJECT_TREE_CREATED_EVENT, onEvent);
                scope.eventTarget.removeEventListener(et.OBJECT_TREE_UNAVAILABLE_EVENT, onEvent);
                if (scope.instanceTree || scope.hasObjectProperties || scope.propertyDbError)
                    scope.asyncPropertyOperation(opArgs, success, fail);
                else if (fail)
                    fail({code:ErrorCodes.UNKNOWN_FAILURE, msg:"Failed to load properties"}); //avoid infinite recursion.
            };
            scope.eventTarget.addEventListener(et.OBJECT_TREE_CREATED_EVENT, onEvent);
            scope.eventTarget.addEventListener(et.OBJECT_TREE_UNAVAILABLE_EVENT, onEvent);
        }
    };


    PropDbLoader.prototype.getProperties = function(dbId, onSuccess, onError) {

        this.asyncPropertyOperation(
            {
                "operation":WORKER_GET_PROPERTIES,
                "dbId": dbId
            },
            onSuccess, onError
        );
    };

    /**
     * Bulk property retrieval with property name filter.
     * dbIds -- array of object dbIds to return properties for.
     * propFilter -- array of property names to retrieve values for. If empty, all properties are returned.
     * ignoreHidden -- ignore hidden properties
     */
    PropDbLoader.prototype.getBulkProperties = function(dbIds, propFilter, onSuccess, onError, ignoreHidden) {

        this.asyncPropertyOperation(
            {
                "operation":WORKER_GET_PROPERTIES,
                "dbIds": dbIds,
                "propFilter": propFilter,
                "ignoreHidden": ignoreHidden
            },
            onSuccess, onError
        );
    };


    PropDbLoader.prototype.searchProperties = function(searchText, attributeNames, onSuccess, onError) {

        this.asyncPropertyOperation(
            {
                "operation": WORKER_SEARCH_PROPERTIES,
                "searchText": searchText,
                "attributeNames" : attributeNames
            },
            onSuccess, onError
        );
    };

    PropDbLoader.prototype.findProperty = function(propertyName) {
        
        var that = this;
        return new Promise(function(resolve, reject){
            that.asyncPropertyOperation(
                {
                    "operation": WORKER_FIND_PROPERTY,
                    "propertyName": propertyName
                },
                resolve, reject
            );
        });
    };

    /*
     * Compares this db with another one. The result object passed to onSuccess
     * is an object that contains...
     * {
     *    // An array of all dbIds that...
     *    // a) exist in both databases
     *    // b) have different properties.
     *    changedIds: dbId[]
     *
     *    // Optional: details about what changed exactly.
     *    // If k props changed for a dbId result.changeIds[i], result.propChanges[i]
     *    // is an array of length k. Each element in it describes the change of a single
     *    // property.
     *    propChanges: Object[][]
     * }
     *
     *  @param {PropDbLoader} dbToDiff
     *  @param {function(number[])} onSuccess     - receives the array of dbIds
     *  @param {Object}             [DiffOptions] - Optional diff options object.
     *
     * DiffOptions:
     *   @param {number[]} diffOps.dbIds             - Restrict diff to fixed set of dbIds (otherwise all ids)
     *   @param {bool}     diffOps.listPropChanges   - If true, result.propChanges is provided (slower)
     */
    PropDbLoader.prototype.diffProperties = function(dbToDiff, onSuccess, onError, diffOptions) {
        this.asyncPropertyOperation(
            {
                "operation": WORKER_DIFF_PROPERTIES,
                "dbPath2":   dbToDiff, // only pass the second dbPath here. this.dbPath is automatically set (see asyncPropertyOperation)
                "diffOptions": diffOptions
            },
            onSuccess, onError
        );
    };

    PropDbLoader.prototype.findLayers = function() {
        
        var that = this;
        return new Promise(function(resolve, reject){
            that.asyncPropertyOperation(
                {
                    "operation": WORKER_FIND_LAYERS
                },
                resolve, reject
            );
        });
    };

    // @param {Object} [extIdFilter] - optional: restricts result to all extIds for which extIdFilter[extId] is true.
    PropDbLoader.prototype.getExternalIdMapping = function(onSuccess, onError, extIdFilter) {

        this.asyncPropertyOperation(
            {
                "operation": WORKER_BUILD_EXTERNAL_ID_MAPPING,
                "extIdFilter": extIdFilter
            },
            onSuccess, onError
        );
    };

    PropDbLoader.prototype.getLayerToNodeIdMapping = function(onSuccess, onError) {

        this.asyncPropertyOperation(
            {
                "operation": WORKER_BUILD_LAYER_TO_NODE_ID_MAPPING
            },
            onSuccess, onError
        );
    };

    PropDbLoader.prototype.isObjectTreeLoaded = function() {
        return !!this.instanceTree;
    };


    PropDbLoader.prototype.getObjectTree = function(onSuccess, onError) {
        var scope = this;

        if (scope.instanceTree) {
            onSuccess(scope.instanceTree);
        } else if (scope.propertyDbError) {
            if (onError)
                onError(scope.propertyDbError);
        } else if ('hasObjectProperties' in scope) {
            if (onError)
                onError('F2D files do not have an InstanceTree.');
        } else {
            // Property Db has been requested; waiting for worker to complete //
            var listener = function() {
                scope.eventTarget.removeEventListener(et.OBJECT_TREE_CREATED_EVENT, listener);
                scope.eventTarget.removeEventListener(et.OBJECT_TREE_UNAVAILABLE_EVENT, listener);
                scope.getObjectTree(onSuccess, onError);
            };
            scope.eventTarget.addEventListener(et.OBJECT_TREE_CREATED_EVENT, listener);
            scope.eventTarget.addEventListener(et.OBJECT_TREE_UNAVAILABLE_EVENT, listener);
        }
    };
