// Add stencil buffer to THREE WebGLState. I followed the way this is done in r86 to
// to make upgrading easier.

import { WebGLState as ThreeState } from "three";

export class WebGLState extends ThreeState {

    constructor(gl, paramThreeToGL) {

        var capabilities = {};

        function enable( id ) {
            
            if ( capabilities[ id ] !== true ) {
    
                gl.enable( id );
                capabilities[ id ] = true;
    
            }
    
        }
    
        function disable( id ) {
    
            if ( capabilities[ id ] !== false ) {
    
                gl.disable( id );
                capabilities[ id ] = false;
    
            }
        }
            
        function StencilBuffer() {
            
            var locked = false;
        
            var currentStencilMask = null;
            var currentStencilFunc = null;
            var currentStencilRef = null;
            var currentStencilFuncMask = null;
            var currentStencilFail = null;
            var currentStencilZFail = null;
            var currentStencilZPass = null;
            var currentStencilClear = null;
        
            return {
        
                setTest: function ( stencilTest ) {
        
                    if ( stencilTest ) {
        
                        enable( gl.STENCIL_TEST );
        
                    } else {
        
                        disable( gl.STENCIL_TEST );
        
                    }
        
                },
        
                setMask: function ( stencilMask ) {
        
                    if ( currentStencilMask !== stencilMask && ! locked ) {
        
                        gl.stencilMask( stencilMask );
                        currentStencilMask = stencilMask;
        
                    }
        
                },
        
                setFunc: function ( stencilFunc, stencilRef, stencilMask ) {
        
                    if ( currentStencilFunc !== stencilFunc ||
                            currentStencilRef 	!== stencilRef 	||
                            currentStencilFuncMask !== stencilMask ) {
        
                        gl.stencilFunc( stencilFunc, stencilRef, stencilMask );
        
                        currentStencilFunc = stencilFunc;
                        currentStencilRef = stencilRef;
                        currentStencilFuncMask = stencilMask;
        
                    }
        
                },
        
                setOp: function ( stencilFail, stencilZFail, stencilZPass ) {
        
                    if ( currentStencilFail	 !== stencilFail 	||
                            currentStencilZFail !== stencilZFail ||
                            currentStencilZPass !== stencilZPass ) {
        
                        gl.stencilOp( stencilFail, stencilZFail, stencilZPass );
        
                        currentStencilFail = stencilFail;
                        currentStencilZFail = stencilZFail;
                        currentStencilZPass = stencilZPass;
        
                    }
        
                },
        
                setLocked: function ( lock ) {
        
                    locked = lock;
        
                },
        
                setClear: function ( stencil ) {
        
                    if ( currentStencilClear !== stencil ) {
        
                        gl.clearStencil( stencil );
                        currentStencilClear = stencil;
        
                    }
        
                },
        
                reset: function () {
        
                    locked = false;
        
                    currentStencilMask = null;
                    currentStencilFunc = null;
                    currentStencilRef = null;
                    currentStencilFuncMask = null;
                    currentStencilFail = null;
                    currentStencilZFail = null;
                    currentStencilZPass = null;
                    currentStencilClear = null;
        
                }
        
            };
        
        }

        // Call the super class
        super(gl, paramThreeToGL);
        this.buffers = {
            stencil: StencilBuffer()
        };

        // THREE.WebGLState doesn't use prototypes, so it wasn't clear to me
        // that typescript method overriding would work for this case. So
        // I manually override reset here.
        var superReset = this.reset.bind(this);
        this.reset = function() {
            this.buffers.stencil.reset();
            superReset();
        }
    }
}
