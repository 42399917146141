
import { i18n } from "../globalization/i18next";


/** @constructor */
export function HudMessage() {
}

HudMessage.instances = [];

// static
HudMessage.displayMessage = function(container, messageSpecs, closeCB, buttonCB, checkboxCB ) {

    function getClientHeight(element) {
        var style = window.getComputedStyle(element); 

        var margin = {
            top: parseInt(style["margin-top"]),
            bottom: parseInt(style["margin-bottom"])
        };

        var rect = element.getBoundingClientRect();
        return rect.height + margin.top + margin.bottom;
    };

    // If hud message is already up, return.
    if (HudMessage.instances.length > 0)
        return ;

    var msgTitle = messageSpecs.msgTitleKey;
    var msgTitleDefault = messageSpecs.msgTitleDefaultValue || msgTitle;
    var message    = messageSpecs.messageKey;
    var messageDefault = messageSpecs.messageDefaultValue || message ;
    var buttonText = messageSpecs.buttonText;
    var checkboxChecked = messageSpecs.checkboxChecked || false;

    var hudMessage = document.createElement("div");
    hudMessage.classList.add("docking-panel");
    hudMessage.classList.add("hud");

    container.appendChild( hudMessage );

    var title = document.createElement("div");
    title.classList.add("docking-panel-title");
    title.classList.add("docking-panel-delimiter-shadow");
    title.textContent = i18n.translate(msgTitle, {"defaultValue" : msgTitleDefault});
    title.setAttribute("data-i18n", msgTitle);
    hudMessage.appendChild(title);

    if (closeCB) {
        var closeButton = document.createElement("div");
        closeButton.classList.add("docking-panel-close");
        closeButton.addEventListener('click', function(e) {
            HudMessage.dismiss();
            if (closeCB)
                closeCB(e);
        });
        hudMessage.appendChild(closeButton);
    }

    var client = document.createElement("div");
    client.classList.add("hud-client");
    client.classList.add("docking-panel-container-solid-color-b");

    hudMessage.appendChild(client);

    var text = document.createElement("div");
    text.className = "hud-message";
    text.textContent = i18n.translate(message, { "defaultValue" : messageDefault});
    text.setAttribute("data-i18n", messageDefault);
    client.appendChild(text);

    var clientHeight = getClientHeight(text);
    if (buttonCB) {
        var button = document.createElement("div");
        button.classList.add("docking-panel-primary-button");
        button.classList.add("hud-button");
        button.textContent = i18n.translate( buttonText, { "defaultValue" : buttonText } );
        button.setAttribute( "data-i18n", buttonText );
        button.addEventListener("click", buttonCB);
        client.appendChild(button);
        clientHeight += getClientHeight(button);
    }

    if (checkboxCB) {
        var checkbox = document.createElement("div");
        var cb = document.createElement("input");
        cb.className = "hud-checkbox";
        cb.type = "checkbox";
        cb.checked = checkboxChecked;
        checkbox.appendChild(cb);

        var checkboxText = "Do not show this message again";    // localized below

        var lbl = document.createElement("label");
        lbl.setAttribute('for', checkboxText);
        lbl.setAttribute("data-i18n", checkboxText);
        lbl.textContent = i18n.translate( checkboxText, { "defaultValue" : checkboxText } );
        checkbox.appendChild(lbl);
        cb.addEventListener( "change", checkboxCB );
        client.appendChild(checkbox);
        clientHeight += getClientHeight(checkboxCB);
    }

    client.style.height =  clientHeight + 'px';
    hudMessage.style.height = (clientHeight + getClientHeight(title)) + 'px';

    var instance = { hudMessage: hudMessage, container: container };
    HudMessage.instances.push(instance);
};

HudMessage.dismiss = function() {
    // dismiss the topmost alert box
    if (HudMessage.instances.length > 0) {
        var instance = HudMessage.instances.pop();
        instance.hudMessage.style.visibility = "hidden";
        instance.container.removeChild(instance.hudMessage);
        return true;
    }
    return false;
};
