
import { SettingsPanel } from "./SettingsPanel";
import * as et from "../application/EventTypes";
import { logger } from "../logger/Logger";
import { OptionLabel } from "./CommonWidgets";
import { LightPresets } from "../application/LightPresets";
import { i18n } from "../globalization/i18next";
import { isMobileDevice, touchStartToClick } from "../compat";
import { ViewerPanelMixin } from "./ViewerPanelMixin";


/**
 * Viewer3dSettings Tabs.
 *
 * These constants are used to define the tabs in the ViewerSettingsPanel.
 *
 * @enum {number}
 * @readonly
 */
export let ViewerSettingTab = {
    Navigation : "navigationtab",
    Performance: "performancetab",
    Appearance: "appearance",
    Environment: "environment"
};

var viewerSettingsPanelInstanceCnt = 0;

/**
 * ViewerSettingsPanel
 * This is a panel for displaying the settings for the viewer.
 * @class
 *
 * @param {Autodesk.Viewing.Viewer3D} viewer - the parent viewer
 * @param {string} model - whether it is 3d or 2d mode (acceptable strings: "2d", "3d")
 * @constructor
 */
export function ViewerSettingsPanel(viewer, model) {

    this.viewer = viewer;
    this.is3dMode = !model.is2d();
    this.visible =  false;

    SettingsPanel.call(this, viewer.container, 'ViewerSettingsPanel' + viewer.id + '-' + viewerSettingsPanelInstanceCnt++, 'Settings',
        { width: 400, addFooter: false, heightAdjustment: 90 } );
    this.container.classList.add('viewer-settings-panel');

    this.addTab( ViewerSettingTab.Performance, "Performance", { className: "performance" } );
    this.addTab( ViewerSettingTab.Navigation, "Navigation", { className: "navigation" } );
    this.addTab( ViewerSettingTab.Appearance, "Appearance", { className: "appearance"} );
    if(this.is3dMode) {
        this.addTab( ViewerSettingTab.Environment, "Environment", { className: "environment"} );
    }
    if(!this.is3dMode) {
        this.container.classList.add('for-2d-model');
    }

    this.createRestoreDefaultSettingsButton();

    this.modelPreferenceCount = 0;
    this.createNavigationPanel();
    this.createPerformancePanel();
    this.createAppearancePanel();
    this.createEnvironmentPanel();
    
    if (this.modelPreferenceCount) {
        logger.log('Model locked (' + this.modelPreferenceCount + ') render settings in UI.');
    }
    // Setting Performance as the default tab
    this.selectTab(ViewerSettingTab.Performance);

    // Add events
    this.syncUI = this.syncUI.bind(this);
    viewer.addEventListener(et.RESTORE_DEFAULT_SETTINGS_EVENT, this.syncUI);
    viewer.addEventListener(et.VIEWER_STATE_RESTORED_EVENT, this.syncUI);
};

ViewerSettingsPanel.prototype = Object.create(SettingsPanel.prototype);
ViewerSettingsPanel.prototype.constructor = ViewerSettingsPanel;
ViewerPanelMixin.call( ViewerSettingsPanel.prototype );

/**
 * Clean up when the viewer setting  is about to be removed.
 * @override
 */
ViewerSettingsPanel.prototype.uninitialize = function () {
    if (this.viewer) {
        this.viewer.removeEventListener(et.RESTORE_DEFAULT_SETTINGS_EVENT, this.syncUI);
        this.viewer.removeEventListener(et.VIEWER_STATE_RESTORED_EVENT, this.syncUI);
    }
    this.viewer = null;
    SettingsPanel.prototype.uninitialize.call(this);
    this.envSelect = null;
};


ViewerSettingsPanel.prototype.setVisible = function(show) {
    this.visible = show;
    SettingsPanel.prototype.setVisible.call(this, show);
    show && this.sizeToContent();
};
/**Yu
 * Creates a checkbox element and adds it to the given tab.
 *
 * @param {number} tabId - tab id
 * @param {string} description - the text associated with the checkbox
 * @param {boolean} initialState - initial value for the checkbox (checked or not)
 * @param {function} onchange - callback that is called when the checkbox is changed
 * @param {string} saveKey - name of the preference associated with this checkbox.
 * @returns {HTMLElement} - it returns the checkbox element.
 *
 */
ViewerSettingsPanel.prototype.addCheckbox = function(tabId, name, description, initialState, onchange, saveKey)
{
    var viewer = this.viewer;

    // Use the stored settings or defaults
    var storedState = viewer.prefs[saveKey];
    initialState = (typeof storedState === 'boolean') ? storedState : initialState;

    function onChangeCB(checked) {
        if(saveKey){
            viewer.prefs.set(saveKey, checked);
        }
        onchange(checked);
    }

    var checkboxId = SettingsPanel.prototype.addCheckbox.call(this, tabId, name, initialState, onChangeCB, description);
    var checkBoxElem = this.getControl(checkboxId);
    checkBoxElem.saveKey = saveKey;

    if(saveKey) {
        viewer.prefs.addListeners(saveKey, function (value) {
            checkBoxElem.setValue(value);
        }, function (value) {
            checkBoxElem.setValue(value);
            onchange(value);
        });
    }
    else {
        checkBoxElem.sliderRow.classList.add('logical-group');
    }

    if (viewer.prefs.hasTag(saveKey, 'no-storage')){
        checkBoxElem.sliderRow.classList.add('no-storage');
        this.modelPreferenceCount++;
    }
    return checkboxId;
};

ViewerSettingsPanel.prototype.addGrid = function(caption, parentTable, items, initialItemIndex, onClick, saveKey) {

    var table = parentTable;

    var envContainer = document.createElement("div");
    envContainer.classList.add("environments-container");
    table.appendChild(envContainer);

    var envRow = document.createElement("div");
    envRow.classList.add("environments-lighting-table");
    envContainer.appendChild(envRow);

    for (var i = 0; i < items.length; i++) {

        var cell = document.createElement("div");
        cell.classList.add("settings-environment-cell");
        cell.index = i;

        var image = document.createElement("div");
        image.classList.add((i < 10 ? 'img0' : 'img') + i);
        image.classList.add("settings-environment-image");

        cell.appendChild(image);

        var name = document.createElement("span");
        name.textContent = i18n.translate(items[i]);
        name.classList.add("settings-environment-name");
        name.setAttribute('data-i18n', items[i]);
        cell.appendChild(name);

        cell.addEventListener("click", function () {
            onClick(this.index);
        });

        envRow.appendChild(cell);
    }

    // Update environment selection
    var that = this;
    var viewer = this.viewer;
    viewer.prefs.addListeners(saveKey, function () {
        that.updateEnvironmentSelection();
    }, function (value) {
        onClick(viewer.prefs.lightPreset);
        that.updateEnvironmentSelection();
    });

    this.updateEnvironmentSelection();
    return envRow;
};

ViewerSettingsPanel.prototype.updateEnvironmentSelection = function() {
    if (!this.is3dMode) {
        return;
    }

    var index = this.viewer.prefs.lightPreset;
    var cells = this.gridTable.querySelectorAll(".settings-environment-cell");
    for(var j =0; j<cells.length;j++) {
        if(cells[j].index === index) {
            cells[j].classList.add("border-select");
        } else {
            cells[j].classList.remove("border-select");
        }
    }
};

ViewerSettingsPanel.prototype.addLabel = function (tabId, name) {
    var table;
    var index = this.tabIdToIndex[tabId];
    if(index === -1) {
        return false;
    }
    table = this.tablesContainer.childNodes[index];
    this.settingsLabel = new OptionLabel(name,table.tBodies[0]);
    this.settingsLabel.sliderRow.classList.add('logical-group');
    return this.settingsLabel;
};

/**
 * Removes an option from the given tab.
 *
 * @param {HTMLElement} checkBoxElem - checkbox to remove.
 *
 */
ViewerSettingsPanel.prototype.removeCheckbox = function(checkBoxElem)
{
    this.viewer.prefs.removeListeners(checkBoxElem.saveKey);
    this.removeEventListener(checkBoxElem, "change", checkBoxElem.changeListener);

    return SettingsPanel.prototype.removeCheckbox.call(this, checkBoxElem);
};

/**
 *  Populates the navigation tab with the appropriate checkboxes.
 */
ViewerSettingsPanel.prototype.createNavigationPanel = function()
{
    var viewer = this.viewer;
    var navTab = ViewerSettingTab.Navigation;
    var table = this.tablesContainer.childNodes[1];

    if (this.is3dMode) {

        this.addLabel(navTab, "ViewCube");

        this.addCheckbox(navTab, "Show ViewCube", "Toggles availability of the ViewCube navigation control" ,true, function(checked) {
            viewer.displayViewCube(checked);
        }, "viewCube");

        if(!isMobileDevice()) {
          this.addCheckbox(navTab, "ViewCube acts on pivot", "When enabled, the ViewCube orbits the view around the active pivot point When disabled, it orbits around the center of the view", false, function (checked) {
            viewer.setUsePivotAlways(checked);
          }, "alwaysUsePivot");
        }

        this.addLabel(navTab, "Orbit");

        this.addCheckbox(navTab, "Fusion style orbit", "Enables Fusion-style orbit overlay and gives the ability to lock orbit axis", false, function(checked) {
            if (checked)
                viewer.loadExtension('Autodesk.Viewing.FusionOrbit', null);
            else
                viewer.unloadExtension('Autodesk.Viewing.FusionOrbit', null);
        }, "fusionOrbit");

        this.addCheckbox(navTab, "Orbit past world poles", "Allows view rotation to continue past the model’s North Pole", true, function(checked) {
            viewer.setOrbitPastWorldPoles(checked);
        }, "orbitPastWorldPoles");

      if(!isMobileDevice()) {
        this.addLabel(navTab, "Zoom");

        this.addCheckbox(navTab, "Zoom towards pivot", "When disabled, zooming operations are centered at the current cursor location", false, function (checked) {
          viewer.setZoomTowardsPivot(checked);
        }, "zoomTowardsPivot");

        this.addCheckbox(navTab, "Reverse mouse zoom direction", "Toggles direction of zooming in and out", false, function (checked) {
          viewer.setReverseZoomDirection(checked);
        }, "reverseMouseZoomDir");

        this.addLabel(navTab, "Mouse");

        this.addCheckbox(navTab, "Left handed mouse setup", "Swaps the buttons on the mouse", false, function (checked) {
          viewer.setUseLeftHandedInput(checked);
        }, "leftHandedMouseSetup");

        this.addCheckbox(navTab, "Set pivot with left mouse button", "Change left-click behavior to set new pivot point (overrides select object)", false, function (checked) {
          viewer.setClickToSetCOI(checked);
        }, "clickToSetCOI");
      }

      this.addCheckbox(navTab, "Open properties on select", "Always show properties upon selecting object", true, function (checked) {
        viewer.setPropertiesOnSelect(checked);
      }, "openPropertiesOnSelect");

    }

    if(!this.is3dMode) {

        this.addLabel(navTab, "Zoom");

        this.addCheckbox(navTab, "Reverse mouse zoom direction", "Toggles direction of zooming in and out", false, function(checked) {
            viewer.setReverseZoomDirection(checked);
        }, "reverseMouseZoomDir");

        this.addLabel(navTab, "Mouse");

        this.addCheckbox(navTab, "Open properties on select", "Always show properties upon selecting object", true, function(checked) {
            viewer.setPropertiesOnSelect(checked);
        }, "openPropertiesOnSelect");

        this.addCheckbox(navTab, "Left handed mouse setup", "Swaps the buttons on the mouse", false, function(checked) {
            viewer.setUseLeftHandedInput(checked);
        }, "leftHandedMouseSetup");

    }
};

/** Populates the performance tab with the appropriate checkboxes.
 *
 */
ViewerSettingsPanel.prototype.createPerformancePanel = function() {
    var viewer = this.viewer;
    var perfTab = ViewerSettingTab.Performance;
    var table = this.tablesContainer.childNodes[0];


    if (this.is3dMode) {

        this.addLabel(perfTab, "Performance Optimization");

        this.optimizeNavigationhkBoxId = this.addCheckbox(perfTab, "Smooth navigation", "Provides faster response(but degrades quality) while navigating",
            isMobileDevice(), function(checked) {
            viewer.setOptimizeNavigation(checked);
        }, "optimizeNavigation");

        this.progressiveRenderChkBoxId = this.addCheckbox(perfTab, "Progressive display", "Shows incremental updates of the view and allows for more responsive interaction with the model (some elements may flicker) This improves perceived waiting time",
            true, function(checked) {
            viewer.setProgressiveRendering(checked);
        }, "progressiveRendering");

        this.addLabel(perfTab, "Display");

        this.ghosthiddenChkBoxId = this.addCheckbox(perfTab, "Ghost hidden objects", "Leave hidden objects slightly visible",
            true, function(checked) {
            viewer.setGhosting(checked);
        }, "ghosting");

        this.displayLinesId = this.addCheckbox(perfTab,"Display Lines", "Toggles display of line objects", true, function (checked) {
            viewer.hideLines(!checked);
        }, "lineRendering");


        this.displayPointsId = this.addCheckbox(perfTab,"Display Points", "Toggles display of point objects", true, function (checked) {
            viewer.hidePoints(!checked);
        }, "pointRendering");


        this.displayEdgesId = this.addCheckbox(perfTab,"Display edges", "Shows outline of model surfaces", false, function (checked) {
            viewer.setDisplayEdges(checked);
        }, "edgeRendering");

    }
    if (!this.is3dMode) {
        // 2D only

        this.addLabel(perfTab, "Performance Optimization");

        this.progressiveRenderChkBoxId = this.addCheckbox(perfTab, "Progressive display", "Shows incremental updates of the view and allows for more responsive interaction with the model (some elements may flicker) This improves perceived waiting time",
           true, function(checked) {
           viewer.setProgressiveRendering(checked);
        }, "progressiveRendering");

    }
};
    /**
     * Populates the appearance tab with the appropriate checkboxes.
     */

ViewerSettingsPanel.prototype.createAppearancePanel = function () {
    var viewer = this.viewer;
    var appearTab = ViewerSettingTab.Appearance;
    var table = this.tablesContainer.childNodes[2];


    if (this.is3dMode) {

        this.addLabel(appearTab, "Visual Quality Optimization");

        this.antialiasingChkBoxId = this.addCheckbox(appearTab, "Anti-aliasing", "Remove jagged edges from lines", true, function(checked) {
            viewer.setQualityLevel(viewer.prefs.ambientShadows, checked);
        }, "antialiasing");

        this.ambientshadowsChkBoxId = this.addCheckbox(appearTab, "Ambient shadows", "Improve shading of occluded surfaces", true, function(checked) {
            viewer.setQualityLevel(checked, viewer.prefs.antialiasing);
        }, "ambientShadows");

        this.groundShadowChkBoxId = this.addCheckbox(appearTab, "Ground shadow", "Add simulated ground surface shadows", true, function(checked) {
            viewer.setGroundShadow(checked);
        }, "groundShadow");

        this.groundReflectionChkBoxId = this.addCheckbox(appearTab, "Ground reflection", "Add simulated ground surface reflections", false, function(checked) {
            viewer.setGroundReflection(checked);
        }, "groundReflection");
    }

    if(!this.is3dMode) {

       this.addLabel(appearTab, "Existing behavior");

        this.swapBlackAndWhiteChkBoxId = this.addCheckbox(appearTab, "2D Sheet Color", "Switch sheet color white to black", true, function(checked) {
            viewer.setSwapBlackAndWhite(checked);
        }, "swapBlackAndWhite");
    }
};


ViewerSettingsPanel.prototype.createEnvironmentPanel = function () {
    if (!this.is3dMode) {
        return;
    }

    var viewer = this.viewer;
    var environmentTab = ViewerSettingTab.Environment;
    var table = this.tablesContainer.childNodes[3];
    this.gridTable = table;

    this.addLabel(environmentTab, "Environment");

    this.envMapBackgroundChkBoxId = this.addCheckbox(environmentTab, "Environment Image Visible", "Shows lighting environment as background", true, function(checked) {
        viewer.setEnvMapBackground(checked);
    }.bind(this), "envMapBackground");

    var captionRow = table.tBodies[0].insertRow(-1);

    var cell = captionRow.insertCell(0);
    this.caption = document.createElement("div");
    this.caption.setAttribute("data-i18n", "Environments and Lighting Selection");
    this.caption.textContent = i18n.translate("Environments and Lighting Selection");
    this.caption.classList.add("settings-row-title");
    cell.appendChild(this.caption);
    cell.colSpan = "3";

    var env_list = [];
    for (var i=0; i<LightPresets.length; i++) {
        env_list.push(LightPresets[i].name);
    }

    this.envSelect = this.addGrid(
        "Environments and Lighting Selection",
        table,
        env_list,
        viewer.impl.currentLightPreset(),
        function onAction(index) {
            viewer.setLightPreset(index);
        },
        "lightPreset"
    );

    // Only display the icons with environment.
    this.envSelect.classList.add("with-environment");
};

ViewerSettingsPanel.prototype.createRestoreDefaultSettingsButton = function () {
    var viewer = this.viewer;

    this.restoreDiv = document.createElement('div');
    this.restoreDiv.classList.add('docking-panel-container-solid-color-b');
    this.restoreDiv.classList.add('restore-defaults-container');

    this.restoreButton = document.createElement('div');
    this.restoreButton.className = 'docking-panel-tertiary-button';
    this.restoreButton.setAttribute("data-i18n", "Restore all default settings");
    this.restoreButton.textContent = Autodesk.Viewing.i18n.translate("Restore all default settings");
    this.restoreDiv.appendChild(this.restoreButton);

    this.versionDiv = document.createElement('div');
    this.versionDiv.textContent = getVersionString(); // No need to localize.
    this.versionDiv.className = 'docking-panel-version-label';

    this.addEventListener(this.restoreDiv, 'touchstart', touchStartToClick );
    this.addEventListener(this.restoreDiv, 'click', function () {
        viewer.restoreDefaultSettings();
    }, false);

    this.scrollContainer.appendChild(this.restoreDiv);
    this.scrollContainer.appendChild(this.versionDiv);
};


ViewerSettingsPanel.prototype.selectTab = function( tabId ) {

    Autodesk.Viewing.UI.SettingsPanel.prototype.selectTab.call(this, tabId);
    this.sizeToContent();
};

/**
 * Resizes panel vertically to wrap around the content.
 * It will always leave some room at the bottom to display the toolbar.
 */
ViewerSettingsPanel.prototype.sizeToContent = function() {
    var csize = this.getContentSize();
    var cHeight = csize.height + this.heightAdjustment;
    var maxHeight = this.viewer.container.clientHeight - this.heightAdjustment;
    var minSize = Math.min(cHeight, maxHeight);
    this.container.style.height = parseInt(minSize) + 'px';
};

ViewerSettingsPanel.prototype.onViewerResize = function(vt, vb, vl, vr, vw, vh) {
    // Avoid default behavior by overriding inherited implementation.
    this.sizeToContent();
};

/**
 * Updates the values in the checkboxes based on what is in the prefs.
 */
ViewerSettingsPanel.prototype.syncUI = function() {

    var viewer = this.viewer;
    var prefs = this.viewer.prefs;

    this.setControlValue(this.antialiasingChkBoxId, prefs.antialiasing);
    this.setControlValue(this.ambientshadowsChkBoxId, prefs.ambientShadows);
    this.setControlValue(this.groundShadowChkBoxId, prefs.groundShadow);
    this.setControlValue(this.groundReflectionChkBoxId, prefs.groundReflection);
    this.setControlValue(this.envMapBackgroundChkBoxId, viewer.impl.isEnvMapBackground());
    this.setControlValue(this.progressiveRenderChkBoxId, prefs.progressiveRendering);
    this.setControlValue(this.swapBlackAndWhiteChkBoxId, prefs.swapBlackAndWhite);
    this.setControlValue(this.ghosthiddenChkBoxId, prefs.ghosting);
    this.setControlValue(this.displayLinesId, prefs.lineRendering);
    this.setControlValue(this.displayPointsId, prefs.pointRendering);
    this.setControlValue(this.displayEdgesId, prefs.edgeRendering);
    
    this.updateEnvironmentSelection();
};

/**
 * Safely sets the value of a checkbox control.
 * 
 * @param {string} ctrlName - The id of the control
 * @param {boolean} value
 */
ViewerSettingsPanel.prototype.setControlValue = function(ctrlName, value) {
    var ctrl = this.getControl(ctrlName);
    if (ctrl) {
        ctrl.setValue(value);
    }
};


function getVersionString() {
    var version = LMV_VIEWER_VERSION;
    if (LMV_VIEWER_VERSION.charAt(0) === "@") {
        version = '0.0.0' // No need to localize.
    }
    return 'v' + version;
}
