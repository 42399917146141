import leafletdiff_frag from '../render/shaders/leafletdiff_frag.glsl';
import leafletdiff_vert from '../render/shaders/leafletdiff_vert.glsl';
import { UVTransform, GeometryManager } from './TexQuadUtils';
import * as THREE from "three";

export const LeafletDiffModes = {
    NORMAL_DIFF: 0,
    MODEL_A_ONLY: 1,
    MODEL_B_ONLY: 2,
    SPLIT_VIEW: 3,
    HIGHLIGHT_A: 4,
    HIGHLIGHT_B: 5
};

function Rect(l = Infinity, b = Infinity, r = -Infinity, t = -Infinity) {
    this.l = l;
    this.b = b;
    this.r = r;
    this.t = t;
}

Rect.prototype.width = function() {
    return this.r - this.l;
};

Rect.prototype.height = function() {
    return this.t - this.b;
};

Rect.prototype.getIntersection = function(otherRect) {
    var l = Math.max(this.l, otherRect.l);
    var r = Math.min(this.r, otherRect.r);
    var b = Math.max(this.b, otherRect.b);
    var t = Math.min(this.t, otherRect.t);
    if (r > l && t > b)
        return new Rect(l, b, r, t);
    else
        return null;
};

Rect.prototype.equals = function(otherRect) {
    return this.l === otherRect.l && this.r === otherRect.r && this.t === otherRect.t && this.b === otherRect.b;
};

Rect.prototype.union = function(otherRect) {
    this.l = Math.min(this.l, otherRect.l);
    this.r = Math.max(this.r, otherRect.r);
    this.b = Math.min(this.b, otherRect.b);
    this.t = Math.max(this.t, otherRect.t);

    return this;
};

/**
 Manages lists of materials for reuse, avoiding the creation of too many materials
 Each material is grouped by a type identifier (a string), since mixing shader materials
 is not possible without recompiling the shader material.
*/
function ShaderMaterialManager() {
    var _reusedMaterials = {};
    var _nextFreeMaterial = {};

    function initReusedContainer(type) {
        _reusedMaterials[type] = [];
        _nextFreeMaterial[type] = 0;
    }

    function disposeMaterial(material) {
        if (!material) {
            return;
        }

        // dispose shader program etc.
        material.dispose();
        material.needsUpdate = true;
    }

    this.acquireShaderMaterial = function(uniforms, type) {
        if (!_reusedMaterials[type]) {
            initReusedContainer(type);
        }

        // get next reusable material and increase counter
        var material = _reusedMaterials[type][_nextFreeMaterial[type]];

        if (!material) {
            material = new THREE.ShaderMaterial({
                uniforms: uniforms,
                vertexShader: leafletdiff_vert,
                fragmentShader: leafletdiff_frag
            });

            _reusedMaterials[type][_nextFreeMaterial[type]] = material;
        } else {
            material.defines = {};  // Clean defines

            for (var uniform in uniforms) {
                material.uniforms[uniform].value = uniforms[uniform].value;
            }
        }

        _nextFreeMaterial[type]++;
        return material;
    };

    this.reset = function() {
        for (var nextFree in _nextFreeMaterial) {
            _nextFreeMaterial[nextFree] = 0;
        }
    };

    this.dispose = function() {
        for (var type in _reusedMaterials) {
            for (var j = 0; j < _reusedMaterials[type].length; j++) {
                disposeMaterial(_reusedMaterials[type][j]);
            }
        }
    }
}

/** @classDesc Wraps two iterators of type {ModelIteratorTexQuad} and produces a new scene which blends their textures
 *             according to the desired compare mode:
 *                 NORMAL_DIFF: Show differences in red (pixels only in model A) and blue (pixels only in model B), gray otherwise.
 *                 MODEL_A_ONLY, MODEL_B_ONLY: Show only one model (A or B)
 *                 SPLIT_VIEW: Show model A on the left, model B on the right of a split line
 *                 HIGHLIGHT_A: Show in red pixels only in model A, gray otherwise
 *                 HIGHLIGHT_B: Show in red pixels only in model B, gray otherwise
 *
 *             The documents may not always overlap, in which case there's an offset that can be added to the second document.
 *
 *             The tiles themselves may not always completely overlap, so the common area between 2 tiles is found, and a new
 *             shader material is created which contains the textures that correspond to both documents at that position. The
 *             offset and sizes of the textures relevant for that portion are also sent to the shader, where the actual comparison
 *             is done (see leafletdiff_frag.glsl). The leftover parts that don't intersect are in the end added as well.
 *
 *   @class
 *   @param {ModelIteratorTexQuad}  iterA
 *   @param {ModelIteratorTexQuad}  iterB
 */
export function LeafletDiffIterator(iterA, iterB) {
    var _iterA = iterA;
    var _iterB = iterB;

    // reused scene that we reconfigure on each iterator reset.
    var _scene = new THREE.Scene();

    // This iterator returns only a single scene. Therefore, _done is set to false when on iteration start (this.reset())
    // and set to true again after first call of nextBatch.
    var _done = true;

    var _needsRedraw = false;   // Used to trigger redraw

    var _isFirstScene = true;   // Used to know if this is the first scene being created

    var geometryManager = new GeometryManager();
    var materialManager = new ShaderMaterialManager();

    var _offset = new THREE.Vector3();  // The offset for aligning the second document
    var _splitPosition = 0.5; // Percentage of the width where the split line is positioned
    var _canvasWidth = 0;
    var addThickening = false;  // Adds an extra pixel adjacent to diff pixels, to thicken the lines

    const HIGHLIGHT_NONE = 0;
    const HIGHLIGHT_A = 1;
    const HIGHLIGHT_B = 2;
    const HIGHLIGHT_BOTH = 3; // A + B

    var _highlightMode = HIGHLIGHT_BOTH;    // Used when highlighting only the differences in one document
    var pixelRatio = window.devicePixelRatio || 1;
    var _deltaZ = 0.0;  // For the non-intersecting tiles, since they might overlap a bit with other tiles

    var _diffMode = LeafletDiffModes.NORMAL_DIFF;

    const NO_INTERSECTION = 0;
    const PARTIAL_INTERSECTION = 1;
    const FULL_INTERSECTION = 2;

    /**
     * Changes the comparison mode
     * @param {LeafletDiffModes} mode
     */
    this.setDiffMode = function(mode) {
        _diffMode = mode;
        _needsRedraw = true;
    };

    /**
     * Returns the current comparison mode
     * @return {LeafletDiffModes}
     */
    this.getDiffMode = function() {
        return _diffMode;
    };

    /**
     * Returns the current offset for the second document
     * @return {THREE.Vector3}
     */
    this.getOffset = function() {
        return _offset;
    };

    /**
     * Sets the offset for the second document, for alignment
     * @param {THREE.Vector3} offset
     */
    this.setOffset = function(offset) {
        _offset = offset;
    };

    /**
     * Sets the position of the split line, for split view mode
     * @param {Number} splitPos - a percentage of the document width (between 0 and 1)
     */
    this.setSplitPosition = function(splitPos) {
        _splitPosition = splitPos;
    };

    /** Perform raycast
     * @param {THREE.RayCaster} raycaster
     * @param {Object[]}        intersects - An object array that contains intersection result objects.
     *                                       Each result r stores properties like r.point, r.fragId, r.dbId. (see VBIntersector.js for details)
     */
    this.rayCast = function(raycaster, intersects) {

        // not implemented yet
        return null;
    };

    /** Copies visible bbox into the given output params. Since per-fragment visibility is not supported
     *  by this iterator, both bboxes are always identical.
     *
     *   @param {THREE.Box3} [visibleBounds]
     *   @param {THREE.Box3} [visibleBoundsWithHidden]
     */
    this.getVisibleBounds = function(visibleBounds, visibleBoundsWithHidden) {
        var tmpBox = new THREE.Box3();
        var tmpBoxWithHidden = new THREE.Box3();

        _iterA.getVisibleBounds(tmpBox, tmpBoxWithHidden);
        _iterB.getVisibleBounds(visibleBounds, visibleBoundsWithHidden);

        visibleBounds.union(tmpBox);
        visibleBoundsWithHidden.union(tmpBoxWithHidden);
    };

    /**
     * @returns {boolean} true if an offset has been set
     */
    function isOffsetNotEmpty() {
        return _offset.x !== 0 || _offset.y !== 0;
    }

    /**
     * Changes the current camera position when there's an offset, so that when resetting the second document it will
     * retrieve the correct tiles for the actual view.
     * @param {String} action - either 'sub' or 'add', to add or remove the offset
     * @param: {FrustumIntersector} frustum
     * @param: {UnifiedCamera}      camera
     */
    function setCameraOffset(action, frustum, camera) {
        camera.position[action](_offset);
        camera.target[action](_offset);
        camera.updateMatrixWorld(true);
        camera.matrixWorldInverse.getInverse(camera.matrixWorld);
        frustum.reset(camera);
    }

    /**
     * Adds a non-intersecting tile to the scene
     * @param {THREE.Mesh} shape - the source mesh
     * @param {Number} posZ - the z-coordinate for the new mesh. Needed to avoid z-fighting with the adjacent intersecting tiles
     * @param {Number} model - 1 or 2, for first or second document, to be passed to the shader
     * @returns {THREE.Mesh} the newly created mesh
     */
    function addNonIntersectingTiles(shape, posZ, model) {
        var mesh = shape.clone();
        mesh.position.z = posZ;
        var texture = shape.material.map;

        var uniforms = {
            texture1: { type: 't', value: texture },
            offsetRepeatA: {type: 'v4', value: new THREE.Vector4(texture.offset.x, texture.offset.y, texture.repeat.x, texture.repeat.y )},
            model: { type: 'i', value: model},
            splitPosition: {type: 'f', value: _splitPosition * _canvasWidth * pixelRatio},
        };

        var isSplit = (_diffMode === LeafletDiffModes.SPLIT_VIEW);
        var material = materialManager.acquireShaderMaterial(uniforms, 'nonintersect' + (isSplit ? 'split' : ''));
        material.defines.SINGLE_MODEL = 1;

        if (isSplit) {
            material.defines.SPLIT_VIEW = 1;
        }

        mesh.material = material;
        _scene.add(mesh);

        return mesh;
    }

    /**
     * Calculates the transform for the textures when there's an intersection. Compares the relative change from the intersecting
     * rectangle to the original rectangle, in order to find the same change in the texture offsets and scale.
     * @param {UVTransform} uvTF - the transform to update
     * @param {UVTransform} origUvTF - the original transform
     * @param {Rect} rect - the original tile rectangle
     * @param {Rect} intersec - the intersection of rect with another rectangle
     */
    function calcTransformation(uvTF, origUvTF, rect, intersec) {
        uvTF.offsetX = origUvTF.offsetX + (intersec.l - rect.l) / rect.width() * origUvTF.scaleX;
        uvTF.offsetY = origUvTF.offsetY + (intersec.b - rect.b) / rect.height() * origUvTF.scaleY;
        uvTF.scaleX = origUvTF.scaleX * intersec.width() / rect.width();
        uvTF.scaleY = origUvTF.scaleY * intersec.height() / rect.height();
    }

    /**
     * Adds an intersecting tile to the scene
     * @param {THREE.Mesh} shapeB - Mesh from second document
     * @param {THREE.Mesh} shapeA - Mesh from first document
     * @param {Rect} rectA - the first document's tile rectangle
     * @param {THREE.Texture} textureA - the first document's texture corresponding to shapeA
     * @param {boolean} isPartial - true if the current miplevel hasn't fully loaded yet.
     *                              To avoid flickering of the materials we don't show a level until it's ready.
     * @returns {Rect} the intersection found
     */
    function addMeshFromIntersection(shapeB, shapeA, rectA, textureA, isPartial) {
        var rectB = shapeB.rect;
        var intersec = rectA.getIntersection(rectB);

        if (intersec) {
            shapeB.intersecStatus = PARTIAL_INTERSECTION; // Some intersection found
            shapeB.intersec.union(intersec);    // Keep track of total intersection for shapeB
            var textureB = shapeB.material.map;

            var mesh = shapeA.clone();
            var uvTFa = new UVTransform();
            var origUvTFa = mesh.geometry.uvTransform;
            if (!intersec.equals(rectA)) {
                // Since intersect is different from rectA, the relative uv transform must be found
                // and a new geometry is needed, since a single geometry can't share different uv tranforms.
                calcTransformation(uvTFa, origUvTFa, rectA, intersec);
                mesh.geometry = geometryManager.acquireQuadGeom(uvTFa);
            } else {
                // rectA == intersect, so the same transform will do
                origUvTFa.copyTo(uvTFa);

                if (intersec.equals(rectB)) {
                    // Full equality -> rectA == intersect == rectB
                    // shapeB could not intersect with any other tile
                    shapeB.intersecStatus = FULL_INTERSECTION;
                }
            }

            var uvTFb = new UVTransform();
            var origUvTFb = shapeB.geometry.uvTransform;
            // Get the relative uv transform for the second doc's texture as well. Passed to the shader for calculations.
            calcTransformation(uvTFb, origUvTFb, rectB, intersec);

            // The mesh will have the dimensions of the intersection
            mesh.position.set(intersec.l, intersec.b, 0.0);
            mesh.scale.set(intersec.width(), intersec.height(), 1.0);

            var resolutionA = new THREE.Vector2();
            var resolutionB = new THREE.Vector2();
            if (addThickening) {
                resolutionA.x = 1.0 / (1 | (textureA.image.width * pixelRatio));
                resolutionA.y = 1.0 / (1 | (textureA.image.height * pixelRatio));
                resolutionB.x = 1.0 / (1 | (textureB.image.width * pixelRatio));
                resolutionB.y = 1.0 / (1 | (textureB.image.height * pixelRatio));
            }

            var uniforms = {
                texture1: {type: 't', value: textureA},
                texture2: {type: 't', value: textureB},
                offsetRepeatA: {type: 'v4', value: new THREE.Vector4(textureA.offset.x, textureA.offset.y, textureA.repeat.x, textureA.repeat.y )},
                offsetRepeatB: {type: 'v4', value: new THREE.Vector4(textureB.offset.x, textureB.offset.y, textureB.repeat.x, textureB.repeat.y )},
                uvTFa: {type: 'v4', value: uvTFa.toVec4()},
                uvTFb: {type: 'v4', value: uvTFb.toVec4()},
                highlight: {type: 'i', value: isPartial ? HIGHLIGHT_NONE : _highlightMode},
                splitPosition: {type: 'f', value: _splitPosition * _canvasWidth * pixelRatio},
                resolution1: {type: 'v2', value: resolutionA },
                resolution2: {type: 'v2', value: resolutionB }
            };

            var isSplit = (_diffMode === LeafletDiffModes.SPLIT_VIEW);
            var material = materialManager.acquireShaderMaterial(uniforms, 'intersection' + (isSplit ? 'split' : ''));
            if (isSplit) {
                material.defines.SPLIT_VIEW = 1;
            }
            if (addThickening) {
                material.defines.ADD_THICKENING = 1;
            }

            mesh.material = material;
            _scene.add(mesh);

            return intersec;
        }

        return null;
    }

    /**
     * Initialize and calculate some parameters for the second document
     * @param {THREE.Scene} sceneB
     */
    function preprocessModelB(sceneB) {
        for (var j = 0; j < sceneB.children.length; j++) {
            var shapeB = sceneB.children[j];
            shapeB.intersecStatus = NO_INTERSECTION;    // Status of this shape's intersection
            shapeB.intersec = new Rect();   // Current intersection

            var posB = new THREE.Vector3();
            posB.addVectors(shapeB.position, _offset); // Store the position with the alignment offset
            var scaleB = shapeB.scale;

            shapeB.rect = new Rect(posB.x, posB.y, posB.x + scaleB.x,posB.y + scaleB.y);
        }
    }

    /**
     * Main function for the standard compare (red for model A, blue for model, gray otherwise), as well as the highlight A or B modes.
     * Resets the internal iterators, applying offset if needed, and calls the performDiff function
     * @param: {FrustumIntersector} frustum
     * @param: {UnifiedCamera}      camera
     * @returns {boolean} true if both scenes are stable (all tiles for current view loaded)
     */
    function normalDiff(frustum, camera) {
        var hasOffset = isOffsetNotEmpty();
        _deltaZ = 0.5*(camera.far - camera.near);

        // Reset the wrapped iterators
        var sceneAcomplete = _iterA.reset(frustum, camera);

        // Move the camera in case the second model is offsetted for alignment
        if (hasOffset) {
            setCameraOffset('sub', frustum, camera);
        }

        var sceneBcomplete = _iterB.reset(frustum, camera);

        if (hasOffset) {
            setCameraOffset('add', frustum, camera);
        }

        performDiff(!sceneAcomplete || !sceneBcomplete);

        return sceneAcomplete && sceneBcomplete;
    }

    /**
     * Iterates through the scene and finds the intersecting rectangles.
     * @param {boolean} isIncomplete - true if one of the iterators hasn't finished loading all the tiles for the current level
     */
    function performDiff(isIncomplete = false) {
        var sceneA = _iterA.getScene();
        var sceneB = _iterB.getScene();

        preprocessModelB(sceneB);

        for (var i = 0; i < sceneA.children.length; i++) {
            var status = NO_INTERSECTION;
            var shapeA = sceneA.children[i];
            var tileA = shapeA.tile;
            var textureA = shapeA.material.map;
            var matTileA = shapeA.material.tile;

            var posA = shapeA.position;
            var scaleA = shapeA.scale;
            var rectA = new Rect(posA.x, posA.y, posA.x + scaleA.x,posA.y + scaleA.y);
            var totalIntersec = new Rect();
            // Assuming most cases will be of matching documents (in size). That means if sceneA and sceneB have
            // the same children number we can start checking from the same index
            var first = i < sceneB.children.length ? i : 0;
            var last = sceneB.children.length;
            for (var j = first; j < last; j++) {
                var shapeB = sceneB.children[j];
                var tileB = shapeB.tile;
                var matTileB = shapeB.material.tile;
                // For partial intersections we show everything in grayscale, otherwise we get situations where it looks like the materials are flickering red and blue
                // until it stabilizes.
                var isPartial = isIncomplete && (matTileB.level !== matTileA.level || tileA.level !== tileB.level || matTileA.level < tileA.level || matTileB.level < tileB.level);
                var intersec = addMeshFromIntersection(shapeB, shapeA, rectA, textureA, isPartial);
                if (intersec) {
                    status = shapeB.intersecStatus;
                    totalIntersec.union(intersec);
                    if (status === FULL_INTERSECTION) {
                        break;
                    }
                }

                if (first > 0 && j === last - 1) {  // Go back to the start
                    last = first;
                    first = 0;
                    j = -1;
                }
            }

            // No intersection or partial intersection for shapeA
            // Improvement for partial intersection: find actual intersection (should be at most 2 rectangles), instead of drawing
            // the whole thing (might not be so much advantage from this - in most cases it might be 1 rectangle)
            if (status === NO_INTERSECTION || (status === PARTIAL_INTERSECTION && !rectA.equals(totalIntersec))) {
                addNonIntersectingTiles(shapeA, -0.2*_deltaZ, 1);
            }
        }

        // Finally add the shapes from second document that had none or partial intersection
        for (var k = 0; k < sceneB.children.length; k++) {
            var shapeB = sceneB.children[k];

            if (shapeB.intersecStatus === NO_INTERSECTION || (shapeB.intersecStatus === PARTIAL_INTERSECTION && !shapeB.rect.equals(shapeB.intersec))) {
                var mesh = addNonIntersectingTiles(shapeB, -0.1*_deltaZ, 2);
                mesh.position.add(_offset);
            }
        }
    }

    /**
     * Show only the first document
     * @param: {FrustumIntersector} frustum
     * @param: {UnifiedCamera}      camera
     */
    function showModelA(frustum, camera) {
        _iterA.reset(frustum, camera);
        _scene = _iterA.getScene().clone();
    }

    /**
     * Show only the second document
     * @param: {FrustumIntersector} frustum
     * @param: {UnifiedCamera}      camera
     */
    function showModelB(frustum, camera) {
        var hasOffset = isOffsetNotEmpty();

        if (hasOffset) {
            setCameraOffset('sub', frustum, camera);
        }
        _iterB.reset(frustum, camera);
        _scene = _iterB.getScene().clone();
        _scene.position.copy(_offset);
        if (hasOffset) {
            setCameraOffset('add', frustum, camera);
        }
    }

    /**
     * Show split view mode.
     * This just calls normalDiff for now, since all the changes are done in the shader
     * @param: {FrustumIntersector} frustum
     * @param: {UnifiedCamera}      camera
     */
    function splitView(frustum, camera) {
        normalDiff(frustum, camera);
    }

    var _progress = 0;
    var _lastTime = 0;
    var _isFirstProgress = true;

    /**
     * Gives a sense of progress while the scene is stabilizing (when all iterators' scenes are complete)
     * The progress here is very much a heuristic, since it's hard to know actually how much time it will take.
     * @param sceneComplete
     */
    this.updateProgress = function(sceneComplete) {
        if (sceneComplete) {
            this.onProgress(100);   // User provided callback
            _progress = 0;
            _isFirstProgress = true;
        } else {
            if (_isFirstProgress) {
                _lastTime = Date.now();
                _progress = 10;
                _isFirstProgress = false;
            } else {
                // Update with some progress
                var curTime = Date.now();
                var factor = _progress > 60 ? 10 : 5;
                _progress += factor * (curTime - _lastTime) / 250;
                _progress = Math.min(95, _progress);
                _lastTime = curTime;
            }

            this.onProgress(_progress);   // User provided callback
        }
    };

    /** Start iterator
     *   @param: {FrustumIntersector} frustum
     *   @param: {UnifiedCamera}      camera
     */
    this.reset = function(frustum, camera) {
        _done = false;

        for (var i = 0; i < _scene.children.length; i++) {
            var obj = _scene.children[i];
            obj.dispatchEvent( { type: 'removed' } );
        }

        // clear scene
        _scene.children.length = 0;
        _scene.position.set(0, 0, 0);

        _canvasWidth = camera.clientWidth;

        // reset counter for reused temp geometry and materials
        geometryManager.reset();
        materialManager.reset();
        var sceneComplete = true;

        switch (_diffMode) {
            case LeafletDiffModes.NORMAL_DIFF:
                _highlightMode = HIGHLIGHT_BOTH;
                sceneComplete = normalDiff(frustum, camera);
                break;
            case LeafletDiffModes.MODEL_A_ONLY:
                showModelA(frustum, camera);
                break;
            case LeafletDiffModes.MODEL_B_ONLY:
                showModelB(frustum, camera);
                break;
            case LeafletDiffModes.SPLIT_VIEW:
                splitView(frustum, camera);
                break;
            case LeafletDiffModes.HIGHLIGHT_A:
                _highlightMode = HIGHLIGHT_A;
                sceneComplete = normalDiff(frustum, camera);
                break;
            case LeafletDiffModes.HIGHLIGHT_B:
                _highlightMode = HIGHLIGHT_B;
                sceneComplete = normalDiff(frustum, camera);
                break;
        }

        if (_isFirstScene) {
            if (sceneComplete) {
                if (this.onFirstSceneComplete) {      // User provided callback for when first scene was loaded
                    this.onFirstSceneComplete();
                }
                _isFirstScene = false;
            }
        } else if (this.onProgress) {   // User provided callback for showing progress
           this.updateProgress(sceneComplete);
        }
    };

    /** @returns {THREE.Scene|null} */
    this.nextBatch = function() {
        // first call since reset => return _scene
        if (!_done) {
            _done = true;
            return _scene;
        }
        return null;
    };

    this.getScene = function() {
        return _scene;
    };

    this.getSceneCount = function() {
        return 1;   // TexQuadIterators are always rendered as a single batch
    };

    /** @returns {bool} */
    this.done = function() {
        return _done;
    };

    /** @returns {bool} Indicates that a full redraw is required to see the latest state. */
    this.update = function() {
        if (_needsRedraw) {
            _needsRedraw = false;
            return true;
        }

        return _iterA.update() || _iterB.update();
    };

    this.dispose = function() {
        // Dispose all material and geometries created for this iterator
        materialManager.dispose();
        geometryManager.dispose();
    };

    this.dtor = function() {
        this.dispose();
    }
}


