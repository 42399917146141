
import { errorCodeString, ErrorCodes } from "../file-loaders/net/ErrorCodes";
import * as et from "./EventTypes";
import { Document } from "./Document";
import { BubbleNode } from "./bubble";
import { logger } from "../logger/Logger";


/**
 * Wrapper application for the viewer component.
 *
 * Attaches ViewingApplication to a div by ID and initializes common properties
 * of the viewing application.
 *
 * @alias Autodesk.Viewing.ViewingApplication
 * @param {string} containerId - The ID of the main container.
 * @param {object} [options] - An optional dictionary of options.
 * @param {boolean} [options.disableBrowserContextMenu=true] - Disables the browser's default context menu.
 * @constructor
 */
export var ViewingApplication = function(containerId, options) {
    this.appContainerId = containerId;
    this.container = document.getElementById(containerId);
    this.options = options || {};
    this.myRegisteredViewers = {};
    this.myDocument = null; // Deprecated. Keep around but don't use it.
    this.bubble = null;     // Replacement for `myDocument`
    this.myCurrentViewer = null;
    this.urn = null;
    this.selectedItem = null;
    this.extensionCache = {}; // Cache for extensions to use for themselves

    var self = this;
    this.onHyperlinkHit = function(event) {
        var item = event.data.item;
        if (item) {
            self.selectItem(item);
        }
    };

    this.onLoadGeometry = function (event){
        var data = event.data;
        self.selectItem(data.item, data.onSuccessCb, data.onErrorCb);
    };
};

/**
 * Defines the 3D viewer type.
 */
ViewingApplication.prototype.k3D = '3D';


/**
 * Performs any necessary cleanup to allow the object to be garbage collected.
 */
ViewingApplication.prototype.finish = function() {
    if (this.myCurrentViewer) {
        this.myCurrentViewer.finish();
        this.myCurrentViewer = null;
    }
};

/**
 * Register a Viewer to be used with this ViewingApplication.
 *
 * @param {number} viewableType - Currently must be ViewingApplication.k3D.
 * @param {*} viewerClass
 * @param {*} config
 */
ViewingApplication.prototype.registerViewer = function(viewableType, viewerClass, config) {

    if (viewableType !== this.k3D) {
        logger.error("The only acceptable viewer type is k3D.", errorCodeString(ErrorCodes.VIEWER_INTERNAL_ERROR));
        return;
    }

    // Pass the disableBrowserContextMenu option down to the viewer config.
    //
    config = config || {};
    if (this.options && this.options.hasOwnProperty("disableBrowserContextMenu")) {
        // Don't override if the option was already explicitly specified.
        //
        if (!config.hasOwnProperty("disableBrowserContextMenu")) {
            config.disableBrowserContextMenu = this.options.disableBrowserContextMenu;
        }
    }

    this.myRegisteredViewers[viewableType] = {};
    this.myRegisteredViewers[viewableType].class = viewerClass;
    this.myRegisteredViewers[viewableType].config = config;
};

ViewingApplication.prototype.getViewerClass = function(viewableType) {
    return this.myRegisteredViewers.hasOwnProperty(viewableType) ? this.myRegisteredViewers[viewableType].class : null;
};

/**
 * Returns the container that will be used by the viewer.  By default uses the same container as the appContainer.
 * This method can be overridden to specify a different sub container for the viewer.
 * @returns {MemberExpression}
 */
ViewingApplication.prototype.getViewerContainer = function() {
    return document.getElementById(this.appContainerId);
};


function mergeConfigs(mergedConfig, config) {

    for (var name in config) {
        if (config.hasOwnProperty(name)) {

            var configValue = config[name],
                configValueIsArray = Array.isArray(configValue),
                mergedConfigValue = mergedConfig[name],
                mergedConfigValueIsArray = Array.isArray(mergedConfigValue);

            // If neither config value is an array, then the config value passed to
            // getViewer() overwrites the config value registered for this viewer.
            //
            if (!configValueIsArray || !mergedConfigValueIsArray) {
                mergedConfig[name] = configValue;

            } else {

                // But if one or the other config value is an array, then let's
                // concatenate them. We need to make them both arrays to do that:
                // they might be null/undefined, or they might be strings.
                //
                if (configValue) {
                    if (!configValueIsArray) {
                        configValue = [configValue];
                    }
                } else {
                    configValue = [];
                }
                if (mergedConfigValue) {
                    if (!mergedConfigValueIsArray) {
                        mergedConfigValue = [mergedConfigValue];
                    }
                } else {
                    mergedConfigValue = [];
                }
                mergedConfig[name] = mergedConfigValue.concat(configValue);

            }
        }
    }

}

/**
 * Returns a new instance of a Viewer of requested type.
 *
 * @param {object} config - Viewer configuration override.
 * @returns {Autodesk.Viewing.Viewer3D} Viewer instance or null.
 */
ViewingApplication.prototype.getViewer = function(config) {

    var registeredViewer = this.myRegisteredViewers[this.k3D];

    if (!registeredViewer)
        return null;

    // Merge the config object provided here with the config object provided
    // when the viewer type was registered. The former takes precedence.
    //
    var mergedConfig = {};
    var registeredViewerConfig = registeredViewer.config;

    mergeConfigs(mergedConfig, registeredViewerConfig);
    mergeConfigs(mergedConfig, config);

    var viewerClass = registeredViewer.class;

    if (this.myCurrentViewer && this.myCurrentViewer.__proto__.constructor === viewerClass) {
        this.myCurrentViewer.tearDown();
        this.myCurrentViewer.setUp(mergedConfig);
        return this.myCurrentViewer;
    }

    this.setCurrentViewer(null);

    // If previous viewer.initialize() failed, then clean it up now.
    // This might happen if, for instance, we had a 3d viewer but
    // WebGL is not supported.
    // TODO: need a better solution
    //
    var container = this.getViewerContainer();
    while (container.hasChildNodes()) {
        container.removeChild(container.lastChild);
    }

    var viewer = new viewerClass(container, mergedConfig);
    this.setCurrentViewer(viewer);
    return viewer;
};

/**
 * Sets this ViewingApplication's viewer to the provided viewer.
 * @param {Autodesk.Viewing.Viewer3D} viewer
 */
ViewingApplication.prototype.setCurrentViewer = function(viewer) {
    if (this.myCurrentViewer) {
        this.myCurrentViewer.removeEventListener(et.HYPERLINK_EVENT, this.onHyperlinkHit);
        this.myCurrentViewer.removeEventListener(et.LOAD_GEOMETRY_EVENT, this.onLoadGeometry);
        this.myCurrentViewer.finish();
    }
    this.myCurrentViewer = viewer;
    if (this.myCurrentViewer) {
        viewer.addEventListener(et.HYPERLINK_EVENT, this.onHyperlinkHit);
        viewer.addEventListener(et.LOAD_GEOMETRY_EVENT, this.onLoadGeometry);
        viewer.extensionCache = this.extensionCache;
    }
};

/**
 * Returns the currently set Viewer.
 * @return {Autodesk.Viewing.Viewer3D}
 */
ViewingApplication.prototype.getCurrentViewer = function() {
    return this.myCurrentViewer;
};

/**
 * Initializes the ViewingApplication with an already downloaded manifest that is Forge hosted.
 * There is no need to call loadDocument() when this function is used.
 * 
 * Available from version 2.15
 * 
 * @param {object} docManifest - A JavaScript object for the hosted manifest.
 * @return {boolean} - true if the document was successfully initialized, false if the document was not successfully initialized.
 */
ViewingApplication.prototype.setDocument = function(docManifest) {
    if (!docManifest.urn || docManifest.urn === "$file$") {
        // Method doesn't support local bubbles (because it doesn't make sense to do so)
        logger.error('Unsupported Manifest urn: ' + docManifest.urn);
        return false;
    }
    var documentPath = Document.getDocumentPath(docManifest.urn);
    this.myDocument = new Document(docManifest, documentPath);
    this.bubble = this.myDocument.getRoot();
    this.onDocumentLoaded(this.myDocument);
    return true;
};

/**
 * Asynchronously loads a document ({@link Autodesk.Viewing.BubbleNode}) given its ID.
 *
 * @param {*} documentId Viewable document ID.
 * @param {function} [onDocumentLoad] Called on success.
 * @param {function} [onLoadFailed] Called on error.
 * @param {object} [accessControlProperties] An optional list of key value pairs as access control properties,
 * which includes a list of access control header name and values, and an OAuth 2.0 access token.
 */
ViewingApplication.prototype.loadDocument = function(documentId, onDocumentLoad, onLoadFailed, accessControlProperties) {

    logger.track({
        category : "load_document",
        urn: (documentId.indexOf("urn:") == 0) ? documentId.substring(4) : documentId
    });

    Document.load(documentId,
         // onLoadCallback
        function(avDocument, errorsandwarnings) {
            // Almost the same as .setDocument() - but with the added errorsandwarnings, which needs to be revisited.
            this.myDocument = avDocument;
            this.bubble = avDocument.getRoot();
            this.onDocumentLoaded(avDocument, errorsandwarnings);
            if (onDocumentLoad) {
                onDocumentLoad(avDocument, errorsandwarnings);
            }
        }.bind(this),
        // onErrorCallback
        function(errorCode, errorMsg, statusCode, statusText, errors) {
            this.onDocumentFailedToLoad(errorCode, errorMsg, errors);
            if (onLoadFailed)
                onLoadFailed(errorCode, errorMsg, statusCode, statusText, errors);
        }.bind(this),
        accessControlProperties
    );
};

/**
 * Default success callback for loadDocument.
 * @param {*} document
 */
ViewingApplication.prototype.onDocumentLoaded = function(avDocument, errorsandwarnings) {
    logger.log(avDocument, errorsandwarnings);
};

/**
 * Default success callback for documentFailedToLoad. Logs the document that was loaded on console.
 * @param {string} errorCode - Globalized error code.
 * @param {string} errorMsg - Error message to display.
 * @param {array} errors - List of errors that come from other clients (translators).
 */
ViewingApplication.prototype.onDocumentFailedToLoad = function( errorCode, errorMsg, errors ) {
    logger.error(errorCodeString(errorCode), errorMsg, errors);
};

/**
 * Given a list of geometry items, it will return 1 single item from the list that should be the first one to be loaded.
 * The method will attempt to find the item marked with attribute 'useAsDefault' with true.
 * When none is found, it will return the first element from the list.
 * @param {array} geometryItems
 * @returns {object} Item element contained in geometryItems.
 */
ViewingApplication.prototype.getDefaultGeometry = function(geometryItems) {
    // Attempt to find the item marked with 'useAsDefault'
    for (var i= 0, len=geometryItems.length; i<len; ++i) {
        var item = geometryItems[i];
        if (item instanceof BubbleNode) {
            item = item._raw();
        }
        var isDefault = item['useAsDefault'];
        if (isDefault === true || isDefault === 'true') {
            return geometryItems[i];
        }
    }
    return geometryItems[0];
};

/**
 * Asynchronously loads an individual item from a document into the correct viewer.
 * 
 * As of version version 2.15, parameter item may be a BubbleNode instead of a raw JavaScript object.
 * @param {*} item
 * @param {function} onSuccessCallback - This call back is called when the item is selected.
 * @param {function} onErrorCallback - This call back is called when the item fails to select.
 * @returns {boolean}
 */
ViewingApplication.prototype.selectItem = function(item, onSuccessCallback, onErrorCallback) {

    // used to pass Leaflet parameters from bubble items to the model loader.
    //TODO: remove this, it's now done inside loadDocumentNode
    // used to pass parameters from bubble items to the model loader.
    var loadOptions = {};

    // First class support of BubbleNode:
    if (!(item instanceof BubbleNode)) {
        console.warn("Deprecated use of ViewingApplication.selectItem. Pass BubbleNode instead of raw manifest JSON");
        item = this.bubble.findByGuid(item.guid);
    }

    var urnToLoad = this.myDocument.getViewablePath(item, loadOptions);

    // If the item to select is a view and there is no direct viewable (geometry), we assume
    // that this is a global view and simply use the current geometry, if there is one.
    if (!urnToLoad && item.isViewPreset()) {
        urnToLoad = this.urn;
    }

    if (!urnToLoad)
        return false;

    var viewItem, title, viewGeometryItem, canView = false;
    if (item.isGeometry() && item.is3D()) {
        // This is for the case that initial view is a child of geometry in some DWF files
        // Set this view's camera as initial camera
        //var children = item.children;
        //if (children) {
        //    for (var i in children) {
        //        if (children.hasOwnProperty(i) && children[i].type === 'view') {
        //            viewItem = children[i];
        //            break;
        //        }
        //    }
        //}

        canView = true;
        title = item.name();
        viewGeometryItem = item;
    } else if (item.isViewPreset() && item.is3D()) {
        viewItem = item;
        canView = true;
        viewGeometryItem = this.myDocument.getViewGeometry(item, true);
        if (viewGeometryItem) {
            title = viewGeometryItem.name();
        }
    } else if (item.isGeometry() && item.is2D()) {
        var f2dItems = item.search(BubbleNode.GEOMETRY_F2D_NODE);
        var leafletItems = item.search(BubbleNode.LEAFLET_NODE);
        var imageItems = item.search(BubbleNode.IMAGE_NODE);

        if (f2dItems.length > 0 || leafletItems.length > 0 || imageItems.length > 0)
            canView = true;

        title = item.name();
        viewGeometryItem = item;
    } else if (item.isViewPreset() && item.is2D()) {
        viewItem = item;
        canView = true;
        viewGeometryItem = this.myDocument.getViewGeometry(item, true);
        if (viewGeometryItem) {
            title = viewGeometryItem.name();
        }
    }

    if (!canView)
        return false;

    var idx = urnToLoad.indexOf("urn:");
    logger.track({
        category : "load_viewable",
        role : item._raw().role,
        type : item._raw().type,
        urn: (idx !== -1) ? urnToLoad.substring(idx+4) : urnToLoad
    });


    // Check if there are any warnings or errors from translators.
    // Exclude the global ones (ones from the root node).
    var messages = this.myDocument.getMessages( item, true );

    var self = this;
    var urnAlreadyLoaded = (this.myCurrentViewer && this.urn === urnToLoad);
    var onLoadCallback = function(model) {

        var viewer = self.myCurrentViewer;

        // set initial view (either 2D or 3D)
        if (viewer.setView(viewItem)) {
            // The view is set.
        } else if (urnAlreadyLoaded) {
            viewer.setViewFromFile(model);
        }

        if(onSuccessCallback) {
            onSuccessCallback(self.myCurrentViewer, item, messages );
        }
    };

    var onFailedToLoadCallback = function( errorCode, errorMsg, statusCode, statusText ) {
        if (onErrorCallback)
            onErrorCallback( errorCode, errorMsg, statusCode, statusText, messages );
    };

    var loaded = false;

    if (urnAlreadyLoaded) {
        if (onLoadCallback) {
            onLoadCallback();
        }
        loaded = true;

    } else {
        this.urn = null;
        var config = {defaultModelStructureTitle: title, viewableName: title};

        // Add any extensions to the config.
        //
        var itemExtensions = item.extensions();
        if (itemExtensions) {
            config.extensions = itemExtensions;
        }

        var viewer = this.getViewer(config);
        if (viewer) {

            var options = {
                ids: null,
                acmSessionId: this.myDocument.acmSessionId,
                loadOptions: loadOptions,
                useConsolidation: this.options.useConsolidation,
                consolidationMemoryLimit: this.options.consolidationMemoryLimit || (100 * 1024 * 1024) // 100 MB
            };

            // Only pass on isAEC if assigned: If we would always set this key, this would disable the loadHeuristics
            // for aec models (see Viewer3d.setLoadHeuristics)
            if (this.options.hasOwnProperty("isAEC")) {
                options.isAEC = this.options.isAEC;
            }

            //If the viewer is not started, use the optimized start+load sequence by calling start with the model to load
            //while starting. Otherwise do normal load.
            if (viewer.started) {
                viewer.loadDocumentNode(this.myDocument, item, options).then(onLoadCallback).catch(onFailedToLoadCallback);
                loaded = true;
            } else {
                viewer.startWithDocumentNode(this.myDocument, item, options).then(onLoadCallback).catch(onFailedToLoadCallback);
                loaded = true;
            }

            this.urn = urnToLoad;
        }
    }

    if (loaded) {
        this.selectedItem = item;
        this.onItemSelected(item, viewGeometryItem);
        return true;
    }

    return false;
};

/**
 * Called when selectItem successfully loads an item.
 *
 * @param {object} item - Can be either type 'view' or 'geometry'.
 * @param {object} viewGeometryItem - Can only be type 'geometry'.
 * Will be the same as item if item is type 'geometry'.
 */
ViewingApplication.prototype.onItemSelected = function(item, viewGeometryItem) {
    logger.log('Selected URL: http://' + location.host + location.pathname + '?document=urn:' + this.myDocument.getRoot().guid() + '&item=' + encodeURIComponent(item.guid()));

    // notify observers a new item was selected.
    if(this.itemSelectedObservers) {
        var currentViewer = this.getCurrentViewer();
        for(var i = 0; i < this.itemSelectedObservers.length; ++i){
            var observer = this.itemSelectedObservers[i];
            observer.onItemSelected && observer.onItemSelected(currentViewer, item, viewGeometryItem);
        }
    }
};

/**
 * Adds objects to be notified when a new item is selected in the browser tree.
 * @param {object} observer - Should implement function `onItemSelected(viewer)`.
 */
ViewingApplication.prototype.addItemSelectedObserver = function(observer) {

    if(!this.itemSelectedObservers) {
        this.itemSelectedObservers = [];
    }
    this.itemSelectedObservers.push(observer);
};

/**
 * Finds the item within the current document and calls selectItem.
 * @param {String} itemId
 * @param {function} [onItemSelectedCallback] - This call back is called when the item is selected.
 * @param {function} [onItemFailedToSelectCallback] - This call back is called when the item fails to select.
 * @returns {boolean}
 */
ViewingApplication.prototype.selectItemById = function(itemId, onItemSelectedCallback, onItemFailedToSelectCallback) {
    var item = this.myDocument.getRoot().findByGuid(itemId);
    if (item) {
        return this.selectItem(item, onItemSelectedCallback, onItemFailedToSelectCallback);
    }
    return false;
};

/**
 * Returns the node object containing metadata associated to the model currently loaded in the viewer.
 * @returns {null|object}
 */
ViewingApplication.prototype.getSelectedItem = function() {
    return this.selectedItem;
};


/**
 * @deprecated (This function seems like it is not used anywhere)
 * Returns a list of named views for the Viewer. It will use getSelectedItem() or
 * the item parameter, if available.
 * 
 * Users may call into setlectItem() with a value of the returned array.
 * 
 * Available from version 2.15
 * 
 * @param {object} [item] - The item to look for named views.
 * @returns {array} - All named views, returns empty array if no named views are found.
 */
ViewingApplication.prototype.getNamedViews = function(item) {
    item = item || this.selectedItem;
    if (!item || !item.guid)
        return [];

    var bubbleNode;
    if (! (item instanceof BubbleNode))
        bubbleNode = this.bubble.findByGuid(item.guid);
    else
        bubbleNode = item;

    // Need to make sure we are at a geometry level.
    if (item === this.selectedItem) {
        bubbleNode = bubbleNode.findParentGeom2Dor3D();
    }

    var views = bubbleNode.getNamedViews();
    return views;
};


