import * as globals from "../../wgs/globals";
import * as et from "../../application/EventTypes";
import { logger } from '../../logger/Logger';

var LOAD_ORDER_TIMEOUT = 100;       // 100 msec delay to ask for sorted load order

var PAST_LAST_PACK = 1.0e10;        // Large number past last pack id

// occlusion testing state.
var NO_OCCLUSION_TESTING = 0;
var QUEUE_OCCLUSION_TESTING = 1;
var PERFORM_OCCLUSION_TESTING = 2;

var MEGA = 1024 * 1024;

// This three constants are used to asjust the memory limit if
// the overhead is too large to allow any geometry to be loaded.
// We start with MIN_OVERHEAD_FACTOR * total geometry + overhead size
// and clip it to the range [MIN_OVERHEAD_LIMIT, MAX_OVERHEAD_LIMIT]
var MIN_OVERHEAD_FACTOR = 0.1;  // Factor of total size
var MIN_OVERHEAD_LIMIT = 10;
var MAX_OVERHEAD_LIMIT = 50;

// Paging proxy object to manage on demand loading and paging logic,
// that is specific to the model loaded by svf loader.
export var SvfPagingProxy = function(loader, options) {

    var _extendObject = function(target, source) {
        for (var prop in source) {
            if (source.hasOwnProperty(prop)) {
                target[prop] = source[prop];
            }
        }
    };

    var _loader = loader;

    // Options of control memory management.
    this.options = {
        onDemandLoading: false,
        pageOutGeometryEnabled: false
    };
    _extendObject(this.options, options);

    this.options.debug = {
        // Increase the max page out size. On slow (mobile) devices the scene
        // traversal is a bottle neck and making this larger helps load more
        // pack files earlier in the load.
        maxPageOutSize: 195,                                // Max we will page out in one go
        pixelCullingEnable: this.options.onDemandLoading,   // Useful with on demand loading
        pixelCullingThreshold: globals.PIXEL_CULLING_THRESHOLD,

        occlusionThreshold: 1,
        occlusionTestThreshold: 1,
        startOcclusionTestingPackCount: 8,
        testPackfileCount: 4,
        useOcclusionInstancing: true,
        automaticRefresh: true,
        boxProxyMaxCount: 0, // show this many boxes during a render
        boxProxyMinScreen: 0.4 // if entire render batch is >= 1/10 of the screen in area
    };
    _extendObject(this.options.debug, options.debug);


    // If reach limit, then stop loading any further pack files.
    this.reachLimit    = false;
    // the geom ids map, is a dictionary that key is the geometry id,
    // and value is an index to an array that record the traversed count
    // for that geometry.
    // ??? The reason that doesn't use the object to record the count but
    // ??? use the indirect arry is due to PERFORMANCE. 
    // ??? Because, if the JS object properties' value are changed frequently,
    // ??? the performance will hurt a whole lot.
    this.geomidsmap    = {};
    this.geomTravCount = [];

    // Variables for recording loaded or loading or queued pack files.
    this.loadedPacks = {};      // Staticly bound to functions, replace this object
    this.loadedPackfileCount = 0;
    this.memoryOverhead = 0;
    this.loadingPacks = {};
    this.loadingPacksSize = 0;
    this.queuedPacks = [];
    this.packQueuedMap = {};
    this.queuedPacksSize = 0;
    this.occludedPacks = [];
    this.occlusionCulledCount = 0;
    this.packsPagedOut = 0;

    this.traversedCounter = 0;
    this.transparentCounter = 0;
    this.resetCount = 0;
    this.invalidateCount = -1;

    // read from options, and passed by the loader.
    this.geompacks = _loader.svf.geompacks;
    this.geommap = _loader.geommap;
    this.totalGeomSize = this.options.totalGeomSize;
    this.overheadSize = this.options.overheadSize;
    // Adjust the limit if the overhead is too large.
    this.minMemoryLimit = Math.min(Math.max(this.totalGeomSize * MIN_OVERHEAD_FACTOR,
        MIN_OVERHEAD_LIMIT), MAX_OVERHEAD_LIMIT) + this.overheadSize;

    this.lastPageOut = -1;
    this.pageOutResetCounter = -1;

    var _resumeNextFrame = false;

    // Variables used to handle the load order from the worker
    var _nextOrderToLoad = 0;       // Next list of fragments to load
    var _nextFragToLoad = 0;        // Next fragment in load order
    var _loadOrderId = 0;           // Last load order request
    var _fragOrder = [];            // Fragment order - allow multiple ordered lists
    var _packOrder;                 // Pack file order
    var _pfVisible = -1;            // Number of visible pack files.
    var _packOrderMap = [];         // Order of pack files in pack order
    var _firstReset = true;         // Track first time we ask for the load order
    var _lastResult = null;         // Last load order result
    var _loadOrderTimer = 0;        // Timer used to keep down traffic to load order worker
    var _pageOutStillPossible = true;// More possible to page out
    var _loadDoneSent = false;      // Load done has been sent

    var loadMissingGeometryHandler = function(e) {
        // e.unloadPackFiles is for debugging only
        this.resetCanPageOut(!!e.debug.unloadPackFiles, e.delay != false, false);
    }.bind(this);
    _loader.viewer3DImpl && _loader.viewer3DImpl.api.addEventListener(et.LOAD_MISSING_GEOMETRY, loadMissingGeometryHandler);

    this.dtor = function() {
        _loader.viewer3DImpl && _loader.viewer3DImpl.api.removeEventListener(et.LOAD_MISSING_GEOMETRY, loadMissingGeometryHandler);
    };

    // Return true of false, whether on demand loading enabled.
    // This mainly controls how the geometries referenced by the fagments
    // are going to load. 
    //
    // If false, then geometry pack files will load in sequence all at once.
    // if true, then only those geometry pack files that are request to render,
    //          can they start to load *on demand*
    this.onDemandLoadingEnabled = function() {
        return this.options.onDemandLoading;
    };

    this.pageOutGeometryEnabled = function() {
        return this.options.pageOutGeometryEnabled && this.onDemandLoadingEnabled();
    };

    this.pixelCullingEnable = function() {
        return this.options.debug.pixelCullingEnable;
    };

    this.pixelCullingThreshold = function() {
        return this.options.debug.pixelCullingThreshold;
    };

    this.getMemoryLimit = function() {
        return Math.max(this.minMemoryLimit, this.options.limit);
    };

    /**
     * Get the memory stats when using on demand loading.
     * @returns {object|null} Object containing the limit and loaded memory usage for the model.
     *                        Return null if the model isn't being loaded on demand.
     */
    this.getMemoryInfo = function() {
        return this.onDemandLoadingEnabled() ? {
            limit: this.options.limit,
            effectiveLimit: this.getMemoryLimit(),
            loaded: this.preparedPackFilesSize()
        } : null;
    };

    this.getLoadedMeshes = function(packId) {
        var pack = this.loadedPacks[packId];
        return pack && pack.inMemory;
    };

    this.loadPackFile = function(/*packId*/) {
        return true;
    };

    this.doLoadPackFile = function(packId) {
        // If on demand loading is disabled, disallow load pack file arbitrarily.
        if (!this.onDemandLoadingEnabled())
            return false;
        if (this.loadingPacks[packId])
            return true;
        // Skip occluded packs
        if (this.occludedPacks[packId] === true)
            return false;

        if (this.queuedPacks.length > 0 || this.occlusionTesting >= QUEUE_OCCLUSION_TESTING) {
            if (!this.addGeomPackMissingLastFrame(packId))
                return false;
            this.loadGeometryMissingLastFrame();
            return true;
        }
        return _loader.loadGeometryPackOnDemand(packId, this.getLoadedMeshes(packId));
    };

    // Take the geometry we will keep in a pack file and create a new set
    // of buffers for them.
    function removeAndCompactGeometry(packId, frags, unloadAll, traversed, transparent, pack) {
        var bufferSize = 0;
        var unloadedSize = 0, size;
        var retainedGeoms = [];
        var inMemory = pack.inMemory;
        var needCompaction = false;
        var count = 0;
        var geomsList = frags.geoms;

        // If all of the current geometry has been traversed, then there
        // is nothing for us to do.
        if (!unloadAll && pack.travsed >= pack.currentCount)
            return 0;

        function processMesh(meshIdx) {
            // If mesh isn't in memory, return
            if (!(inMemory[meshIdx >> 5] & (1 << (meshIdx & 31))))
                return;

            var fragId = frags.fragments.mesh2frag[packId + ":" + meshIdx];
            if (Array.isArray(fragId))
                fragId = fragId[0];
            var geom = geomsList.geoms[frags.getGeometryId(fragId)];

            if (geom && geom.packId == packId) {
                // We handle meshes that occupy an entire buffer differently from
                // meshes that are sharing a buffer. If we unload a mesh that is
                // part of a shared buffer, we need to compact the buffer. Similarly
                // we only need to include meshes that are part of a shared buffer
                // in the meshes we want to compact.
                var partialBuffer = geom.vb.buffer === geom.ib.buffer
                    ? (geom.vb.buffer.byteLength > geom.vb.byteLength + geom.ib.byteLength)
                    : (geom.vb.buffer.byteLength > geom.vb.byteLength
                        || geom.ib.buffer.byteLength > geom.ib.byteLength);
                // remove geometry we don't want to keep and keep track of the size
                if ((unloadAll || (geom.traversed != traversed && geom.transparent != transparent))
                    && (size = geomsList.removeGeometry(geom.svfid, _loader.viewer3DImpl.glrenderer())) > 0) {
                    unloadedSize += size;
                    inMemory[geom.meshIndex >> 5] &= ~(1 << (geom.meshIndex & 31));
                    needCompaction = needCompaction || partialBuffer;
                } else {
                    ++count;
                    // Either don't want to remove the geometry, or we can't remove it
                    // We only compact geometry that is stored in a shared array buffer
                    if (partialBuffer) {
                        bufferSize += geom.vb.byteLength;
                        bufferSize += (geom.ib.byteLength + 3) & ~3;
                        retainedGeoms.push(geom);
                    }
                }
            } else
                console.error("Mismapped or missing geometry %d:%d", packId, meshIdx);
        }

        // Remove geometry that we can. Make a list of the geometry that
        // we want to keep.
        var i;
        for (i = 0; i < pack.totalCount; ++i)
            processMesh(i);
        pack.currentCount = count;
        pack.culled = 0;

        // Nothing more to do, if nothing was unloaded or everything was unloaded
        if (unloadedSize == 0)
            return 0;

        unloadedSize /= MEGA;
        // If we don't need to compact, then don't
        if (retainedGeoms.length == 0 || !needCompaction)
            return unloadedSize;

        var newBuffer = new ArrayBuffer(bufferSize);
        var offset = 0;

        // Copy a single buffer to the destination
        function copy(type, src, size) {
            var b = null;
            if (src) {
                var round = size - 1;
                offset = (offset + round) & ~round; // size must be a power of 2
                var length = src.length;
                b = new type(newBuffer, offset, length);
                b.set(src);
                offset += length * size;
            }
            return b;
        }

        // Copy data to new buffer
        retainedGeoms.forEach(function(geom) {
            geom.vb = copy(Float32Array, geom.vb, 4);
            geom.ib = copy(Uint16Array, geom.ib, 2);
        });
        
        return unloadedSize;
    }

    this.unloadPackFile = function(packId, unloadAll, pageOut) {
        // If on demand loading is disabled, can't unload on runtime.
        if (!this.onDemandLoadingEnabled()) {
          return false;
        }

        var frags = _loader.model.getFragmentList();
        var pack = this.loadedPacks[packId];
        if (!frags || !pack || !frags.geoms) {
            return false;
        }
        
        // Remove all geometries comming from this pack file
        removeAndCompactGeometry(packId, frags, unloadAll, this.traversedCounter, this.transparentCounter, pack);

        if (pack.currentCount == 0) {
            // Then, remove the record and decrease the count.
            delete this.loadedPacks[packId];
            --this.loadedPackfileCount;
            if (pageOut)
                ++this.packsPagedOut;
        }
        
        return true;
        
    };

    var redrawIfIdle = function() {
        // Schedule a redraw if we think we are idle. We detect idle
        // by tracking when the iterator is reset and when the traversal
        // is done.
        if (this.resetCount != this.pageOutResetCounter)
            return false;       // Draw is active
        _loader.viewer3DImpl.invalidate(false, true);
    }.bind(this);

    this.onPackFileLoaded = function(packId, data, geomSize) {
        // Record the pack file loaded.
        var pf = this.geommap[packId];

        _loadDoneSent = false;  // Once we load a pack file, we need to send load done again

        // This packId is no longer being loaded, reduce the loading packs size        
        if (this.loadingPacks.hasOwnProperty(packId)) {
            delete this.loadingPacks[packId];
            this.loadingPacksSize -= pf.geomSize + pf.usize;
        }

        // If data is null, then there was an error
        if (data) {
            var pack = this.loadedPacks[packId];
            if (!pack) {
                var count = data.meshes.length;
                
                pack = this.loadedPacks[packId] = {
                    totalCount: count,
                    travsed: 0,
                    culled: 0,
                    resetCounter: this.resetCount,
                    geomSize: 0, // Memory used by geometry in the loaded pack file
                    inMemory: new Array(((count + 31) / 32) | 0)
                };

                ++this.loadedPackfileCount;
                if (this.options.debug.occlusionTestThreshold > 0
                    && this.occlusionTesting == NO_OCCLUSION_TESTING
                    && this.loadedPackfileCount >= this.options.debug.startOcclusionTestingPackCount) {
                    this.occlusionTesting = QUEUE_OCCLUSION_TESTING;
                }

            }
            // Once we load a pack file, everything is in memory again
            pack.geomSize += geomSize;
            pack.currentCount = pack.totalCount;
            pack.inMemory.fill(~0);

            // The geometry loaded now replaces all the geometry loaded before
            this.totalGeomSize += pack.geomSize - pf.geomSize;

            // Adjust queuedPacksSize if this packId has been queued again.
            if (this.packQueuedMap[packId])
                this.queuedPacksSize += pack.geomSize - pf.geomSize;
            pf.geomSize = pack.geomSize;

            // Need to make sure we render something, to continue the loading process
            redrawIfIdle();
        }

        if (this.loadedGeometrySize() > this.getMemoryLimit()) {
            logger.warn("More pack files being loaded than the max count: " + this.loadedGeometrySize());
        }
    };

    this.checkLoadFinished = function() {
        if (_loadDoneSent)
            return;

        // Are all workers done?
        var pack_workers = _loader.pack_workers;
        if (pack_workers) {
            for (var j = 0; j < pack_workers.length; j++) {
                if (pack_workers[j].queued != 0)
                    return;
            }
        }

        // All workers are done. Is there anything more to do.
        if (this.geomPacksMissingLastFrame().length != 0 || _nextOrderToLoad < _fragOrder.length) {
            // More items on the list, so there might be more to do
            if (this.loadedGeometrySize() < this.getMemoryLimit() || _pageOutStillPossible) {
                // There may be more memory, so we aren't done yet
                return;
            }
        }

        // Done
        _loadDoneSent = true;
        _loader.onDemandGeomLoadDone();                
    };

    this.doOnDemandLoadFinished = function() {
        // Any more to do?
        this.loadGeometryMissingLastFrame();
        this.checkLoadFinished();
    };

    this.onPackFileLoading = function(packId) {
        if (this.loadingPacks.hasOwnProperty(packId))
            return;
        this.loadingPacks[packId] = true;
        var pf = this.geommap[packId];
        this.loadingPacksSize += pf.geomSize + pf.usize;
    };

    this.onProcessReceivedMesh = function(geometry, numInstances) {

        var geomId = geometry.svfid;
        if (this.onDemandLoadingEnabled() && numInstances > 1 &&
            this.geomidsmap[geomId] == null) {
            this.geomidsmap[geomId] = this.geomTravCount.length;
            this.geomTravCount.push(0);
        }
    };

    this.loadedGeometrySize = function() {
        return _loader.model.getGeometryList().geomMemory / MEGA + this.overheadSize;
    };

    this.preparedPackFilesSize = function() {
        return this.loadingPacksSize + this.loadedGeometrySize();
    };

    this.cancelPending = function() {
        if (this.loadingPacksSize > 1.0 / MEGA) {
                
            // Cancel any on going geometry loading, as it is probably no longer
            // immediately used by the following rendering as scene or camera 
            // changed.
            _loader.cancelGeometryPackLoading();
            this.loadingPacks = {};
            this.loadingPacksSize = 0;
        }
        
        this.queuedPacks.length = 0;
        this.queuedPacksSize = 0;
        this.packQueuedMap = {};
        this.occlusionTesting = (this.onDemandLoadingEnabled()
            && this.options.debug.occlusionTestThreshold > 0
            && this.loadedPackfileCount >= this.options.debug.startOcclusionTestingPackCount)
                ? QUEUE_OCCLUSION_TESTING : NO_OCCLUSION_TESTING;
        this.occludedPacks.length = 0;
    };

    this.resetIterator = function(camera/*, resetType*/) {
        this.lastCamera = camera;
        ++this.resetCount;
    };

    this.reset = function() {

        // Reset the record of geometry travsed or culled count.
        var loadedPacks = this.loadedPacks;
        for (var p in loadedPacks) {
            loadedPacks[p].travsed = 0;
            loadedPacks[p].culled = 0;
        }
        this.geomTravCount.fill(0);

        // I don't like this but I don't see any way around it. The goal is to keep all
        // visible geometry in memory. So when we reset the geometry in memory we need
        // to clear the display, which calls this method again. invalidateCount is used
        // to keep us from starting over in that case.
        if (this.resetCount > this.invalidateCount) {
            this.cancelPending();
            this.resetCanPageOut(false, true, true);
        }
    };

    this.geomPacksMissingLastFrame = function() {
        return this.queuedPacks;
    };

    this.addGeomPackMissingLastFrame = function(packId) {

        if (!this.onDemandLoadingEnabled())
            return true;

        if (this.pageOutGeometryEnabled()) {
            // Not too many loaded + loading + queued.
            if (this.loadedGeometrySize() >= this.getMemoryLimit()) {
                this.reachLimit = true;
            }
        }

        // Otherwise, schedule a futher loading
        if(!this.packQueuedMap[packId]) {
            var pf = this.geommap[packId];
            if (pf) {
                this.queuedPacks.push(packId);
                this.queuedPacksSize += pf.geomSize;
                this.packQueuedMap[packId] = true;
            }
        }
        
        return true;
    };

    this.loadGeometryMissingLastFrame = function() {
        // This load is done, then can start as many as possible.
        var missingPacks = this.queuedPacks;
        var i;
        for(i = 0; i < missingPacks.length; ++i) {
            var packId = missingPacks[i];
            if (this.occlusionTesting >= QUEUE_OCCLUSION_TESTING && this.occludedPacks[packId] === undefined)
                break;
            if (!this.geommap[packId].loading && !this.occludedPacks[packId]) {
                // Find one that hasn't been loaded.
                if (!_loader.loadGeometryPackOnDemand(packId, this.getLoadedMeshes(packId)))
                    break;      // can't load any more - stop
            }
        }

        // If we weren't able to load anything, redraw to start things up again.
        if (i == 0 && !_loadDoneSent)
            redrawIfIdle();

        // Remove pack files that are loading and the one we will load
        var _this = this;
        missingPacks.splice(0, i).forEach(function(packId) {
            _this.queuedPacksSize -= _this.geommap[packId].geomSize;
            delete _this.packQueuedMap[packId];
        });
    };

    this.needResumeNextFrame = function() {
        return _resumeNextFrame;
    };

    var _packSort = function(a, b) {
        var wa = a >= _packOrderMap.length ? PAST_LAST_PACK : _packOrderMap[a];
        var wb = b >= _packOrderMap.length ? PAST_LAST_PACK : _packOrderMap[b];
        return wb - wa;
    }.bind(this.loadedPacks);

    this.markVisibleGeoms = function(pagingOptions) {
        var map = _loader.model.getData().instanceTree || _loader.model.getData().fragmentMap;
        var ids;
        // Return if we can't get the data to mark visible geometry
        if (!map || !pagingOptions.visibleIdCB || !(ids = pagingOptions.visibleIdCB()))
            return;

        var visible = ++this.traversedCounter;
        var found = {};
        var end = ids.length;
        var id, key;
        var loadedPacks = this.loadedPacks;

        // Clear traversed count
        for (key in loadedPacks) {
                loadedPacks[key].travsed = 0;
        }

        // Count pixels covered by each id
        for (var i = 0; i < end; i += 4) {
            id = ids[i] | (ids[i + 1] << 8) | (ids[i + 2] << 16);
            id = (id << 8) >> 8;    // recover sign
            if (id > 0)
                found[id] = (found[id] | 0) + 1;
        }

        var frags = _loader.model.getFragmentList();
        var threshold = this.options.debug.occlusionThreshold | 0;
        for (key in found) {
            id = Number(key);
            if (found[id] >= threshold) {
                map.enumNodeFragments(id, function(fragId) {
                    var geom = frags.getGeometry(fragId);
                    if (geom) {
                        var pack = loadedPacks[geom.packId];
                        if (pack && geom.traversed != visible)
                            ++pack.travsed;
                        geom.traversed = visible;
                    }
                });
            }
        }
    };

    this.pageOut = function(iterationDone, forcePageOut, pagingOptions) {

        _resumeNextFrame = false;
        var pageStatus = globals.PAGEOUT_SUCCESS;

        if (iterationDone && this.options.debug.occlusionTestThreshold > 0
            && this.loadedPackfileCount >= this.options.debug.startOcclusionTestingPackCount) {
            this.occlusionTesting = PERFORM_OCCLUSION_TESTING;
        }
        this.occlusionTest(pagingOptions);

        // Only try to page out at the end of iteration of scene travseral,
        // which is to guarantee the geometries loaded from pack files get 
        // all used (either traversed or culled.)
        if (!iterationDone) {
            return pageStatus;
        }

        // This page out will page geometries on a pack file basis
        this.pageOutResetCounter = this.resetCount;
        var size = this.loadedGeometrySize();
        _pageOutStillPossible = true;
        if (size && (this.reachLimit || size > this.getMemoryLimit())) {

            this.markVisibleGeoms(pagingOptions);

            var loadedPacks = this.loadedPacks;
            var loadedPackIds = Object.keys(loadedPacks);
            var packsSkipped = false;

            // Sorting functions for different paging strategies

            // Sort so pack files that can be paged come first
            // and are sorted in reverse culled count order.
            // Pack files that can't be paged are not sorted further
            loadedPackIds.sort(_packSort);

            // If we aren't paging normally, then the best performance is to
            // page out as much as possible.
            var unloaded = size - Math.min(size, this.options.debug.maxPageOutSize);

            // Then, unload pack files 
            loadedPackIds.every(function(id) {
                if (loadedPacks[id].resetCounter < this.resetCount) {
                    this.unloadPackFile(id, false, true);
                } else
                    packsSkipped = true;
                
                return this.loadedGeometrySize() > unloaded;
            }.bind(this));
            
            if (forcePageOut && this.loadedGeometrySize() == size
                && loadedPacks[loadedPackIds[0]].resetCounter < this.resetCount) {
                this.unloadPackFile(loadedPackIds[0], false, true);
                logger.log("A force page out occur.");
            }

            if (this.loadedGeometrySize() == size) {
                pageStatus = globals.PAGEOUT_SUCCESS;
                this.reachLimit = true;
            }
            else {
                this.reachLimit = false;
                this.loadGeometryMissingLastFrame();
                _resumeNextFrame = true;
            }

            this.lastPageOut = size - this.loadedGeometrySize();
            logger.log("[On Demand Loading] Unload pack files size: " + this.lastPageOut);
            if (window && window.gc) {
                window.gc();
            }

            // If we weren't able to pageout anything, see if we are done
            if (this.lastPageOut == 0) {
                // If we didn't skip any pack files and nothing was paged out
                // then we won't be able to page out more later.
                _pageOutStillPossible = packsSkipped;
                this.checkLoadFinished();
            }

            return pageStatus;
        }

        // resume on missing geom for next frame.
        _resumeNextFrame = _resumeNextFrame || this.queuedPacks.length > 0;

        this.loadFragsFromLoadOrder();

        return pageStatus;
    };

    this.occlusionTest = function(pagingProxy) {
        var occlusionTestCB = null;
        var fragmentList = null;
        var moving = false;
        var promise = null;
        var packIds = null;
        var occlusionTestTimer = 0;
        var waitingCount = 0;
        var delayPerWaiting = 3;

        function findFragsForPackfile(packIds) {
            var packids = fragmentList.fragments.packIds;
            if (!packids)
                return null;

            var packList = [];
            packIds.forEach(function(packId) {
                var fragIds = [];
                var i = packids.lastIndexOf(packId);
                while (i >= 0) {
                    if (packids[i] == packId)
                        fragIds.push(i--);
                    else
                        i = packids.lastIndexOf(packId, i - 1);
                }
                packList.push(fragIds);
            });
            return packList;
        }

        function nextPackIds(count) {
            var packIds = null;
            waitingCount = 0;
            var queue = pagingProxy.queuedPacks;
            var occluded = pagingProxy.occludedPacks;
            count = Math.min(4, count || 4);
            var length = queue.length;
            for (var i = 0; i < length && count > 0; ++i) {
                var id = queue[i];
                if (occluded[id] === undefined) {
                    packIds = packIds || [];
                    packIds.push(id);
                    --count;
                } else if (!occluded[id])
                    ++waitingCount;
            }
            return packIds;
        }

        function handleOcclusion(visible) {
            promise = null;
            for (var i = 0; i < packIds.length; ++i) {
                pagingProxy.occludedPacks[packIds[i]] = !visible[i];
                if (!visible[i]) {
                    logger.debug("[On Demand Loading] Occluded Geometry Pack file: " + packIds[i]);
                    ++pagingProxy.occlusionCulledCount;
                }
            }
            pagingProxy.doOnDemandLoadFinished(); // Remove packId from queue
            doOcclusionTest();
        }

        function doOcclusionTest() {
            if (occlusionTestTimer || promise || _loadDoneSent)
                return;

            // Clear previous promise
            promise = null;
            // Can we do occlusion testing now?
            if (occlusionTestCB && !moving
                && (packIds = nextPackIds(pagingProxy.options.debug.testPackfileCount))) {
                
                occlusionTestTimer = setTimeout(function() {
                    occlusionTestTimer = 0;
                    // Yes get the fragment ids for the pack files
                    var fragIds = findFragsForPackfile(packIds);
                    promise = occlusionTestCB(fragmentList.boxes, pagingProxy.options.debug.occlusionTestThreshold,
                        fragIds, pagingProxy.options.debug.useOcclusionInstancing, packIds);
                    promise.then(handleOcclusion, function() {
                        // Assume visible if there is an error
                        handleOcclusion([true, true, true, true]);
                    });
                }, waitingCount * delayPerWaiting);
            }
        }

        function occlusionTest(pagingOptions) {
            // If we already have a test scheduled, or occlusion testing hasn't started yet.
            if (promise || this.occlusionTesting < PERFORM_OCCLUSION_TESTING)
                return;

            // collect data
            if (pagingOptions) {
                occlusionTestCB = pagingOptions.occlusionTestCB;
                moving = pagingOptions.moved;
            }
            fragmentList = _loader.model.getFragmentList();

            // Kick off the test
            if (fragmentList && occlusionTestCB && !moving )
                doOcclusionTest();
        }
        return occlusionTest;
    }(this);

    this.loadFragsFromLoadOrder = function() {
        var frags = _loader.model.getFragmentList();
        while (_nextOrderToLoad < _fragOrder.length) {
            var fragOrder = _fragOrder[_nextOrderToLoad];
            var len = fragOrder.length;
            while (_nextFragToLoad < len) {
                var fragId = fragOrder[_nextFragToLoad];
                if (!frags.getGeometry(fragId)) {
                    var packId = frags.fragments.packIds ? frags.fragments.packIds[fragId] : fragId;
                    var queuedLen = this.queuedPacks.length;
                    if (!this.doLoadPackFile(packId) && queuedLen == this.queuedPacks.length)
                        return;
                }
                ++_nextFragToLoad;
            }
            ++_nextOrderToLoad;
            _nextFragToLoad = 0;
        }
    };

    this.pfOrder = function() {
        return _packOrder;
    };

    this.getNumVisiblePFs = function() {
        return _packOrder ? _pfVisible : -1;
    };

    this.onLoadOrderCalculated = function(loadOrder) {
        if (loadOrder.error) {
            _lastResult = null;
            return;
        }
        if (loadOrder.fragOrder && loadOrder.packOrder)
            _lastResult = loadOrder;
        if (loadOrder.id != _loadOrderId || !_lastResult) {
            return;     // Superseded or error or frustum didn't change
        }

        this.lastPageOut = -1;
        _pageOutStillPossible = true;
        _loadDoneSent = false;

        _fragOrder.length = 0;
        if (_loader.model) {
            var fastFragsList = _loader.model.getFastLoadList();
            if (fastFragsList) {
                logger.log("Using PF fast-load-list for homeView, size:",fastFragsList.length);
                _fragOrder.push(fastFragsList);
            }
        }
         _fragOrder.push(_lastResult.fragOrder);

        // create a map that maps a packId to its position in the load order
        // this is used during pageout to prioritize the packs paged out.
        _packOrder = _lastResult.packOrder;
        _pfVisible = _lastResult.pfVisible;
        var i, len = _packOrder.length;
        _packOrderMap.length = this.geompacks.length;
        // Put all packs at the end of the list
        _packOrderMap.fill(PAST_LAST_PACK);
        // Set the load order for pack Ids in the load order list
        for (i = 0; i < len; ++i)
            _packOrderMap[_packOrder[i]] = i;

        // Figure out which pack files need to be unloaded
        // We loop through the fragments and add up the pack file sizes
        // until we reach the memory limit. Those pack files are the
        // ones we keep. If the fragment geometry is in memory, we
        // use the size of geometry currently loaded in memory, if it
        // isn't we use the total size of the pack file.
        var frags = _loader.model.getFragmentList();
        var size = this.overheadSize;
        var limit = this.getMemoryLimit();
        var j;
        for (j = 0; j < _fragOrder.length && size < limit; ++j) {
            var fragOrder = _fragOrder[j];
            len = fragOrder.length;
            var keepPacks = [];
            keepPacks.length = _packOrderMap.length;
            keepPacks.fill(0);
            var geompacks = this.geompacks;
            for (i = 0; i < len && size < limit; ++i) {
                var fragId = fragOrder[i];
                var packId = frags.fragments.packIds ? frags.fragments.packIds[fragId] : fragId;
                var pack = geompacks[packId];
                if (pack) {
                    var geomSize = frags.getGeometry(fragId) ? pack.geomSize : pack.totalGeomSize;
                    if (geomSize > keepPacks[packId]) {
                        size += geomSize - keepPacks[packId];
                        keepPacks[packId] = geomSize;
                    }
                }
            }
        }

        // Clear can page out for all loaded pack files
        var loadedPacks = this.loadedPacks;
        for (var a in loadedPacks) {
            if (/*a >= this.options.minPackFiles &&*/ loadedPacks.hasOwnProperty(a)) {
                if (!keepPacks[a])
                    this.unloadPackFile(a, true);
            }
        }

        ++this.traversedCounter;
        ++this.transparentCounter;
        this.reachLimit = this.loadedGeometrySize() >= this.getMemoryLimit();

        this.cancelPending();
        _nextOrderToLoad = 0;
        _nextFragToLoad = 0;
        this.loadFragsFromLoadOrder();

        _loader.viewer3DImpl.invalidate(true);
        this.invalidateCount = this.resetCount + 1;
    };

    this.resetCanPageOut = function(unloadPackFiles, delay, automatic) {
        if (_loadOrderTimer) {
            clearTimeout(_loadOrderTimer);
            _loadOrderTimer = 0;
        }

        var proxy = this;
        function doReset() {
            _loadOrderTimer = 0;
            // The first time we get here, from this.reset() then calculate the load order
            // This is so the scene will display, without manually calculating the load order.
            if (_firstReset || !automatic || proxy.options.debug.automaticRefresh) {
                proxy.occlusionCulledCount = 0;
                proxy.packsPagedOut = 0;

                var camera = proxy.lastCamera;
                var loadedPacks = proxy.loadedPacks;
                var a;
                // Unload all the pack files if needed;
                if (unloadPackFiles) {
                    for (a in loadedPacks) {
                        if (/*a >= proxy.options.minPackFiles &&*/ loadedPacks.hasOwnProperty(a)) {
                            proxy.unloadPackFile(a, true);
                        }
                    }
                }

                _loader.calculateLoadOrder(++_loadOrderId, camera,
                    proxy.options.debug.pixelCullingEnable ? proxy.options.debug.pixelCullingThreshold : -1);
                _firstReset = false;
            }
        }

        if (delay)
            _loadOrderTimer = setTimeout(doReset, LOAD_ORDER_TIMEOUT);
        else
            doReset();
    };

    this.onGeomTraversed = function(geometry, transparent) {
        var packId = geometry.packId;
        var geomId = geometry.svfid;
        geometry.traversed = this.traversedCounter;
        if (transparent)
            geometry.transparent = this.transparentCounter;

        // Only record it for paging if the pack file is allowed to be paged out.
        //if (packId >= this.options.minPackFiles) {
            var geomTraversed = true;
            
            var mapIdx = this.geomidsmap[geomId];
            if (mapIdx != null) {
                // increase counter of traversed geometry instances
                this.geomTravCount[mapIdx] += 2;
                this.geomTravCount[mapIdx] |= 1;

                geomTraversed = geometry.instanceCount == this.geomTravCount[mapIdx] >> 1;
            }

            var loaded = this.loadedPacks[packId];
            if (loaded) {
                if (geomTraversed) {
                    loaded.travsed++;
                }
            }
        //}

    };

    this.onGeomCulled = function(geometry) {
        if (!geometry) {
            return;
        }

        var packId = geometry.packId;
        var geomId = geometry.svfid;

        // Only record it for paging if the pack file is allowed to be paged out.
        //if (packId >= this.options.minPackFiles) {
            var mapIdx = this.geomidsmap[geomId];
            var geomCulled = !mapIdx;

            if (mapIdx != null) {
                // The low order bit of geomeTravCount indicates whether the
                // geometry has ever been traversed. If it has, then treat this
                // cull as a traverse.
                if (this.geomTravCount[mapIdx] & 1)
                    this.onGeomTraversed(geometry);
                else {
                    // ??? multiple geometry instance, may have some traversed
                    // ??? and some culled. The culled one is also marked as traversed count,
                    // ??? so this geometry may be counted as either culled or traversed,
                    // ??? that is ok so far.
                    this.geomTravCount[mapIdx] += 2;
                    geomCulled = geometry.instanceCount == this.geomTravCount[mapIdx] >> 1;
                }
            }

            var loaded = this.loadedPacks[packId];
            if (loaded && geomCulled)
                loaded.culled++;

        //}
        
    };
};
